import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import SectionHeader from "./SectionHeader";
import Alert from "@material-ui/lab/Alert";
import { useRouter } from "./../util/router";

//  minWidth:{xs: "110%", sm: "150%", md: "145%", lg: "140%", xl: "100%"},

function SideHeroSection(props) {
  const router = useRouter();

  const productHunt = (router.query.ref && router.query.ref==="ProductHunt");

  return (
    <>
    {productHunt && (
      <Box mx="auto" mb={2} maxWidth={400}>
          <Alert severity="success">
            Hey Hunters! Use code PRODUCTHUNT for a 30% discount on all plans for the first year.
            <span
              role="img"
              aria-label="cat"
              style={{ marginLeft: "10px" }}
            >
              😸
            </span>
          </Alert>
        </Box>)}
        <Grid container align="end" direction="row-reverse">
            <Grid item xs={10} sm={6}>
                <Box component="div" sx={{overflow: 'hidden'}}>
                        <Box 
                        sx={{
                            height: {xs: "250px", sm: "380px", md: "475px", lg: "750px"},
                            minWidth:"100%",
                            objectFit: "cover",
                            objectPosition: "left bottom",
                        }}
                            component="img"
                            src={props.bgImage}
                            justifyContent="flex-end"
                            
                        />     
                    </Box>
            </Grid>
            <Grid item xs={12} sm={6} lg={5} xl={4}>
                <Container>
                    <Box textAlign="left" display="flex" flexDirection="column" alignItems="center" padding={3} mt={{xs:4, sm:4, md:4, lg:22, xl:24 }}>
                        <SectionHeader
                            title={props.title}
                            subtitle={props.subtitle}
                            subtitle2={props.subtitle2}
                            size={4}
                        />
                       {/*  <Button
                            component={Link}
                            to={props.buttonPath}
                            variant="contained"
                            size="large"
                            color={props.buttonColor}
                        >
                            {props.buttonText}
                        </Button> */}
                    </Box>
                </Container>
            </Grid>

        </Grid>
    </>
  );
}

export default SideHeroSection;
