import React from "react";
import Meta from "./../components/Meta";
import PricingSection from "./../components/PricingSection";
import CtaSection from "../components/CtaSection";


function PricingPage(props) {
  return (
    <>
      <Meta title="Pricing" />
      <PricingSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Pricing"
        subtitle={<>Additional SMS Credits can be added at any time.<br></br>Remaining credits rollover to the next year.<br></br>Plans can be upgraded at any time.</>}
      />
            <CtaSection
        bgColor="primary"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Want to see it in action?"
        subtitle=""
        buttonText="Create an alert"
        buttonColor="secondary"
        buttonPath="/alerts"
      />
    </>
  );
}

export default PricingPage;
