import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { timeConditions } from "../util/timeConditions";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
  content: {
    paddingBottom: 24,
  },
  category: {
    fontWeight: 'bold'
  }
}));

function ConditionTimeAddModal(props) {
    const [age, setAge] = React.useState('')
    const [selectionIndex, setSelectionIndex] = React.useState(null);
    const [selectionMade, setSelectionMade] = React.useState(false);

    const handleChange = (event) => {
        setAge(event.target.value)
        const index = timeConditions.map(item => item.title).indexOf(event.target.value)
        setSelectionIndex(index)
        setSelectionMade(true)
        
    }

  function saveCondition(selectionIndex) {
    if (selectionIndex !== null) {
        props.setTimeConditionsList((draft) => {
            draft.push(timeConditions[selectionIndex])
        });
    }
    
    props.onDone()
  }
  const classes = useStyles();

  return (
    <Dialog open={true} onClose={props.onDone} fullWidth maxWidth="xs">
      <DialogTitle>
        Add a new time condition
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container={true}>
            <Grid item xs={12}>
                <Box 
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    padding={2}
                    >
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Condition</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={age}
                        label="Age"
                        onChange={handleChange}
                        autoWidth
                        >
                        {timeConditions.map((w, index) => {
                                return ([
                                    <MenuItem   
                                        key={w.title}
                                        value={w.title}
                                    >
                                        {w.title}
                                    </MenuItem>  
                                ])  
                            }
                        )}
                    </Select>
                </FormControl>
                </Box>
            </Grid>
            {selectionMade && 
                <><Grid item xs={12}>
                    <Typography variant="body1">
                        {timeConditions[selectionIndex].description}
                    </Typography>
                    <Typography variant="body1">
                        &nbsp;
                    </Typography>
                    <Typography variant="body1">
                        {timeConditions[selectionIndex].description2}
                    </Typography>
                    </Grid>
                </>}
            <Grid item={true} xs={12}>
                <Box 
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    pt={4}
                    >
                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            onClick={() => saveCondition(selectionIndex)}
                        >
                            Add
                        </Button>
                </Box>
            </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default ConditionTimeAddModal;
