export function getAlertSettings(historical) {
    const report = optimiseForAll(historical[0])
    return report
}

const optionsMap = {
    "Temperature": {
        "Cold": {
            "field": "mean", 
            "min": -5,
            "max": 5,
        },
        "Mild": {
            "field": "mean", 
            "min": 10,
            "max": 16,
        },
        "Warm": {
            "field": "mean", 
            "min": 20,
            "max": 26,
        },
        "Hot": {
            "field": "mean", 
            "min": 27,
            "max": false,
        }
    },
    "Cloud": {
        "Very Low": {
            "field": "mean", 
            "min": false,
            "max": 10,
        },
        "Low": {
            "field": "mean", 
            "min": false,
            "max": 20,
        },
    },
    "Rain": {
        "None": {
            "field": "max", 
            "min": false,
            "max": 0,
        },
        "Low": {
            "field": "max", 
            "min": false,
            "max": 0.1,
        },
    },
    "WindSpeed": {
        "Very Low": {
            "field": "mean", 
            "min": false,
            "max": 7,
        },
        "Low": {
            "field": "mean", 
            "min": false,
            "max": 10,
        },
        "Consistent": {
            "field": "min", 
            "min": 10,
            "max": false,
        },
    },
    "MoonIllumination": {
        "Low": {
            "field": "total", 
            "min": false,
            "max": 20,
        },
        "Mid-High": {
            "field": "total", 
            "min": 50,
            "max": false,
        },
    },
    "MoonlightHours": {
        "High": {
            "field": "total", 
            "min": 6,
            "max": false,
        },
    },
};


function getOptionsFromActivity(activity) {
    if (activity==="Hiking") {
        return [ 
            {
                "location": "Temperature",
                "period": "day",
                "desired": "Mild",
                "unitType": "temp",
                "condition": "Average Temperature",
                "weatherConditionsIndex": 0,
            }, 
            {
                "location": "WindSpeed",
                "period": "day",
                "desired": "Low",
                "unitType": "speed",
                "condition": "Average Wind Speed",
                "weatherConditionsIndex": 14,
            },
            {
                "location": "Cloud",
                "period": "day",
                "desired": "Low",
                "unitType": "percent",
                "condition": "Average Cloud Cover",
                "weatherConditionsIndex": 6,
            },
        ];
    } else if (activity === "Camping") {
        return [ 
            {
                "location": "Temperature",
                "period": "all",
                "desired": "Mild",
                "unitType": "temp",
                "condition": "Average Temperature",
                "weatherConditionsIndex": 0,
            }, 
            {
                "location": "Rain",
                "period": "all",
                "desired": "Low",
                "unitType": "distance",
                "condition": "Hourly Rain",
                "weatherConditionsIndex": 10,
            },
            {
                "location": "WindSpeed",
                "period": "all",
                "desired": "Low",
                "unitType": "speed",
                "condition": "Average Wind Speed",
                "weatherConditionsIndex": 14,
            },
        ];
    } else if (activity === "Sailing") {
        return [ 
            {
                "location": "Temperature",
                "period": "day",
                "desired": "Warm",
                "unitType": "temp",
                "condition": "Average Temperature",
                "weatherConditionsIndex": 0,
            }, 
            {
                "location": "Rain",
                "period": "day",
                "desired": "Low",
                "unitType": "distance",
                "condition": "Hourly Rain",
                "weatherConditionsIndex": 10,
            },
            {
                "location": "WindSpeed",
                "period": "day",
                "desired": "Consistent",
                "unitType": "speed",
                "condition": "Hourly Wind Speed",
                "weatherConditionsIndex": 15,
            },
        ];
    } else if (activity === "Cycling") {
        return [ 
            {
                "location": "Temperature",
                "period": "day",
                "desired": "Mild",
                "unitType": "temp",
                "condition": "Average Temperature",
                "weatherConditionsIndex": 0,
            }, 
            {
                "location": "Cloud",
                "period": "day",
                "desired": "Low",
                "unitType": "percent",
                "condition": "Average Cloud Cover",
                "weatherConditionsIndex": 6,
            },
            {
                "location": "Rain",
                "period": "day",
                "desired": "None",
                "unitType": "distance",
                "condition": "Hourly Rain",
                "weatherConditionsIndex": 10,
            },
            {
                "location": "WindSpeed",
                "period": "day",
                "desired": "Low",
                "unitType": "speed",
                "condition": "Average Wind Speed",
                "weatherConditionsIndex": 14,
            },
        ];
    } else if (activity === "Beach Day") {
        return [ 
            {
                "location": "Temperature",
                "period": "day",
                "desired": "Hot",
                "unitType": "temp",
                "condition": "Average Temperature",
                "weatherConditionsIndex": 0,
            }, 
            {
                "location": "Cloud",
                "period": "day",
                "desired": "Low",
                "unitType": "percent",
                "condition": "Average Cloud Cover",
                "weatherConditionsIndex": 6,
            },
            {
                "location": "Rain",
                "period": "day",
                "desired": "None",
                "unitType": "distance",
                "condition": "Hourly Rain",
                "weatherConditionsIndex": 10,
            },
            {
                "location": "WindSpeed",
                "period": "day",
                "desired": "Low",
                "unitType": "speed",
                "condition": "Average Wind Speed",
                "weatherConditionsIndex": 14,
            },
        ];
    } else if (activity === "Wild Swimming") {
        return [ 
            {
                "location": "Temperature",
                "period": "day",
                "desired": "Warm",
                "unitType": "temp",
                "condition": "Average Temperature",
                "weatherConditionsIndex": 0,
            }, 
            {
                "location": "Cloud",
                "period": "day",
                "desired": "Low",
                "unitType": "percent",
                "condition": "Average Cloud Cover",
                "weatherConditionsIndex": 6,
            },
            {
                "location": "WindSpeed",
                "period": "day",
                "desired": "Low",
                "unitType": "speed",
                "condition": "Average Wind Speed",
                "weatherConditionsIndex": 14,
            },
        ];
    } else if (activity === "Star Gazing") {
        return [ 
            {
                "location": "Rain",
                "period": "night",
                "desired": "None",
                "unitType": "distance",
                "condition": "Hourly Rain",
                "weatherConditionsIndex": 10,
            }, 
            {
                "location": "Cloud",
                "period": "night",
                "desired": "Very Low",
                "unitType": "percent",
                "condition": "Average Cloud Cover",
                "weatherConditionsIndex": 6,
            },
            {
                "location": "MoonIllumination",
                "period": false,
                "desired": "Low",
                "unitType": "percent",
                "condition": "Moon Illumination",
                "weatherConditionsIndex": 21,
            },
        ];
    } else if (activity === "Night Walk") {
        return [ 
            {
                "location": "Temperature",
                "period": "night",
                "desired": "Mild",
                "unitType": "temp",
                "condition": "Average Temperature",
                "weatherConditionsIndex": 0,
            }, 
            {
                "location": "Cloud",
                "period": "night",
                "desired": "Low",
                "unitType": "percent",
                "condition": "Average Cloud Cover",
                "weatherConditionsIndex": 6,
            },
            {
                "location": "WindSpeed",
                "period": "night",
                "desired": "Low",
                "unitType": "speed",
                "condition": "Average Wind Speed",
                "weatherConditionsIndex": 14,
            },
            {
                "location": "MoonIllumination",
                "period": false,
                "desired": "Mid-High",
                "unitType": "percent",
                "condition": "Moon Illumination",
                "weatherConditionsIndex": 21,
            },
            {
                "location": "MoonlightHours",
                "period": false,
                "desired": "High",
                "unitType": "time",
                "condition": "Moonlight Hours",
                "weatherConditionsIndex": 22,
            },
        ];
    }
}


function optimiseForAll(data) {

    const activityList = ["Hiking", "Camping", "Sailing", "Cycling", "Beach Day", "Wild Swimming", "Star Gazing", "Night Walk"]

    const activityReports = {}
    activityList.forEach((activity) => {
        activityReports[activity] = optimiseParams(data, activity)
    })

    return activityReports;

}

function optimiseParams(data, activity) {

    const options = getOptionsFromActivity(activity);
    const startingPoint = fillStartingPoint(options, optionsMap);
    let [report, goodDays] = compareDataToStartingPoint(data, startingPoint);
    // we now have our bare bones filtering based on defaults
    // if the number of good days is outside the required
    // range of 8-16 results then we need to tweak some params
    let totalDays = goodDays.length;
    // console.log("Defaults resulted in " + totalDays + " days, with " + expectedGoodDays + " expected.");
    let goAgain = ((totalDays < 8) || (totalDays > 14));
    let attempts = 0;
    
    let product = 1;
    report.forEach((condition) => {
        product *= condition.percentileRange;
    });

    let lastDaysImplied = product * 365;

    while (goAgain) {
        attempts += 1;
        // console.log()
        // console.log("Tweak attempt number " + attempts);
        const [newReport, newGoodDays, newExpectedGoodDays] = tweakReport(data, report, totalDays, lastDaysImplied);
        report = newReport;
        goodDays = newGoodDays;
        totalDays = newGoodDays.length;
        lastDaysImplied = newExpectedGoodDays;
        // console.log("Tweak resulted in " + totalDays + " days, with " + newExpectedGoodDays + " expected.");
        goAgain = (((totalDays < 8) || (totalDays > 14)) && (attempts < 20));
    }

    startingPoint.forEach((condition, index) => {
        if (condition.min !== false) {
            condition.minFound = report[index].min;
        }
        if (condition.max !== false) {
            condition.maxFound = report[index].max;
        }
        condition.minInData = Math.floor(report[index].deciles[0])
        condition.maxInData = Math.ceil(report[index].deciles[10])
        if ("windMax" in report[index]) {
            condition.maxInData =  Math.ceil(report[index].windMax);
        }
        if (condition.min !== false) {
            // make sure it's above min
            if (condition.min < condition.minInData) {
                condition.min = condition.minInData
            }
            if (condition.minFound < condition.minInData) {
                condition.minFound = condition.minInData
            }
        }
        if (condition.max !== false) {
            // max sure it's below max
            if (condition.max > condition.maxInData) {
                condition.max = condition.maxInData
            }
            if (condition.maxFound > condition.maxInData) {
                condition.maxFound = condition.maxInData
            }
        }
    })

    const result = {
        "goodDays": goodDays,
        "total": totalDays,
        "conditions": startingPoint
    }
    

    return result;
}

function fillStartingPoint(options, optionsMap) {
    const result = [];
    options.forEach((option) => {
        const location = option.location;
        const pref = option.desired;
        const mapChoice = optionsMap[location][pref];
        result.push({ ...option, ...mapChoice});
    });

    return result;

}

function getDeciles(allVals) {
    const ordered = allVals.sort((a, b) => a-b);
    const count = allVals.length-1;
    const tenths = count/10;

    const dec = [];
    for (let i=0; i<11; i++) {
        const loc = Math.round(i * tenths);
        dec.push(ordered[loc]);
    }
    return dec;
}

function compareDataToStartingPoint(data, startingPoint) {

    const records = data.records;
    const allReadingsLog = [];
    startingPoint.forEach(() => {
        allReadingsLog.push([]);
    });

    const goodDays = [];

    let windMax = 0;
    let needsWindMax = [];
    records.forEach((record, index) => {
        needsWindMax.push(false)
        let valid = true;
        const dateKey = Object.keys(record)[0];
        startingPoint.forEach((condition, index) => {
            let v = 0;
            if (condition.period) {
                v = record[dateKey][condition.location][condition.period][condition.field];
                if ((condition.field==="min") & (condition.location==="WindSpeed")) {
                    needsWindMax[index] = true;
                    const maxWindV = record[dateKey][condition.location][condition.period].max;
                    if (maxWindV > windMax) {
                        windMax = maxWindV
                    }
                }
            } else {
                v = record[dateKey][condition.location][condition.field];
            }
            allReadingsLog[index].push(v);
            if (condition.min !== false) {
                if (v < condition.min) {
                    valid = false;
                }
            }
            if (condition.max !== false) {
                if (v > condition.max) {
                    valid = false;
                }
            }
        });
        if (valid) {
            goodDays.push(dateKey);
        }
    });

    const withContext = [];
    startingPoint.forEach((condition, index) => {
        const allVals = allReadingsLog[index];
        let minPercentile = false;
        let maxPercentile = false;
        if (condition.min !== false) {
            const numDays = countBy(allVals, number => number < condition.min);
            minPercentile = numDays / allVals.length;
        }
        if (condition.max !== false) {
            const numDays = countBy(allVals, number => number <= condition.max);
            maxPercentile = numDays / allVals.length;
        }
        const deciles = getDeciles(allVals);
        let percentileRange = 0;
        if ((condition.min !== false) && (condition.max !== false)) {
            percentileRange = maxPercentile - minPercentile;
        } else if (condition.max === false) {
            percentileRange = 1-minPercentile;
        } else if (condition.min === false) {
            percentileRange = maxPercentile;
        } else {
            console.error("What?");
        }
        if (needsWindMax[index]) {
            withContext.push({ ...condition, minPercentile, maxPercentile, deciles, percentileRange, windMax});
        } else {
            withContext.push({ ...condition, minPercentile, maxPercentile, deciles, percentileRange});
        }
    });

    return [withContext, goodDays];
}

// takes an array and a function to check each element in the array against
const countBy = (array, checkFunction) => {
    return array.reduce((count, element) => {
      return checkFunction(element) ? count + 1 : count;
    }, 0);
  };

function getMinMaxFromMid(midAsPercentile, target) {
    const halfTarget = target/2;
    let min = midAsPercentile - halfTarget;
    let max = midAsPercentile + halfTarget;

    if (min<0) {
        const adjust = -min;
        min = 0;
        max += adjust;
    }
    if (max > 1) {
        const adjust = max - 1;
        min -= adjust;
        max = 1;
    }

    return [min, max];
}

function getValFromDeciles(deciles, point) {
    const idx = Math.floor(point * 10);
    const lower = deciles[idx];
    let upper = deciles[idx];
    if (idx < (deciles.length-1)) {
        upper = deciles[idx+1];
    }
    const range = upper - lower;
    const prop = ((point*10) % 1);

    return (Math.round((lower + (prop * range))*10)/10);

}

function getMinMaxFromDeciles(condition, target) {

    const lower = (condition.minPercentile===false)? 0 : condition.minPercentile;
    const upper = (condition.maxPercentile===false)? 1: condition.maxPercentile;

    const midAsPercentile = (lower + upper) / 2;

    const [minPercentile, maxPercentile] = getMinMaxFromMid(midAsPercentile, target);

    const newMin = getValFromDeciles(condition.deciles, minPercentile);
    const newMax = getValFromDeciles(condition.deciles, maxPercentile);

    return [newMin, newMax];

}

function getAdjustment(adjustment, condition, target) {

    // we may have min false, max false, or neither false

    let newMin = false;
    let newMax = false;

    if (condition.min !== false) {
        if (condition.max !== false) {
            // then we can change both upper and lower
            [newMin, newMax] = getMinMaxFromDeciles(condition, target);
            // console.log("Changing " + condition.location + " from (" + condition.min + ", " + condition.max + ") to (" + newMin + ", " + newMax + ")");

        } else {
            // condition max is false, change min
            newMin = getValFromDeciles(condition.deciles, 1-target);
            newMin = (newMin + condition.min)/2 
            // TODO - this is a hacky way of making sure our "more than" measures
            // stay anchored near the top... it's a bit like introducing weightings
            // but only for those where we are only seeking a min value...
            // works for beach day temps tho...
            // console.log("Changing " + condition.location + " from " + condition.min + " to " + newMin);
        }
    } else {
        // condition min is false, must have condition max
        newMax = getValFromDeciles(condition.deciles, target);
        if (newMax === condition.max) {
            if (adjustment === "Increase") {
                newMax = getValFromDeciles(condition.deciles, target*1.5); // avoid local minima (see tenerife star gazing)
            } else {
                newMax = getValFromDeciles(condition.deciles, target/1.5);
            }
        }
        // console.log("Changing " + condition.location + " from " + condition.max + " to " + newMax);
    }

    return [newMin, newMax];
}

function tweakReport(data, report, totalDays, lastDaysImplied) {

    let DAYSWANTED = (((12/totalDays) * lastDaysImplied) * 0.6)+ (lastDaysImplied* 0.4);

    if (isNaN(DAYSWANTED)) {
        if (lastDaysImplied===12) {
            DAYSWANTED *= 2;
        } else {
            DAYSWANTED = 12;
        }
    }

    if (!(isFinite(DAYSWANTED))) {
        DAYSWANTED = 12;
    }
    
    const fieldCount = report.length;
    const aim = DAYSWANTED / 365; // want 12 days in 365 to alert
    const needed = aim ** (1/fieldCount); // assumes independance of variables


    const adjustments = [];
    if (totalDays > 12) {
        // we have too many, need to refine down
        report.forEach((condition) => {
            if (condition.percentileRange <= needed) {
                adjustments.push("Keep");
            } else {
                // can we adjust this further?
                if ((condition.min !== false) && (condition.max !== false)) {
                    if (condition.min === condition.max) {
                        // we can't adjust it further
                        adjustments.push("Fixed");
                    } else {
                        adjustments.push("Reduce");
                    }
                } else if (condition.min === false) {
                    // we can reduct max if it's not already at the lowest decile
                    if (condition.max === condition.deciles[0]) {
                        adjustments.push("Fixed");
                    } else {
                        adjustments.push("Reduce");
                    }
                } else if (condition.max ===  false){
                    // we can reduce min if it's not already at the lowest decile
                    if (condition.min === condition.deciles[0]) {
                        adjustments.push("Fixed");
                    } else {
                        adjustments.push("Reduce");
                    }
                }
            }
        });
    } else {
        // we have too few, need to expand conditions out
        report.forEach((condition) => {
            if (condition.percentileRange >= needed) {
                adjustments.push("Keep");
            } else {
               
                // can we adjust this further?
                if ((condition.min !== false) && (condition.max !== false)) {
                    if ((condition.min === condition.deciles[0]) && (condition.max === condition.deciles[10])) {
                        // we can't adjust it further
                        adjustments.push("Fixed");
                    } else {
                        adjustments.push("Increase");
                    }
                } else if (condition.min === false) {
                    // we can reduct max if it's not already at the lowest decile
                    if (condition.max === condition.deciles[10]) {
                        adjustments.push("Fixed");
                    } else {
                        adjustments.push("Increase");
                    }
                } else if (condition.max ===  false){
                    // we can reduce min if it's not already at the lowest decile
                    if (condition.min === condition.deciles[10]) {
                        adjustments.push("Fixed");
                    } else {
                        adjustments.push("Increase");
                    }
                }
            }
        });
    }

    let count = 0;
    let product=1;
    adjustments.forEach((adjustment, index) => {
        if (adjustment==="Fixed") {
            count += 1;
            product *= report[index].percentileRange;
        }
    });

    let target = (aim/product) ** (1/(fieldCount-count));

    const finalAdjustments = [];
    if (totalDays > 12) {
        // console.log("More days than needed");
        // we have too many, need to refine down
        report.forEach((condition, index) => {
            if (condition.percentileRange <= target) {
                finalAdjustments.push("Keep");
                // console.log("Keeping " + condition.location + " (percentile range lower than target)");
            } else {
                if (adjustments[index] === "Fixed") {
                    // we can't adjust it
                    finalAdjustments.push("Keep")
                    // console.log("Keeping " + condition.location + " (can't adjust any further)");
                } else {
                    finalAdjustments.push("Reduce");
                    // console.log("Reducing " + condition.location + " range");
                }
            }
        });
    } else {
        // console.log("Fewer days than needed");
        // we have too few, need to expand conditions out
        report.forEach((condition, index) => {
            if (condition.percentileRange >= target) {
                finalAdjustments.push("Keep");
                // console.log("Keeping " + condition.location + " (percentile range larger than target)");
            } else {
                if (adjustments[index] === "Fixed") {
                    // we can't adjust it
                    finalAdjustments.push("Keep")
                    // console.log("Keeping " + condition.location + " (can't adjust any further)");
                } else {
                    finalAdjustments.push("Increase");
                    // console.log("Increasing " + condition.location + " range");
                }
            }
        });
    }

    count = 0;
    product=1;
    finalAdjustments.forEach((adjustment, index) => {
        if (adjustment==="Keep") {
            count += 1;
            product *= report[index].percentileRange;
        }
    });

    target = (aim/product) ** (1/(fieldCount-count));



    // we now have a target percentile range for the ones we want to change
    finalAdjustments.forEach((adjustment, index) => {
        if (adjustment!=="Keep") {
            const [newMin, newMax] = getAdjustment(adjustment, report[index], target);
            if (newMin !== false) {
                report[index].min = newMin;
            }
            if (newMax !== false) {
                report[index].max = newMax;
            }
        }
    });

    const [newReport, goodDays, newExpectedGoodDays] = compareDataToStartingPoint(data, report);
    
    return [newReport, goodDays, newExpectedGoodDays];

}