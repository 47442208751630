import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Section from "./Section";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { createLocalStorageStateHook } from "use-local-storage-state";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import SinglePicker from "./SinglePicker";
import ActivitySelector from "./ActivitySelector";
import ActivityReport from "./ActivityReport";
import { getAlertSettings } from "../util/activityAlertSettings";


const useStyles = makeStyles((theme) => ({
    // Increase <Container> padding so it's
    // at least half of <Grid> spacing to
    // avoid horizontal scroll on mobile.
    // See https://material-ui.com/components/grid/#negative-margin
    container: {
      padding: `0 ${theme.spacing(3)}px`,
      textAlign: "center",
    },
    image: {
        height: "auto",
        maxWidth: "180px",
      },
  }));



function generateReport(historical) {
    return getAlertSettings(historical)
}

function ExampleAlertFilter({historical, city, currencySymbol, longitude, latitude}) {

    const classes = useStyles();

    const [report, setReport] = React.useState(null);
    const [hasReport, setHasReport] = React.useState(false);

    useEffect(()=> {
        if (historical !== undefined) {
            setReport(generateReport(historical))
            setHasReport(true)
        }
    }, [historical])

    const hasCity = city.length > 0;

    // Create a local storage hook for temp preference
    const temps = ["°C", "°F"]
    const localTempPref = createLocalStorageStateHook("localTempPref");
    const [tempPref, setTempPref] = localTempPref();
    if (tempPref === undefined) {
        if (currencySymbol==="$") {
            setTempPref(temps[1])
        } else {
            setTempPref(temps[0])
        }
        
    }

    const speeds = ["kph", "mph"]
    const localSpeedPref = createLocalStorageStateHook("localSpeedPref");
    const [speedPref, setSpeedPref] = localSpeedPref();
    if (speedPref === undefined) {
        if (currencySymbol==="$") {
            setSpeedPref(speeds[1])
        } else {
            setSpeedPref(speeds[0])
        }
        
    }

    const distances = ["mm", "inch"]
    const localDistancesPref = createLocalStorageStateHook("localDistancePref");
    const [distancePref, setDistancePref] = localDistancesPref();
    if (distancePref === undefined) {
        if (currencySymbol==="$") {
            setDistancePref(distances[1])
        } else {
            setDistancePref(distances[0])
        }
        
    }

    const [activity, setActivity] = React.useState("Hiking")


    return (
        <Section
          bgColor="default"
          size="medium"
          bgImage=""
          bgImageOpacity={1}
        >
        <Grid
              container={true}
              alignItems="center"
              justifyContent="center"
              spacing={0}
            >
             <Grid item xs={6}>
                        <img
                            src="https://ik.imagekit.io/critz/tryitout.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677670911353"
                            alt="try it out pointer"
                            className={classes.image}
                        />
                        </Grid>
        </Grid>
          <Container className={classes.container}>
            <Grid
              container={true}
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
            <Grid item xs={12} md={8}>
                {/* sets the width of the box on home page */}
              <Card>
                <CardContent className={classes.cardContent}>
                <Box>
                    <Grid container spacing={0}>
                       
                        <Grid item xs={12}>
                            <Box
                            display="flex"
                            padding={0}
                            marginLeft="12px"
                            >
                            <Typography variant="h4" paragraph={true}>
                            {hasCity && <><strong>{activity} in {city}</strong></>}
                            {!hasCity && <><strong>Try it now for your location.</strong></>}
                            </Typography>
                            </Box>
                        </Grid>
                        <ActivitySelector activity={activity} setActivity={setActivity} includeNone={false}/>
                        <ActivityReport report={report} hasReport={hasReport} activity={activity} tempPref={tempPref} distancePref={distancePref} speedPref={speedPref} longitude={longitude} latitude={latitude}/>
                        <Grid item xs={12}>
                            <Grid container justifyContent="flex-end">
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    padding = {1}
                                    >
                                    <SinglePicker formats={tempPref} setFormats={setTempPref} options={temps} disabled={[false, false]} key={tempPref}/>
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    padding = {1}
                                    >
                                    <SinglePicker formats={speedPref} setFormats={setSpeedPref} options={speeds} disabled={[false, false]} key={speedPref}/>
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    padding = {1}
                                    >
                                    <SinglePicker formats={distancePref} setFormats={setDistancePref} options={distances} disabled={[false, false]} key={distancePref}/>
                                </Box>
                            </Grid>
                        </Grid>
                        

                    </Grid>
                </Box>
                </CardContent>
            </Card>
            </Grid>
            </Grid>
          </Container>
        </Section>
      );
    }

export default ExampleAlertFilter;



