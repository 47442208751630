import React from "react";
import Box from "@material-ui/core/Box"

import ActivitySelector from "../ActivitySelector";

export default function ActivityPicker({activity, setActivity}) {


    return (
        <>
      <div>
        <Box sx={{ py: 1 }}>
            <ActivitySelector activity={activity} setActivity={setActivity} includeNone={true}/>
        </Box>
      </div>
      </>
    );
  }
  