import React from "react";
import Meta from "./../components/Meta";
import AlertsSection from "../components/AlertsSection";


function AlertPage(props) {
  return (
    <>
      <Meta title="Alerts" />
      <AlertsSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title=""
        subtitle=""
      />
    </>
  );
}

export default (AlertPage);
