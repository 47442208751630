import React, { useEffect } from "react";
import Alert from "@material-ui/lab/Alert";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

function VerifyAlert({id, formAlert, setFormAlert}) {
    const classes = useStyles();
    useEffect(() => {
        fetch('https://us-central1-wxtxtco.cloudfunctions.net/verifyMobile', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
                'Origin': 'localhost:3000',
            },
            body: JSON.stringify({
                'verificationCode': id
            })
        })
        .then((response) => response.json())
        .then((data) => {
            console.log(data)
            if ("error" in data) {
                setFormAlert({
                    type: "error",
                    message: "There was a problem verifying your number (we couldn't reach Firebase) - please try again in a few minutes, and contact support if this persists",
                    });
            } else {
                setFormAlert({
                    type: "success",
                    message: data.msg,
                    });
            }
            handleClose();
        })
        .catch((error) => {
            setFormAlert({
                type: "error",
                message: error.message,
                });
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
        };


    return (
    <>
        {formAlert && (
            <Alert severity={formAlert.type} style={{ maxWidth: "500px" }}>
            {formAlert.message}
        </Alert>
        )}
        <div>
          <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
    </>
    )
}

export default VerifyAlert;


