import React, { useEffect } from "react";
import ExampleAlertFilter from "./ExampleAlertFilter";
import PlaceHolderExampleAlertFilter from "./PlaceHolderExampleAlertFilter";
import { useHistorical } from "../util/db";

function HomePageWidget({latitude, longitude, city, currencySymbol}) {

    const [haveHistorical, setHaveHistorical] = React.useState(false);

    const {
        data: historical,
      } = useHistorical(latitude, longitude);

    function handleRequestHistorical() {

        fetch('https://us-central1-wxtxtco.cloudfunctions.net/initialiseHistory', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
                'Origin': 'localhost:3000',
            },
            body: JSON.stringify({
                'longitude': longitude,
                'latitude': latitude,
            })
        })
        .then((response) => response.json())
        .catch((error) => {
            console.log(error);
            // todo, tell the user about the error
            // then revert to map open close the backdrop
        })
    }

    useEffect(() => {
        if (historical !== undefined) {
            if (historical.length > 0) {
                setHaveHistorical(true);
            }
        }
    }, [historical]);

    useEffect(()=> {
      // on mount, let's fire off a call to grab the forecast
      handleRequestHistorical()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

  return (
    <>

    {haveHistorical &&
        <ExampleAlertFilter historical={historical} city={city} currencySymbol={currencySymbol} longitude={longitude} latitude={latitude}/>
    }
    {!haveHistorical &&
        <PlaceHolderExampleAlertFilter/>
    }
    </>
  );
}

export default HomePageWidget;
