import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { motion } from "framer-motion"

const useStyles = makeStyles((theme) => ({
  itemsContainer: {
    marginTop: 30,
  },
  row: {
    // Reverse every other row
    "&:nth-of-type(even)": {
      flexDirection: "row-reverse",
    },

    // Spacing between rows
    "&:not(:last-child)": {
      marginBottom: `${theme.spacing(3)}px`,
    },
  },
  figure: {
    maxWidth: 400,
    margin: "30px auto",
  },
  image: {
    height: "auto",
    maxWidth: "100%",
  },
}));

function FadeInWhenVisible({ children }) {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.3 }}
      variants={{
        visible: {opacity: 1, scale: 1},
        hidden: { opacity: 0, scale: 0 }
      }}
    >
      {children}
    </motion.div>
  );
}

function FeaturesSection(props) {
  const classes = useStyles();

  const items = [
    {
      title: "Spontaneity, perfected.",
      description:
        "We love micro adventures; even more when the weather is just right. Set up local alerts for your perfect weather and know when to hit the trail, road, beach, or whatever you fancy.",
      image:
        "https://ik.imagekit.io/critz/undraw_into_the_night_vumi__2_.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1675333938975",
    },
    {
      title: "Simple setup",
      description:
        "Just click the map at the location you want alerts for. We'll analyse the data and build alerts for all sorts of activities.",
      image:
      "https://ik.imagekit.io/critz/undraw_weather_re_qsmd__1_.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1675333938729",
    },
    {
      title: "Just what you're looking for",
      description:
        "Don't like our defaults? Review the last year's data and tweak your settings until you're happy.",
      image:
      "https://ik.imagekit.io/critz/undraw_adventure_map_hnin__1_.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1675333938803",
    },
    {
      title: "Never miss an adventure",
      description:
        "SMS remains the best way to grab attention. We'll text you as soon as a good forecast comes in, up to 14 days in advance.",
      image:
        "https://ik.imagekit.io/critz/undraw_weather_notification_re_3pad__1_.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1675333938976",
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Container
          maxWidth="md"
          disableGutters={true}
          className={classes.itemsContainer}
        >
          {items.map((item, index) => (
              <Grid
                className={classes.row}
                container={true}
                item={true}
                alignItems="center"
                spacing={4}
                key={index}
              >
                <Grid item={true} xs={12} md={6}>
                  <FadeInWhenVisible>
                  <Box
                    textAlign={{
                      xs: "center",
                      md: "left",
                    }}
                  >
                    <Typography variant="h4" gutterBottom={true}>
                      {item.title}
                    </Typography>
                    <Typography variant="subtitle1">
                      {item.description}
                    </Typography>
                  </Box>
                  </FadeInWhenVisible>
                </Grid>
                <Grid item={true} xs={12} md={6}>
                <FadeInWhenVisible>
                  <figure className={classes.figure}>
                    <img
                      src={item.image}
                      alt={item.title}
                      className={classes.image}
                    />
                  </figure>
                  </FadeInWhenVisible>
                </Grid>
              </Grid>

          ))}
        </Container>
      </Container>
    </Section>
  );
}

export default FeaturesSection;
