import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import BigConditionBox from "./BigConditionBox";
import Carousel from "react-material-ui-carousel";
import PlotTimeline from "./PlotTimeline";
import { rawNumberToColour } from "../util/graphing";
import { graphMap } from "../util/graphMap";
import { useDarkMode } from "../util/theme";
import _ from "lodash";
import { valueInDefaultUnits } from "./ConditionSlider";

const useStyles = makeStyles((theme) => ({
  content: {
    paddingBottom: 24,
  },
  category: {
    fontWeight: 'bold'
  }
}));

function convertUnitToDisplayUnit(unit, tempPref, distancePref, speedPref) {

    if (unit==="tempPref") {
        return tempPref;
    }

    if (unit==="distancePrefmm") {
        return distancePref;
    }

    if (unit==="distancePrefcm") {
        // data will be in cm
        if (distancePref === "inch") {
            return "inch";
        } else {
            return "cm";
        }
    }

    if (unit==="distancePrefkm") {
        // data will be in km
        if (speedPref === "mph") {
            return "miles";
        } else {
            return "km";
        }
    }

    if (unit === "speedPref") {
        // data will be in kmh
        if (speedPref === "mph") {
            return "mph";
        } else {
            return "km/h";
        }
    }

    if (unit === "percent") {
        return "%";
    }

    if (unit === "hours") {
        return "hours"
    }

    return "";
}

export function convertValueToPreference(rawValue, unit, tempPref, distancePref, speedPref) {

    let convertedValue = rawValue;
    if (unit==="tempPref") {
        // data will be in C
        if (tempPref === "°F") {
            convertedValue = (rawValue * 9/5) + 32;
        }
        return convertedValue;
    }

    if (unit==="temp") {
        // data will be in C
        if (tempPref === "°F") {
            convertedValue = (rawValue * 9/5) + 32;
        }
        return convertedValue;
    }

    if (unit==="distancePrefmm") {
        // data will be in mm
        if (distancePref === "inch") {
            convertedValue = rawValue / 25.4;
        }
        return convertedValue;
    }

    if (unit==="distance") {
        // data will be in mm
        if (distancePref === "inch") {
            convertedValue = rawValue / 25.4;
        }
        return convertedValue;
    }

    if (unit==="distancePrefcm") {
        // data will be in cm
        if (distancePref === "inch") {
            convertedValue = rawValue / 2.54;
        }
    }

    if (unit==="distancecm") {
        // data will be in cm
        if (distancePref === "inch") {
            convertedValue = rawValue / 2.54;
        }
    }

    if (unit==="distancePrefkm") {
        // data will be in km
        if (speedPref === "mph") {
            convertedValue = rawValue * 0.6213711922;
        }
        return convertedValue;
    }

    if (unit==="distancekm") {
        // data will be in km
        if (speedPref === "mph") {
            convertedValue = rawValue * 0.6213711922;
        }
        return convertedValue;
    }

    if (unit === "speedPref") {
        // data will be in kmh
        if (speedPref === "mph") {
            convertedValue = rawValue * 0.6213711922;
        }
        return convertedValue;
    }

    if (unit === "speed") {
        // data will be in kmh
        if (speedPref === "mph") {
            convertedValue = rawValue * 0.6213711922;
        }
        return convertedValue;
    }

    return convertedValue; // catches any not defined
}

function countCompassPointsInData(compass, yVals) {
    let count = 0;
    yVals.forEach((val) => {
        if (val===compass) {
            count += 1;
        }
    })

    return count;
}

function labelFromGraphPeriod(activeGraphPeriod) {
    if (activeGraphPeriod==="day") {
        return "Daytime"
    }
    if (activeGraphPeriod==="night") {
        return "Nightime"
    }
    return ("24Hr")
}

function getGraphingVals(weatherCondition, historical, tempPref, distancePref, speedPref, localSliderValues, activeGraphPeriod, setGraphObject, setPercentage, setIsRadar) {

    // returns an array of graph objects
    // Each one contains [xDates, yVals, barColours, yAxisLabel, hoverTemplate: {fieldName, descriptor, showUnits, unit, decimals}]

    const graphsRequired = graphMap[weatherCondition];
    setIsRadar(graphsRequired.isRadar);
    const records = historical[0].records;

    const xDates = [];
    const xDateKeys = []
    const validXDatesNotAbove = [];
    const validXDatesNotBelow = [];
    const xNums = [];
    const ys = [];

    const chartsRequired = graphsRequired.charts;
    // instantiates empty arrays for each chart we want
    chartsRequired.forEach((chart) => {
        ys.push([])
    });

    let incrementX = 100/(records.length-1);

    let lastRawValue = null;

    records.forEach((record, index) => {
        const dateKey = Object.keys(record)[0]
        xDates.push(new Date(dateKey))
        xDateKeys.push(dateKey)
        xNums.push(incrementX*index);
        chartsRequired.forEach((chart, index) => {
            let rawValue;
            if (_.isEmpty(record[dateKey][graphsRequired.location])) {
                console.log("Backfilling a missing value...")
                rawValue = lastRawValue 
            } else {
                if (graphsRequired.usePeriods) {
                    rawValue = record[dateKey][graphsRequired.location][activeGraphPeriod][chart.field]
                } else {
                    rawValue = record[dateKey][graphsRequired.location][chart.field]
                }
            }
            lastRawValue = rawValue; // using this to backfill any missing vals...
            const nativeValue = convertValueToPreference(rawValue, graphsRequired.unit, tempPref, distancePref, speedPref)

            if (localSliderValues !== null) {
                if (Array.isArray(localSliderValues)) {
                    // multiSelect
                    if ("filter" in graphsRequired) {
                        const notAbovefield = graphsRequired.filter.notAbove;
                        const notBelowfield = graphsRequired.filter.notBelow;
        
                        if (chart.field === notAbovefield) {
                            if (localSliderValues[1] >= nativeValue) { // if max slider > actual val in sample
                                validXDatesNotAbove.push(dateKey)
                            }
                        }
                        if (chart.field === notBelowfield) {
                            if (localSliderValues[0] <= nativeValue) { // if min slider < actual val in sample
                                validXDatesNotBelow.push(dateKey)
                            }
                        }
                    }

                } else {
                    // single slider
                    if ("filter" in graphsRequired) {
                        const notAbovefield = graphsRequired.filter.notAbove;
        
                        if (chart.field === notAbovefield) {
                            if (localSliderValues >= nativeValue) { // if max slider > actual val in sample
                                validXDatesNotAbove.push(dateKey)
                            }
                        }
                        validXDatesNotBelow.push(dateKey)
                    }
                    
                }

            } else {
                validXDatesNotAbove.push(dateKey)
                validXDatesNotBelow.push(dateKey)
            }
            ys[index].push(nativeValue)
        });
    });

    // now I have the raw data - I need to build each of the graph objects for each required chart

    const graphObjectsList = []

    let validXDates = validXDatesNotAbove.filter(x => validXDatesNotBelow.includes(x))
    validXDates = [...new Set(validXDates)]

    setPercentage(validXDates.length / xDates.length);

    chartsRequired.forEach((chart, index) => {

        let barColours = [];

        const thisMin = Math.min(...ys[index]);
        const thisMax = Math.max(...ys[index]);

        let hoverTemplate = {
            fieldName: chart.display.hover.fieldName,
            descriptor: chart.display.hover.descriptor,
            showUnits: false,
            decimals: chart.display.hover.decimals,
        }
        let yAxisLabel = chart.display.axis.fieldName;
        let title = chart.display.axis.descriptor + " " + chart.display.axis.fieldName;
        if (graphsRequired.usePeriods) {
            title = labelFromGraphPeriod(activeGraphPeriod) + " " + title;
        }

        if (chart.display.showUnits) {
            const displayUnits = convertUnitToDisplayUnit(graphsRequired.unit, tempPref, distancePref, speedPref);
            hoverTemplate = {
                fieldName: chart.display.hover.fieldName,
                descriptor: chart.display.hover.descriptor,
                showUnits: true,
                displayUnits: displayUnits,
                decimals: chart.display.hover.decimals,
            }
            yAxisLabel = yAxisLabel + " (" + displayUnits + ")";
        }

        if (chart.showTimeline) {
            // we need a timeline chart
            // Each one contains [xVals, yVals, barColours, yAxisLabel, hoverTemplate: {fieldName, descriptor, showUnits, displayUnits (optional), decimals}]

            barColours = ys[index].map((y, idx) => {
                if (graphsRequired.invertColours) {
                    if (validXDates.includes(xDateKeys[idx])) {
                        return rawNumberToColour(y, 0.9, thisMin, thisMax);
                    } else {
                        return rawNumberToColour(y, 0.15, thisMin, thisMax);
                    }
                } else {
                    if (validXDates.includes(xDateKeys[idx])) {
                        return rawNumberToColour(y, 0.9, thisMax, thisMin);
                    } else {
                        return rawNumberToColour(y, 0.15, thisMax, thisMin);
                    }
                    
                }
            });
            
            graphObjectsList.push({
                xVals: xDates,
                yVals: ys[index],
                barColours: barColours,
                yAxisLabel: yAxisLabel,
                hoverTemplate: hoverTemplate,
                isTimeline: true,
                title: "Last Year's " + title,
            })
        }

        if (chart.showDistribution) {
            // we need a distribution chart
            if (graphsRequired.isRadar) {
                // this is a wind one
                const theta = ["N", "NNE", "NE", "ENE", "E", "ESE", "SE", "SSE", "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW", "N"]

                const compassCounts = [];
                theta.forEach((compass) => {
                    compassCounts.push(countCompassPointsInData(compass, ys[index]));
                });

                const minCount = Math.min(...compassCounts);
                const maxCount = Math.max(...compassCounts);

                const barColours = compassCounts.map((y) => {
                    if (graphsRequired.invertColours) {
                        return rawNumberToColour(y, 0.7, minCount, maxCount);
                    } else {
                        return rawNumberToColour(y, 0.7, maxCount, minCount);
                    }
                });

                graphObjectsList.push({
                    xVals: theta,
                    yVals: compassCounts,
                    barColours: barColours,
                    isTimeline: false,
                    yAxisLabel: yAxisLabel,
                    hoverTemplate: hoverTemplate,
                    isRadar: true,
                })

            } else {
                const ysCopy = [...ys[index]]
                const barsCopy = [...barColours]
                let list = [];
                ysCopy.forEach((y, idx) => {
                    list.push({"y": y, "color": barsCopy[idx]})
                });

                list.sort(function(a, b) {
                    return ((a.y < b.y) ? -1 : (a.y === b.y) ? 0: 1)
                })

                const newYs = []
                const newColours = []
                list.forEach((l) => {
                    newYs.push(l.y)
                    newColours.push(l.color)
                })
    
                graphObjectsList.push({
                    xVals: xNums,
                    yVals: newYs,
                    barColours: newColours,
                    yAxisLabel: yAxisLabel,
                    hoverTemplate: hoverTemplate,
                    isTimeline: false,
                    title: "Stacked " + title,
                })
            }
        }
    })


    setGraphObject(graphObjectsList)
}

function ConditionAddModal(props) {

    const darkMode = useDarkMode();

    const historical = props.historical;
    const [selectionIndex, setSelectionIndex] = React.useState(null);
    const [selectionMade, setSelectionMade] = React.useState(false);
    const [age, setAge] = React.useState('')
    const [percentage, setPercentage] = React.useState(1);
    const [isRadar, setIsRadar] = React.useState(false);

    const [localSliderValues, setLocalSliderValues] = React.useState(null);

    const updatingIndex = props.updatingIndex;
    const weatherConditionsList = props.weatherConditionsList;
    const weatherConditions = props.weatherConditions;
    const setWeatherConditionsList = props.setWeatherConditionsList;
    const tempPref = props.tempPref;
    const distancePref = props.distancePref;
    const speedPref = props.speedPref;


    useEffect(() => {
        if (updatingIndex !== null) {
            const weatherConditionUpdating = weatherConditionsList[updatingIndex].title;
            const asIndex = weatherConditions.map(item => item.title).indexOf(weatherConditionUpdating);
            setAge(weatherConditionUpdating)
            setSelectionIndex(asIndex)
            setSelectionMade(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChange = (event) => {
        setAge(event.target.value)
        const index = weatherConditions.map(item => item.title).indexOf(event.target.value)
        setSelectionIndex(index)
        setSelectionMade(true);
        setLocalSliderValues(null)
    }

  function periodFromActiveGraph() {
    if (activeGraphPeriod === "day") {
        return ["Day"]
    } else if (activeGraphPeriod==="night") {
        return ['Night'] 
    } else {
        return ['24 Hr']
    }
  }

  function saveCondition(selectionIndex) {

    const multiSlider = Array.isArray(localSliderValues);

    let lower=0;
    let upper = 0;

    if (multiSlider) {
        lower = localSliderValues[0];
        upper = localSliderValues[1]    
    } else {
        upper = localSliderValues;
    }

    if (updatingIndex===null) {
        // it's a new condition we need to push
        // need these lower and upper converted to base

        const wcClone = _.cloneDeep(weatherConditions[selectionIndex])

        wcClone.periodSelected = periodFromActiveGraph()

        const unitType = wcClone.control.unitType;
        if (multiSlider) {
            wcClone.control.minValueWanted = valueInDefaultUnits(lower, unitType, tempPref, speedPref, distancePref);
            wcClone.control.maxValueWanted = valueInDefaultUnits(upper, unitType, tempPref, speedPref, distancePref);
        } else {
            wcClone.control.valueWanted = valueInDefaultUnits(upper, unitType, tempPref, speedPref, distancePref);
        }
    
        setWeatherConditionsList((draft) => {
            draft.push(wcClone)
        })

    } else {
        // it's an existing condition we need to update.

        const unitType = weatherConditionsList[updatingIndex].control.unitType;
        setWeatherConditionsList((draft) => {
            if (multiSlider) {
                draft[updatingIndex].control.minValueWanted = valueInDefaultUnits(lower, unitType, tempPref, speedPref, distancePref);
                draft[updatingIndex].control.maxValueWanted = valueInDefaultUnits(upper, unitType, tempPref, speedPref, distancePref);
            } else {
                draft[updatingIndex].control.valueWanted = valueInDefaultUnits(upper, unitType, tempPref, speedPref, distancePref);
            }
        })
    }
    
    props.onDone();

  }

   const [activeGraphPeriod, setActiveGraphPeriod] = useState("day");
   const [graphObject, setGraphObject] = useState(null);
   useEffect(() => {
        if (selectionIndex!== null) {
            const weatherCondition = weatherConditions[selectionIndex].title;
            //[xDates, yMain, yMin, yMax, hoverTemplate, yAxisLabel] = 
            getGraphingVals(weatherCondition, historical, props.tempPref, props.distancePref, props.speedPref, localSliderValues, activeGraphPeriod, setGraphObject, setPercentage, setIsRadar)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectionIndex, props.tempPref, props.distancePref, props.speedPref, activeGraphPeriod, localSliderValues]);

  const classes = useStyles();

  const GroupingHeaders = {0: "Temperature", 6: "Sky", 9: "Rain and Snow", 14: "Wind", 19: "Daytime", 21:"Nighttime"}

  const lightBgPlot = "https://ik.imagekit.io/critz/wbgplot.png?ik-sdk-version=javascript-1.4.3&updatedAt=1676367864969"
  const darkBgPlot = "https://ik.imagekit.io/critz/bbgplot.png?ik-sdk-version=javascript-1.4.3&updatedAt=1676367694233"
  
  const bgPlot = (darkMode.value) ? darkBgPlot : lightBgPlot;
  const bgSlider = "https://ik.imagekit.io/critz/wbgSlider.png?ik-sdk-version=javascript-1.4.3&updatedAt=1676368572019"


  return (
    <Dialog open={true} onClose={props.onDone} fullWidth maxWidth="lg">
      <DialogTitle>
        {!!updatingIndex &&
            <>Edit {weatherConditionsList[updatingIndex].title} Settings</>
        }
        {!updatingIndex && 
            <>Add a new weather condition</>
        }
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container={true} justifyContent="space-between" spacing={0}>
                <Grid item xs={12} md={3}>
                    <Grid container={true}>
                    <Box padding={1}>
                        <Grid item xs={12}>
                        
                            { (updatingIndex === null) &&
                            <>  <Grid item xs={12}>
                                    <Typography variant="body1">
                                       Please choose a condition you would like to explore
                                    </Typography>
                                    <Typography variant="body1">
                                            &nbsp;
                                        </Typography>
                                    </Grid>
                                    </>
                                    }
                            <Box 
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                padding={0}
                                >
                                <FormControl fullWidth disabled={updatingIndex !== null}>
                                    <InputLabel id="demo-simple-select-label">Condition</InputLabel>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={age}
                                        label="Age"
                                        onChange={handleChange}
                                        autoWidth

                                        >
                                        {weatherConditions.map((w, index) => {
                                            if (index in GroupingHeaders) {
                                                return ([
                                                    <MenuItem disabled className={classes.category}>{GroupingHeaders[index]}</MenuItem>
                                                    ,<MenuItem   
                                                        key={w.title}
                                                        value={w.title}
                                                    >
                                                        {w.title}
                                                    </MenuItem>  
                                                ])  
                                            } else {
                                                return ([
                                                    <MenuItem   
                                                        key={w.title}
                                                        value={w.title}
                                                    >
                                                        {w.title}
                                                    </MenuItem>  
                                                ])  
                                            }
                                                            
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        {selectionMade && 
                                    <>
                                        <Grid item xs={12}>
                                        <Typography variant="body1">
                                            &nbsp;
                                        </Typography>
                                        <Typography variant="body1">
                                            {weatherConditions[selectionIndex].description}
                                        </Typography>
                                        <Typography variant="body1">
                                            &nbsp;
                                        </Typography>
                                        <Typography variant="body1">
                                            {weatherConditions[selectionIndex].description2}
                                        </Typography>
                                        </Grid>
                                    </>}
                        </Box>
                    </Grid>
                </Grid>
                <Divider orientation="vertical" flexItem/>
                <Grid item xs={12} md={8}>
                    <Grid container>
                        <Grid item xs={12}>
                            {graphObject && 
                                <Carousel autoPlay={false} swipe={false}>
                                    {
                                        graphObject.map((graph, index) => 
                                            <PlotTimeline graph={graph} key={index}/>
                                        )
                                    }
                                </Carousel>
                            }
                            { !graphObject && 

                                <Carousel autoPlay={false} swipe={false}>
                                    <img
                                        src={bgPlot}
                                        className={classes.image}
                                        alt="blurred, inactive graph of weather"
                                        height="310px"
                                    />
                                </Carousel>
                                    
                                }
                            
                        </Grid>
                        <Grid item xs={12}>
                        {
                            graphObject &&
                            <BigConditionBox
                                updatingIndex={updatingIndex} 
                                selectionIndex={selectionIndex} 
                                weatherConditionsList={weatherConditionsList} 
                                setWeatherConditionsList={props.setWeatherConditionsList} 
                                tempPref={props.tempPref}
                                speedPref={props.speedPref}
                                distancePref={props.distancePref}
                                setActiveGraphPeriod={setActiveGraphPeriod}
                                weatherConditions={weatherConditions}
                                localSliderValues={localSliderValues}
                                setLocalSliderValues={setLocalSliderValues}
                                percentage={percentage}
                                isRadar={isRadar}
                                key={selectionIndex}
                            />
                        }    
                        {
                            !graphObject &&
                            <img
                                        src={bgSlider}
                                        className={classes.image}
                                        alt="blurred, inactive slider"
                                        width="92%"
                                    />
                        }    
                        </Grid>
                        <Grid item={true} xs={12}>
                            <Box 
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                pt={4}
                                >
                                    <Button
                                        variant="contained"
                                        size="medium"
                                        color="primary"
                                        onClick={() => saveCondition(selectionIndex)}
                                    >
                                        Add
                                    </Button>
                            </Box>
                        </Grid>
                    </Grid>

                </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default ConditionAddModal;
