import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  content: {
    paddingBottom: 24,
  },
  category: {
    fontWeight: 'bold'
  }
}));

function ConditionAddModal(props) {
  const classes = useStyles()

  return (
    <Dialog open={true} onClose={props.onDone} fullWidth maxWidth="xs">
      <DialogTitle>
        Earn credits by sharing
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container={true}>
            <Grid item xs={12}>
                <Typography variant="body1">
                   Send a copy of this alert to a friend so they can tweak it for themselves.
                </Typography>
                <Typography variant="body1">
                    &nbsp;
                </Typography>
                <Typography variant="body1">
                   For every sign up we'll give you 10 SMS credits.
                </Typography>
                <Typography variant="body1">
                    &nbsp;
                </Typography>
                <Typography variant="body1">
                   We'll also give anyone who views this link 25% off their plan for the first year.
                </Typography>
                <Grid item={true} xs={12}>
                    <Box 
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        pt={3}
                        >
                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            onClick={() => {navigator.clipboard.writeText("https://wxtxt.co/alerts?alert=" + props.shareAlertId)}}
                        >
                            Copy Link
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default ConditionAddModal;
