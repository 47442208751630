import React from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '181px'
};

function MiniMap({selectedLocation}) {

    // eslint-disable-next-line  no-unused-vars
   const [map, setMap] = React.useState(null);

   const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBP7_OsrVhdOLyAw9Ao5ayQnCo39VaXhDs'
  })

  const marker = [{lat: selectedLocation.latitude, lng: selectedLocation.longitude}]
  const center = {lat: selectedLocation.latitude, lng: selectedLocation.longitude}

  const onLoad = React.useCallback(function callback(map) {
    map.setZoom(12);
    setMap(map)
  }, [setMap])

  const onUnmount = React.useCallback(function callback() {
    setMap(null)
  }, [setMap])

  const createMapOptions = {
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      styles: [{ stylers: [ {'hue': "#61d1d5" }, { 'saturation': 0 }, { 'gamma': 0 }, { 'lightness': 50 }, { 'visibility': 'on' }] }]
    }


  return (isLoaded  &&
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        clickableIcons={false}
        zoom={8}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={createMapOptions}
      >
        {marker.map((m) => {
            return (<Marker 
                key = "marker"
                position = { {
                    lat: m.lat,
                    lng: m.lng
                }}
            />)
        })}
      </GoogleMap>
  )
}

export default React.memo(MiniMap)