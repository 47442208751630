import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";

export default function FetchingHistorical() {  
    return (
        <>
      <div>
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding = {0}
            textAlign="center">
            <Grid container>
            <Grid item xs={12}>
                    <Typography variant="h5" style={{marginBottom: "20px"}}>
                        Loading Weather History
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" style={{marginBottom: "20px"}}>
                        Won't be long! We're just pulling the data and analysing it for you.
                    </Typography>
                </Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={6}>
                    <LinearProgress color="secondary" />
                </Grid>
            
                
            </Grid>
        </Box>
      </div>
      </>
    );
  }
  