import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid";
import ConditionTimeSlider from "./ConditionTimeSlider";
import ConditionTimePicker from "./ConditionTimePicker";


const useStyles = makeStyles((theme) => ({
    control: {
      backgroundColor: 
      theme.palette.type === "dark" ? "#606060" : "#F7F7F7",
    }
}));



export default function ConditionTimeBoxControl({control, setTimeConditionsList, index}) {

    const classes = useStyles()
    return (
        <>
      
            <Box 
                display="flex"
                padding={2}
                sx={{ 
                    width: "100%",
                    padding: "12px 0px 8px 14px", 
                    borderRadius: 4
                }} 
                className={classes.control}
                key={control.title + index}
            >   <Grid container alignItems="center">
                    <Grid item xs={12}>
                        {control.flavour === "slider" &&
                        <>
                            <ConditionTimeSlider control={control} setTimeConditionsList={setTimeConditionsList} index={index}/>
                        </>}
                        {control.flavour === "picker" &&
                        <>
                            <ConditionTimePicker control={control} setTimeConditionsList={setTimeConditionsList} index={index}/>
                        </>}
                    </Grid>
                </Grid>
                
            </Box>
      </>
    );
  }
  