import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { rawNumberToColour } from "../util/graphing";



function PercentageIndicator({percentage, reverseColour}) {

    const displayPercentage = Math.round(percentage*365)

    let colour = rawNumberToColour(displayPercentage, 0.5, 0, 365);
    let borderColour= rawNumberToColour(displayPercentage, 0.9, 0, 365);

    if (reverseColour !== undefined) {
        if (reverseColour) {
            colour = rawNumberToColour(displayPercentage, 0.5, 365, 0);
            borderColour= rawNumberToColour(displayPercentage, 0.9, 365, 0);        
        } 
    }
    
  return (
    <Box 
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    sx={{ 
        width: "80px",
        height: "80px",
        padding: "0px 0px 0px 0px", 
        borderRadius: "50%",
        bgcolor: colour,
        borderColor: borderColour,
        border: '2px solid'
    }} 
    >
        <Typography variant="h5">
            <strong>{displayPercentage}</strong>
        </Typography>
        <Typography variant="subtitle2">
            {(displayPercentage===1) && 
                <>day</>
            }
            {(displayPercentage!==1) && 
                <>days</>
            }
            
        </Typography>
    </Box>
    
  );
}

export default PercentageIndicator;
