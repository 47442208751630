import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import AwaitingReportLoading from "./AwaitingReportLoading"
import ReportLoaded from "./ReportLoaded";
import ReportExitRoute from "./ReportExitRoute";

function ActivityReport({report, hasReport, activity, tempPref, distancePref, speedPref, longitude, latitude}) {

    return (
        <Grid item xs={12}>
            <Box
            display="flex"
            padding = {1}
            >
            <Grid container>
                <Grid item xs={12}>
                    {!hasReport && 
                    <>
                        <AwaitingReportLoading/>
                        </>
                    }
                    {hasReport && 
                    <>
                        <ReportLoaded report={report} activity={activity} tempPref={tempPref} distancePref={distancePref} speedPref={speedPref}/>
                        <ReportExitRoute longitude={longitude} latitude={latitude} report={report} activity={activity}/>
                        </>
                    }
                </Grid>
            </Grid>
            </Box>
        </Grid>
      );
    }

export default ActivityReport;



