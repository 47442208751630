import React from "react";
import Grid from "@material-ui/core/Grid";
import ConditionTimeBox from "./ConditionTimeBox";


export default function SMSSettings({setSMSConditionsList, smsConditionsList}) {

    return (
        <>
               <Grid container alignItems="center">
                    <Grid container={true} spacing={2}>
                    {smsConditionsList.map((w, index) => {
                        return (
                            <Grid item={true} xs={12}  key={w.title + index}>
                                <ConditionTimeBox title={w.title} subtitle={w.description} control={w.control} setTimeConditionsList={setSMSConditionsList} index={index} hideDelete={true}/>
                            </Grid>
                        )
                    })}
            </Grid>
                </Grid>
                
      </>
    );
  }
  