import React, { useEffect } from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import LinkMui from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import DashboardAlerts from "./DashboardAlerts";
import { Link, useLocation, useRouter } from "./../util/router";
import { useAuth } from "./../util/auth";
import { weatherConditions } from "../util/weatherConditions";
import { smsConditions } from "../util/smsConditions";
import { useImmer } from "use-immer";
import AlertLoader from "./AlertLoader";
import NewAlertConfig from "./AlertConfig/NewAlertConfig";
import _ from 'lodash'
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
    useTheme
  } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(3),
  },
}));


const timeConditionsDefault = []
const smsConditionsDefault = [smsConditions[0], smsConditions[1], smsConditions[2]];


function fillWeatherConditionsFromObj(setWeatherConditionsList, weatherConditions, passingObj) {
  setWeatherConditionsList([])
  passingObj.alert.forEach((condition) => {
    const wc = _.cloneDeep(weatherConditions[condition.weatherConditionsIndex])
    let multislider = false;
    if ("maxValueWanted" in wc.control) {
      multislider = true;
    }
    if (multislider) {
      // we expect to update the min and max
      // but if min or max is false, set it to the bottom / top of the slider range
      if (condition.min === false) {
        wc.control.minValueWanted = condition.minInData
        wc.control.maxValueWanted = condition.maxFound;
      } else if (condition.max === false) {
        wc.control.maxValueWanted = condition.maxInData;
        wc.control.minValueWanted = condition.minFound;
      } else {
        // range
        wc.control.minValueWanted = condition.minFound;
        wc.control.maxValueWanted = condition.maxFound;
      }
      wc.control.minValue = condition.minInData
      wc.control.maxValue = condition.maxInData
      // set period
      if (condition.period !== false) {
        if (condition.period === "day") {
          wc.periodSelected = ['Day']
        } else if (condition.period === "night") {
          wc.periodSelected = ['Night']
        } else {
          wc.periodSelected = ['24 Hr']
        }
      }
    } else {
      console.error("Not supported yet. We didn't have single value optimisations until now...")
    }
    setWeatherConditionsList((draft) => {
      draft.push(wc)
  })
  })

}


function AlertsSection(props) {
  const classes = useStyles();

  const auth = useAuth();
  const theme = useTheme();
  const aboveSM = useMediaQuery(theme.breakpoints.up('sm'));

  // need to bring in weatherConditionsDefault to here.
  // as we need to set the min Max slider values once we work out sensible defaults
  // eslint-disable-next-line no-unused-vars
  const [weatherConditionsDefault, setWeatherConditionsDefault] = useImmer([]);

  // everything we need for an alert
  const [weatherConditionsConfig, setWeatherConditionsConfig] = useImmer(weatherConditions);
  const [weatherConditionsList, setWeatherConditionsList] = useImmer([]);
  const [timeConditionsList, setTimeConditionsList] = useImmer(timeConditionsDefault)
  const [smsConditionsList, setSMSConditionsList] = useImmer(smsConditionsDefault);
  

  const hasPhoneNumber = (auth.user && auth.user.phone && auth.user.phone.length>0);
  const phoneVerified = (auth.user && auth.user.phone && auth.user.phoneVerified);
  const textCredits = (auth.user && auth.user.textCredits>0);
  const allowedAlerts = (auth.user && auth.user.allowedAlerts);

  const router = useRouter();

  const [marker,  setMarker] = React.useState([]);
  const [map, setMap] = React.useState(null);

  const [updatingAlertId, setUpdatingAlertId] = React.useState(null);

  const [resetDefaults, setResetDefaults] = React.useState(true);

  const [countActiveAlerts, setCountActiveAlerts] = React.useState(0);

  const [selectedLocation, setSelectedLocation] = React.useState(null);

  const topTierPlan = (auth.user && auth.user.planId==="patron" && auth.user.stripeSubscriptionStatus==="active");

  const seekingAlertId = router.query.alert;

  const [processedWeatherConditionChange, setProcessedWeatherConditionChange] = React.useState(true)

  const [fixLocation, setFixLocation] = React.useState(false);

  const [activity, setActivity] = React.useState("Hiking")

  const [usedLoader, setUsedLoader] = React.useState(false);

  const location = useLocation();

  useEffect(() => {
    if (processedWeatherConditionChange === false) {
        if (location.state !== undefined) {
          if ("passingObj" in location.state) {
            const passingObj = location.state.passingObj;
            fillWeatherConditionsFromObj(setWeatherConditionsList, weatherConditions, passingObj)
          }
        }
        setProcessedWeatherConditionChange(true)    
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processedWeatherConditionChange])


  useEffect(()=> {
    if (location.state !== undefined) {
      if ("passingObj" in location.state) {
        const passingObj = location.state.passingObj;
        setSelectedLocation({latitude: passingObj.latitude, longitude: passingObj.longitude})
        setMarker([{lat: passingObj.latitude, lng: passingObj.longitude}])
        setFixLocation(true)
        setActivity(passingObj.activity)

        //aside: if we're linking off to sign up up get a paid membership
        // then, if we're signed up already we can save the alert (silently)
        // if we're not signed up, then as soon as we do, we can save
        // it (as my first alert) on sign up - and set the redirection
        // link back to the alert?alertid
        // which will load it as soon as they land back on the alerts page.
      }
    }
  }, [location])


  

  const defaultCenter = {
    lat: 39.118,
    lng: -106.445
  };
  
  const [center, setCenter] = React.useState(defaultCenter);



  return (
    <>
    {!aboveSM && (
        <Box mx="auto" mb={0} mt={1} maxWidth={400}>
            <Alert severity="info">
              WxTxt works best on larger screens!
              <span
                role="img"
                aria-label="party"
                style={{ marginLeft: "10px" }}
              >
                🥳
              </span>
            </Alert>
          </Box>)}
    {aboveSM &&  !auth.user && (
        <Box mx="auto" mb={0} mt={1} maxWidth={400}>
            <Alert severity="info">
              You won't be able to save your alert until you log in or create an account!
              <span
                role="img"
                aria-label="rocket"
                style={{ marginLeft: "10px" }}
              >
                🚀
              </span>
            </Alert>
          </Box>)}
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />

        {router.query.paid && auth.user && auth.user.planIsActive && (
          <Box mx="auto" mb={4} maxWidth={400}>
            <Alert severity="success">
              You are now subscribed to the {auth.user.planId} plan
              <span
                role="img"
                aria-label="party"
                style={{ marginLeft: "10px" }}
              >
                🥳
              </span>
            </Alert>
          </Box>
        )}

        {hasPhoneNumber && !phoneVerified && (
          <Box mx="auto" mb={4} maxWidth={400}>
            <Alert severity="warning">
              Alerts will not be sent until you verify your phone number - please check your SMS messages or contact support.
            </Alert>
          </Box>
        )}

        {(allowedAlerts>0) && (countActiveAlerts===0) && (
          <Box mx="auto" mb={4} maxWidth={400}>
            <Alert severity="info">
              You don't have any active alerts! Once created: to activate an alert click the star (⭐) next to the alert in the Saved Alerts box.
            </Alert>
          </Box>
        )}

    {router.query.alert && (
          <AlertLoader 
            setWeatherConditionsList={setWeatherConditionsList} 
            setTimeConditionsList={setTimeConditionsList}
            setSMSConditionsList={setSMSConditionsList}
            setSelectedLocation={setSelectedLocation}
            weatherConditions={weatherConditionsConfig}
            setMarker={setMarker}
            setCenter={setCenter}
            seekingAlertId={seekingAlertId}
            setResetDefaults={setResetDefaults}
            setFixLocation={setFixLocation}
            setActivity={setActivity}
            setUsedLoader={setUsedLoader}
          />
        )}

        <Grid container={true} spacing={4}>
          <Grid item={true} xs={12} md={12}>
            <NewAlertConfig
              setProcessedWeatherConditionChange={setProcessedWeatherConditionChange}
              updatingAlertId={updatingAlertId}
              setUpdatingAlertId={setUpdatingAlertId}
              selectedLocation={selectedLocation} 
              weatherConditionsList = {weatherConditionsList}
              setWeatherConditionsList = {setWeatherConditionsList}
              timeConditionsList = {timeConditionsList}
              setTimeConditionsList={setTimeConditionsList}
              smsConditionsList = {smsConditionsList}
              setSMSConditionsList={setSMSConditionsList}
              weatherConditionsDefault={weatherConditionsDefault}
              timeConditionsDefault={timeConditionsDefault}
              smsConditionsDefault={smsConditionsDefault}
              weatherConditions={weatherConditionsConfig}
              setWeatherConditions={setWeatherConditionsConfig}
              resetDefaults={resetDefaults}
              setResetDefaults={setResetDefaults}
              setSelectedLocation={setSelectedLocation}
              marker={marker}
              setMarker={setMarker}
              map={map}
              setMap={setMap}
              center={center}
              setCenter={setCenter}
              fixLocation={fixLocation}
              setFixLocation={setFixLocation}
              activity={activity}
              setActivity={setActivity}
              hasPhoneNumber={hasPhoneNumber}
              phoneVerified={phoneVerified}
              countActiveAlerts={countActiveAlerts}
              allowedAlerts={allowedAlerts}
              textCredits={textCredits}
              usedLoader={usedLoader}
              setUsedLoader={setUsedLoader}
            />
          </Grid>
        </Grid>
        
        {auth.user &&
        <>
        <Grid container={true} spacing={4}>
            <Grid item={true} xs={12} md={8}>
                <DashboardAlerts
                setCountActiveAlerts={setCountActiveAlerts}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation} 
                setMarker={setMarker} 
                map={map}
                updatingAlertId={updatingAlertId}
                setUpdatingAlertId={setUpdatingAlertId}
                setWeatherConditionsList={setWeatherConditionsList}
                setTimeConditionsList={setTimeConditionsList}
                setSMSConditionsList = {setSMSConditionsList}
                weatherConditions={weatherConditionsConfig}
                setResetDefaults={setResetDefaults}
                setFixLocation={setFixLocation}
                />
            </Grid>
            <Grid item={true} xs={12} md={4}>
                <Card>
                <CardContent className={classes.cardContent}>
                    <Box>
                    <Typography variant="h6" paragraph={true}>
                        <strong>Account Status</strong>
                    </Typography>
                        <Typography component="div">
                        {auth.user && auth.user.stripeSubscriptionId && (
                            <>
                            <div>
                                You {" "}
                                {auth.user.stripeSubscriptionStatus==="active" && <>are</>}
                                {auth.user.stripeSubscriptionStatus!=="active" && <>were</>}
                                {" "}subscribed to the{" "}
                                <strong>{auth.user.planId} plan</strong>
                                {topTierPlan ? " (you're awesome!)": "."}
                            </div>
                            <div>
                                Your plan status is{" "}
                                <strong>
                                {auth.user.stripeSubscriptionStatus}
                                </strong>
                                .
                            </div>
                            <div>
                                Your remaining SMS credits are{" "}
                                <strong>
                                {auth.user.textCredits}
                                </strong>
                                .
                            </div>
                            <div>
                                You have{" "}
                                <strong>
                                {countActiveAlerts}/{allowedAlerts}
                                </strong>
                                {" "}alerts active.
                            </div>
                            <div>
                                {hasPhoneNumber &&
                                <>
                                Alerts will be sent to{" "}
                                <strong>
                                    {auth.user.phone}
                                </strong>
                                
                                </>
                                }
                                {!hasPhoneNumber &&
                                <>
                                Please add a phone number in{" "}
                                <LinkMui component={Link} to="/settings/general">
                                    <strong>settings</strong>
                                </LinkMui>
                                {" "}to receive SMS alerts.
                                </>
                                }
                                
                            </div>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                padding={2}
                                >
                                {!topTierPlan &&
                                <Box padding={1}>
                                <Button
                                    component={Link}
                                    to="/pricing"
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    >
                                    Upgrade Plan
                                </Button>
                                </Box> }
                                {/* <Box padding={1}>
                                <Button
                                    component={Link}
                                    to="/pricing"
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                >
                                    Buy SMS Credits
                                </Button>
                                </Box> */}
                            </Box>
                            </>
                        )}

                        {auth.user && !auth.user.stripeSubscriptionId && (
                            <>
                            <div>
                            Sign up for a {" "}
                            <LinkMui component={Link} to="/pricing">
                                <strong>plan</strong>
                            </LinkMui>
                            {" "}to set up SMS alerts.
                            </div>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                padding={1}
                                >
                            <Box padding={0}>
                                <Button
                                    component={Link}
                                    to="/pricing"
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    >
                                    Choose Plan
                                </Button>
                                </Box>
                              </Box>
                            </>
                        )}
                        </Typography>
                    </Box>
                </CardContent>
                </Card>
            </Grid>
        </Grid>
        </>
        }
      </Container>
    </Section>
    </>
  );
}

export default AlertsSection;
