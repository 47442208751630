import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import EditAlertModal from "../EditAlertModal";
import { useAuth } from "../../util/auth";
import { Link, useRouter } from "../../util/router";
import SinglePicker from "../SinglePicker";
import AlertEditor from "./AlertEditor";
import { createLocalStorageStateHook } from "use-local-storage-state";
import { useHistorical } from "../../util/db";
import { useDarkMode } from "../../util/theme";

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
}));


function getContextualValueFromHistory(historical, minMaxCalc, defaultSlider) {
    // return min value, max value, and min max default slider vals
    const records = historical[0].records;
    let fields = ["min", "max"]
    if (minMaxCalc.fieldOverride!== false) {
        fields = [minMaxCalc.fieldOverride]
    }

    const valuesFound = [];
    
    fields.forEach((f) => {
        records.forEach((record) => {
            const dateKey = Object.keys(record)[0]
            if (minMaxCalc.hasPeriod) {
                if (Object.keys(record[dateKey][minMaxCalc.location]).length >0) {
                    const v = record[dateKey][minMaxCalc.location].all[f]
                    valuesFound.push(v);
                } else {
                    valuesFound.push(0)
                }
                
            } else {
                const v = record[dateKey][minMaxCalc.location][f]
                valuesFound.push(v);
            }
        });
    });

    const avgValuesFound = [];
    if (defaultSlider !== undefined) {
        let sliderField = "mean";
        if (minMaxCalc.fieldOverride!== false) {
            sliderField = minMaxCalc.fieldOverride;
        }
        const period = defaultSlider.period;
        records.forEach((record) => {
            const dateKey = Object.keys(record)[0]
            if (minMaxCalc.hasPeriod) {
                const v = record[dateKey][minMaxCalc.location][period][sliderField]
                avgValuesFound.push(v) 
            } else {
                const v = record[dateKey][minMaxCalc.location][sliderField]
                avgValuesFound.push(v)
            }
        });
    }

    let minFound = Math.min(...valuesFound);
    let maxFound = Math.max(...valuesFound);

    let minWanted = null;
    let maxWanted = null;

    if (defaultSlider !== undefined) {
        const totalValueCount = avgValuesFound.length;
        const orderedValues = avgValuesFound.sort((a, b) => a-b);
        if (defaultSlider.minWanted) {
            const loc = Math.round(totalValueCount * defaultSlider.minWanted / 100);
            if (orderedValues[loc]<1) {
                minWanted = Math.round(orderedValues[loc] * 10)/10;
            } else {
                minWanted = Math.round(orderedValues[loc]);
            }
            
        }
        if (defaultSlider.maxWanted) {
            const loc = Math.round(totalValueCount * defaultSlider.maxWanted / 100);
            if (orderedValues[loc]<1) {
                maxWanted = Math.round(orderedValues[loc] * 10)/10;
            } else {
                maxWanted = Math.round(orderedValues[loc]);
            }
        }
        if (defaultSlider.valueWanted) {
            const loc = Math.round(totalValueCount * defaultSlider.valueWanted / 100);
            if (orderedValues[loc]<1) {
                maxWanted = Math.round(orderedValues[loc] * 10)/10;
            } else {
                maxWanted = Math.round(orderedValues[loc]);
            }
        }
    }

    if (minMaxCalc.excess.type==="percentage") {
        const buffer = maxFound * (minMaxCalc.excess.value / 100);
        minFound -= buffer;
        maxFound += buffer;
    } else {
        minFound -= minMaxCalc.excess.value; // fixed
        maxFound += minMaxCalc.excess.value; // fixed
    }

    // snap to boundaries
    if (minMaxCalc.minBound!==false) {
        if (minFound < minMaxCalc.minBound) {
            minFound = minMaxCalc.minBound
        }
    }

    if (minMaxCalc.maxBound!==false) {
        if (maxFound > minMaxCalc.maxBound) {
            maxFound = minMaxCalc.maxBound
        }
    }

    minFound = Math.round(minFound)
    maxFound = Math.round(maxFound)

    if (minWanted === null) {
        minWanted = minFound;
    }
    if (maxWanted === null) {
        maxWanted = maxFound
    }

    return [minFound, maxFound, minWanted, maxWanted]

}

function NewAlertConfig({
    setProcessedWeatherConditionChange, 
    updatingAlertId, 
    setUpdatingAlertId, 
    selectedLocation, 
    weatherConditionsList, 
    setWeatherConditionsList, 
    timeConditionsList, 
    setTimeConditionsList, 
    smsConditionsList, 
    setSMSConditionsList, 
    weatherConditionsDefault, 
    timeConditionsDefault, 
    smsConditionsDefault, 
    weatherConditions, 
    setWeatherConditions, 
    resetDefaults, 
    setResetDefaults, 
    setSelectedLocation, 
    marker, 
    setMarker, 
    map, 
    setMap, 
    center, 
    setCenter, 
    fixLocation, 
    setFixLocation, 
    activity, 
    setActivity,
    hasPhoneNumber,
    phoneVerified,
    countActiveAlerts,
    allowedAlerts,
    textCredits,
    usedLoader,
    setUsedLoader
    }) {
  const classes = useStyles();
  const auth = useAuth();
  const router = useRouter();
  const darkMode = useDarkMode();

  const lat = (selectedLocation) ? selectedLocation.latitude : null;
  const long = (selectedLocation) ? selectedLocation.longitude : null;

  const visualCrossingImgURL = (darkMode.value) ? "https://ik.imagekit.io/critz/VCWeatherDark.png?ik-sdk-version=javascript-1.4.3&updatedAt=1678204952264" : "https://ik.imagekit.io/critz/VCWeatherLight.png?ik-sdk-version=javascript-1.4.3&updatedAt=1678205833240"


  const {
    data: historical,
    status: historicalStatus,
    error: historicalError,
  } = useHistorical(lat, long);

  if (historicalError) {
    console.log(historicalError)
  }

  let hasHistorical = false;
  if (historical) {
    hasHistorical = historical.length > 0;
  }
 
  useEffect(() => {
    if (historicalStatus==="success") {
        if (hasHistorical) {
            let hasChanged = false;
            // we can update each weather condition with the contextual values
            weatherConditions.forEach((wc, index) => {
                if (wc.minMaxCalc.location !== "none") {
                    let [minV, maxV, minWantedV, maxWantedV] = getContextualValueFromHistory(historical, wc.minMaxCalc, wc.defaultSlider);
                    if ("maxValueWanted" in wc.control) {
                        // multi
                        // check if a change is required
                        if ((wc.control.minValue !== minV) || (wc.control.maxValue !== maxV) || (wc.control.minValueWanted !== minWantedV) || (wc.control.maxValueWanted !== maxWantedV)) {
                            setWeatherConditions((draft) => {
                                draft[index].control.minValue = minV;
                                draft[index].control.maxValue = maxV;
                                draft[index].control.minValueWanted = minWantedV;
                                draft[index].control.maxValueWanted = maxWantedV;
                            });
                            hasChanged = true;
                        }
                    } else {
                        if ((wc.control.minValue !== minV) || (wc.control.maxValue !== maxV) || (wc.control.valueWanted !== maxWantedV)) {
                            setWeatherConditions((draft) => {
                                draft[index].control.minValue = minV;
                                draft[index].control.maxValue = maxV;
                                draft[index].control.valueWanted = maxWantedV;
                                
                            }); 
                            hasChanged = true;
                        }
                    }

                    // so I think we can't rely on the weather conditions list
                    // being copied from the weather conditions AFTER they've been
                    // contextualised
                    // so, on each weatherConditionList change we need to
                    // check for context for the max and min end points.
                    // but also consider the range we're asking for (as we do below)
                    
                    weatherConditionsList.forEach((wci, idx) => {
                        if (wc.title === wci.title) {
                            // check whether existing values live outside the range
                            if ("maxValueWanted" in wci.control) {
                              if (wci.control.maxValueWanted > maxV) {
                                maxV = wci.control.maxValueWanted
                              }
                              if (wci.control.minValueWanted < minV) {
                                minV = wci.control.minValueWanted
                              }
                            } else {
                                if (wci.control.valueWanted > maxV) {
                                    maxV = wci.control.valueWanted
                                }
                            }
                            if ((wci.control.minValue !== minV) || (wci.control.maxValue !== maxV)) {
                                setWeatherConditionsList((draft) => {
                                    draft[idx].control.minValue = minV;
                                    draft[idx].control.maxValue = maxV;
                                })
                                hasChanged = true;
                            }
                            
                        }
                    })

                    // override the defaults to be contextualised.
                    // block comment as not using defaults anymore...
                   /*  weatherConditionsDefault.forEach((wcd, idx) => {
                        if (wc.title === wcd.title) {
                            if ("maxValueWanted" in wcd.control) {
                                // multi
                                setWeatherConditionsDefault((draft) => {
                                    draft[idx].control.minValue = minV;
                                    draft[idx].control.maxValue = maxV;
                                    draft[idx].control.minValueWanted = minWantedV;
                                    draft[idx].control.maxValueWanted = maxWantedV;
                                }); 
                            } else {
                                setWeatherConditionsDefault((draft) => {
                                    draft[idx].control.minValue = minV;
                                    draft[idx].control.maxValue = maxV;
                                    draft[idx].control.valueWanted = maxWantedV;
                                    
                                }); 
                            }

                        }
                    }) */
                }
            });
            if (hasChanged) {
                setProcessedWeatherConditionChange(false)
            }
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historicalStatus])

  // Create a local storage hook for temp preference
  const temps = ["°C", "°F"]
  const localTempPref = createLocalStorageStateHook("localTempPref");
  const [tempPref, setTempPref] = localTempPref();
  if (tempPref === undefined) {
    setTempPref(temps[0])
  }

  const speeds = ["kph", "mph"]
  const localSpeedPref = createLocalStorageStateHook("localSpeedPref");
  const [speedPref, setSpeedPref] = localSpeedPref();
  if (speedPref === undefined) {
    setSpeedPref(speeds[0])
  }

  const distances = ["mm", "inch"]
  const localDistancesPref = createLocalStorageStateHook("localDistancePref");
  const [distancePref, setDistancePref] = localDistancesPref();
  if (distancePref === undefined) {
    setDistancePref(distances[0])
  }

  const [creatingItem, setCreatingItem] = useState(false);

  const handleSaveAsNew = () => {
    setUpdatingAlertId(null);
    setCreatingItem(true)
  }
  
  const resetAlertInputs=  () => {
    setWeatherConditionsList(weatherConditionsDefault)
    setTimeConditionsList(timeConditionsDefault)
    setSMSConditionsList(smsConditionsDefault)
  }

  const handleSavedChanges= () => {
    setUpdatingAlertId(null);
    setCreatingItem(false)
    resetAlertInputs()
    setFixLocation(false)
  }

  const handleDiscardChanges = () => {
    setUpdatingAlertId(null);
    resetAlertInputs();
    setResetDefaults(true);
    setFixLocation(false)
  }

  const hasNoWeatherConditions = (weatherConditionsList.length === 0);

  let authLink = "/auth/signup";

  if (router.query.alert) {
    authLink = "/auth/signup?next=/alerts?alert=" + router.query.alert;
  }

  return (
    <>
    <Card>
        <CardContent className={classes.cardContent}>
        <Box>
            <Grid container spacing={1}>
                <Grid item sm={5} xs={12}>
                    <Typography variant="h5" paragraph={true}>
                    {updatingAlertId && <><strong>Edit Alert</strong></>}
                    {!updatingAlertId && <><strong>Create New Alert</strong></>}
                    </Typography>
                </Grid>
                <Grid item sm={7} xs={12}>
                    <Grid container justifyContent="flex-end">
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            padding = {1}
                            >
                            <SinglePicker formats={tempPref} setFormats={setTempPref} options={temps} disabled={[false, false]}/>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            padding = {1}
                            >
                            <SinglePicker formats={speedPref} setFormats={setSpeedPref} options={speeds} disabled={[false, false]}/>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            padding = {1}
                            >
                            <SinglePicker formats={distancePref} setFormats={setDistancePref} options={distances} disabled={[false, false]}/>
                        </Box>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <AlertEditor 
                        tempPref={tempPref} 
                        speedPref={speedPref} 
                        distancePref={distancePref} 
                        setWeatherConditionsList={setWeatherConditionsList} 
                        setTimeConditionsList={setTimeConditionsList} 
                        setSMSConditionsList={setSMSConditionsList} 
                        weatherConditionsList={weatherConditionsList} 
                        timeConditionsList={timeConditionsList} 
                        smsConditionsList={smsConditionsList} 
                        updatingAlertId={updatingAlertId} 
                        hasHistorical={hasHistorical} 
                        historicalStatus={historicalStatus}
                        historical={historical} 
                        weatherConditions={weatherConditions} 
                        setWeatherConditions={setWeatherConditions} 
                        selectedLocation={selectedLocation}
                        setSelectedLocation={setSelectedLocation}
                        marker={marker}
                        setMarker={setMarker}
                        map={map}
                        setMap={setMap}
                        center={center}
                        setCenter={setCenter}
                        fixLocation={fixLocation}
                        setFixLocation={setFixLocation}
                        activity={activity}
                        setActivity={setActivity}
                        usedLoader={usedLoader}
                        setUsedLoader={setUsedLoader}
                        />
                </Grid>         
                <Grid container align="end" direction="row-reverse">
                <Grid item xs={12} sm={8} md={9}>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        padding={1}
                        >
                        {updatingAlertId && 
                            <Box padding={1}>
                                <Button
                                variant="contained"
                                size="medium"
                                color="primary"
                                onClick={() => handleDiscardChanges()}
                                >
                                    Discard Changes
                                </Button>
                            </Box>}
                        {auth.user &&
                        <>
                        <Box padding={1}>
                            <Button
                                variant="contained"
                                size="medium"
                                color="primary"
                                disabled={hasNoWeatherConditions}
                                onClick={() => setCreatingItem(true)}
                            >
                                Save Alert
                            </Button>
                        </Box>
                        { updatingAlertId && false &&
                        <Box padding={1}>
                            <Button
                                variant="contained"
                                size="medium"
                                color="primary"
                                disabled={hasNoWeatherConditions}
                                onClick={() => handleSaveAsNew()}
                            >
                                Save As New
                            </Button>
                        </Box>
                        }
                        </>}
                        {!auth.user &&
                        <>
                        <Box padding={1}>
                            <Button
                                variant="contained"
                                size="medium"

                                color="primary"
                                component={Link}
                                to={authLink}
                                style={{textAlign: "center"}}
                            >
                                Create an account
                            </Button>
                        </Box>
                        <Box padding={1}>
                            <Button
                                variant="contained"
                                size="medium"

                                color="primary"
                                component={Link}
                                to="/pricing"
                                style={{textAlign: "center"}}
                            >
                                Explore pricing
                            </Button>
                        </Box>
                        </>}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                <Box
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                        padding={0}
                        style={{marginTop: "7px", marginBottom:"-20px"}}
                        >
                <a href="https://visualcrossing.com" target="blank_"><img
                    src={visualCrossingImgURL}
                    className={classes.image}
                    alt="Powered by Visual Crossing Weather"
                    height="70px"
                /></a>
                </Box>
                </Grid>
                </Grid>
            </Grid>
        </Box>
        </CardContent>
    </Card>

    {creatingItem && <EditAlertModal id={updatingAlertId} selectedLocation={selectedLocation} weatherConditionsList={weatherConditionsList} timeConditionsList={timeConditionsList} smsConditionsList={smsConditionsList} setSMSConditionsList={setSMSConditionsList} setCreatingItem={setCreatingItem} hasPhoneNumber={hasPhoneNumber} phoneVerified={phoneVerified} countActiveAlerts={countActiveAlerts} allowedAlerts={allowedAlerts} textCredits={textCredits} onDone={() => handleSavedChanges()} />}

    </>
  );
}

export default NewAlertConfig;
