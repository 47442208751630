import React, { useEffect } from "react";
import Meta from "./../components/Meta";
import SideHeroSection from "../components/SideHeroSection";
import FeaturesSection from "./../components/FeaturesSection";
import CtaSection from "./../components/CtaSection";
import HomePageWidget from "../components/HomePageWidget";
import useFetch from "react-fetch-hook";
import PricingSection from "../components/PricingSection";

function IndexPage(props) {

  const [currencySymbol, setCurrencySymbol] = React.useState("£");
  const [heroSubtitle, setHeroSubtitle] = React.useState("Starting at just $3 per year.")

  const [city, setCity] = React.useState(null);
  const [latitude, setLatitude] = React.useState(null);
  const [longitude, setLongitude] = React.useState(null);
  const [hasLocation, setHasLocation] = React.useState(false);

  const { data } = useFetch(
      "https://api.weatherapi.com/v1/ip.json?key=3b4d6ff32081410e984130721231402&q=auto:ip"
  )

  useEffect(() => {
      if (data) {
          setCity(data.city);
          setLatitude(data.lat)
          setLongitude(data.lon)
          //setLongitude(-16.7) // use this to test locations
          //setLatitude(28.05)
          setHasLocation(true);
          if (data.country_code==="GB") {
            setCurrencySymbol("£")
          } else if (data.is_eu === true) {
            setCurrencySymbol("€")
          } else {
            setCurrencySymbol("$")
          }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    setHeroSubtitle("From just " + currencySymbol + "3 per year.")
  }, [currencySymbol])

  return (
    <>
      <Meta />
      <SideHeroSection
        size="medium"
        bgImage="https://ik.imagekit.io/critz/mountains.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1675339577167"
        title="Never miss a perfect day."
        subtitle="Get SMS alerts whenever the weather is perfect for adventure."
        subtitle2={heroSubtitle}
        buttonText="Create an alert"
        buttonColor="secondary"
        buttonPath="/alerts"
      />
      <FeaturesSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title=""
        subtitle=""
      />
      {hasLocation &&
        <HomePageWidget latitude={latitude} longitude={longitude} city={city} currencySymbol={currencySymbol}/>
      }
      <PricingSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Never miss a perfect day again"
        subtitle=""
        currencySymbol={currencySymbol}
      />
      <CtaSection
        bgColor="primary"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Want to see it in action?"
        subtitle=""
        buttonText="Create an alert"
        buttonColor="secondary"
        buttonPath="/alerts"
      />
    </>
  );
}

export default IndexPage;
