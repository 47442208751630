import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Section from "./Section";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
    useTheme
  } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import { useDarkMode } from "../util/theme";

    


const useStyles = makeStyles((theme) => ({
    // Increase <Container> padding so it's
    // at least half of <Grid> spacing to
    // avoid horizontal scroll on mobile.
    // See https://material-ui.com/components/grid/#negative-margin
    container: {
      padding: `0 ${theme.spacing(3)}px`,
      textAlign: "center",
    },
    image: {
        height: "auto",
        maxWidth: "180px",
      },
  }));


function PlaceHolderExampleAlertFilter() {

    const classes = useStyles();

    const theme = useTheme();
    const aboveSM = useMediaQuery(theme.breakpoints.up('sm'));

    const darkMode = useDarkMode();
    


    const widgetImgSrc = (darkMode.value) ? ((aboveSM) ? "https://ik.imagekit.io/critz/WidgetDark_1_.png?ik-sdk-version=javascript-1.4.3&updatedAt=1678290748040" : "https://ik.imagekit.io/critz/WidgetDarkMobile.png?ik-sdk-version=javascript-1.4.3&updatedAt=1678291000008") : ((aboveSM) ? "https://ik.imagekit.io/critz/WidgetLight.png?ik-sdk-version=javascript-1.4.3&updatedAt=1678291660547" : "https://ik.imagekit.io/critz/WidgetLightMobile.png?ik-sdk-version=javascript-1.4.3&updatedAt=1678291877123")

    return (
        <Section
          bgColor="default"
          size="medium"
          bgImage=""
          bgImageOpacity={1}
        >
        <Grid
              container={true}
              alignItems="center"
              justifyContent="center"
              spacing={0}
            >
             <Grid item xs={6}>
                        <img
                            src="https://ik.imagekit.io/critz/tryitout.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677670911353"
                            alt="try it out pointer"
                            className={classes.image}
                        />
                        </Grid>
        </Grid>
          <Container className={classes.container}>
            <Grid
              container={true}
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
            <Grid item xs={12} md={8}> 
            <div>
                <Box 
                        sx={{
                            width: "100%",
                            objectFit: "contain",
                        }}
                            component="img"
                            src={widgetImgSrc}
                            
                        />

                <div style={{
                    position: "absolute",
                    top: "25%", 
                    left: '50%', 
                    transform: 'translateX(-50%)'
                }}>
                    <Typography variant="h4">
                    👋 Hi, you must be new here!
                    </Typography>
                    <Typography variant="body1" style={{marginBottom:"22px"}}>
                        Just fetching some local weather data. Give it a few seconds! 🥳
                    </Typography>
                <CircularProgress size={50}/>
                </div>
                </div>
            </Grid>
            </Grid>
          </Container>
        </Section>
      );
    }

export default PlaceHolderExampleAlertFilter;



