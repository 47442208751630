import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";


function AwaitingReportLoading({activity, setActivity}) {

    return (
        
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding = {0}
            sx={{ 
                padding: "0", 
                margin: "4px",
            }}
        >
            <Grid item xs={12}>
                <CircularProgress size={38} />
                <Typography variant="h6">
                        Calculating your perfect weather settings
                </Typography>
                
            </Grid>
        </Box>
        
      );
    }

export default AwaitingReportLoading;



