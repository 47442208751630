import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {MuiTelInput, matchIsValidTel} from 'mui-tel-input';
import { useForm } from "react-hook-form";
import { useAuth } from "./../util/auth";
import { withStyles } from "@material-ui/styles";
import { useDarkMode } from "./../util/theme";

function SettingsGeneral(props) {

  const darkMode = useDarkMode();

  const CssMuiTelInput = withStyles({
    root: {
      '& label': {
        color: darkMode.value ? '#bdbdbd' : '#757575',
      },
      '& input': {
        color: darkMode.value ? '#FFF' :'#212121',
      },
      '& label.Mui-focused': {
        color: '#ffc23e',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: darkMode.value ? '#545454' : '#c4c4c4',
        },
        '&:hover fieldset': {
          borderColor: darkMode.value ? '#FFF' :'#212121',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#ffc23e',
        },
      },
    }
  })(MuiTelInput)

  const auth = useAuth();
  const [pending, setPending] = useState(false);

  const [phone, setPhone] = React.useState(auth.user.phone);
  const [phoneError, setPhoneError] = React.useState(false);
  const [info, setInfo] = React.useState(null)

  const handleChange = (newPhone, info) => {
    setPhone(newPhone);
    setInfo(info);
  }


  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    // first check for phone errors
    // but accept no value
    if (data.phone.length>0) {
      if (!(matchIsValidTel(data.phone))) {
        setPhoneError(true)
        props.onStatus({
          type: "error",
          message: "Please correct your phone number - it must start with a country code like +44, or +1",
        });
        return
      }
      setPhoneError(false);
      if (info) {
        data.phone = info.numberValue
      } else {
        data.phone = auth.user.phone
      }
    } else {
      data.phone = ""
    }
    // Show pending indicator
    setPending(true);

    return auth
      .updateProfile(data)
      .then(() => {
        // Set success status
        props.onStatus({
          type: "success",
          message: "Your profile has been updated",
        });
      })
      .catch((error) => {
        if (error.code === "auth/requires-recent-login") {
          props.onStatus({
            type: "requires-recent-login",
            // Resubmit after reauth flow
            callback: () => onSubmit(data),
          });
        } else {
          // Set error status
          props.onStatus({
            type: "error",
            message: error.message,
          });
        }
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <TextField
            variant="outlined"
            type="text"
            label="Name"
            name="name"
            placeholder="Name"
            defaultValue={auth.user.name}
            error={errors.name ? true : false}
            helperText={errors.name && errors.name.message}
            fullWidth={true}
            inputRef={register({
              required: false,
            })}
          />
        </Grid>
        <Grid item={true} xs={12}>
            <CssMuiTelInput
              autoFocus
              value={phone} 
              onChange={handleChange}
              preferredCountries={['GB', 'US']}
              defaultCountry="GB"
              variant="outlined"
              label="SMS enabled Phone"
              name="phone"
              error={phoneError}
              helperText={phoneError && "Invalid Phone Number"}
              fullWidth={true}
              inputRef={register({
                required: false,
              })}/>
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            variant="outlined"
            type="email"
            label="Email"
            name="email"
            placeholder="user@example.com"
            defaultValue={auth.user.email}
            error={errors.email ? true : false}
            helperText={errors.email && errors.email.message}
            fullWidth={true}
            inputRef={register({
              required: "Please enter your email",
            })}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled={pending}
            fullWidth={true}
          >
            {!pending && <span>Save</span>}

            {pending && <CircularProgress size={28} />}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default SettingsGeneral;
