import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid";
import SinglePicker from "./SinglePicker";
import ConditionBoxControl from "./ConditionBoxControl";
import PercentageIndicator from "./PercentageIndicator";
import { convertValueToPreference } from "./ConditionAddModal";

export default function BigConditionBox({updatingIndex, selectionIndex, weatherConditionsList, setWeatherConditionsList, tempPref, speedPref, distancePref, setActiveGraphPeriod, weatherConditions, localSliderValues, setLocalSliderValues, percentage, isRadar}) {

    const times = ["Day", "Night", "24 Hr"];

    let weatherConditionUpdating = weatherConditions[selectionIndex]

    if (updatingIndex !== null) {
        weatherConditionUpdating = weatherConditionsList[updatingIndex];
        
    }

    const [timePref, setTimePref] = useState(weatherConditionUpdating.periodSelected);
    const periodsDisabled = weatherConditionUpdating.periodDisabled;

    const markers = weatherConditionUpdating.control.markers;

    useEffect(()=>{
        if (updatingIndex !== null) {
            const unit = weatherConditionUpdating.control.unitType
            if ("maxValueWanted" in weatherConditionUpdating.control) {
                const localisedMin = convertValueToPreference(weatherConditionUpdating.control.minValueWanted, unit, tempPref, distancePref, speedPref)
                const localisedMax = convertValueToPreference(weatherConditionUpdating.control.maxValueWanted, unit, tempPref, distancePref, speedPref)
                // this needs to account for user preferences.
                setLocalSliderValues([localisedMin, localisedMax])
            } else {
                const localisedMax = convertValueToPreference(weatherConditionUpdating.control.valueWanted, unit, tempPref, distancePref, speedPref)
                setLocalSliderValues(localisedMax)
            }
        }

        if (timePref[0]==="Day") {
            setActiveGraphPeriod("day")
        } else if (timePref[0]==="Night") {
            setActiveGraphPeriod("night")
        } else if (timePref[0]==="24 Hr") {
            setActiveGraphPeriod("all");
        } else if (timePref==="Day") {
            setActiveGraphPeriod("day")
        } else if (timePref==="Night") {
            setActiveGraphPeriod("night")
        } else if (timePref==="24 Hr") {
            setActiveGraphPeriod("all");
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        // change the weather conditions object on changes to the child.
        if (timePref==="Day") {
            setActiveGraphPeriod("day")
        } else if (timePref==="Night") {
            setActiveGraphPeriod("night")
        } else if (timePref==="24 Hr") {
            setActiveGraphPeriod("all");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timePref, setWeatherConditionsList, updatingIndex]);
    
    return (
        <>
      
              <Grid container alignItems="center">
                    <Grid item xs={12}>
                    <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                >
                        <Grid item xs={10} sm={12} md={8} lg={8}>
                            <ConditionBoxControl 
                                darkColour={"#424242"} 
                                control={weatherConditionUpdating.control} 
                                setWeatherConditionsList={setWeatherConditionsList} 
                                index={updatingIndex} 
                                tempPref={tempPref} 
                                speedPref={speedPref} 
                                distancePref={distancePref} 
                                updateWeatherListObj={false} 
                                markers={markers} 
                                localSliderValues={localSliderValues}
                                setLocalSliderValues={setLocalSliderValues}/>
                        </Grid>
                        <Grid item xs={6} sm={3} md={2} lg={2}>
                            <Box
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                            paddingBottom={1}
                            paddingRight={1}>
                                {!isRadar &&
                                    <PercentageIndicator percentage={percentage}/>
                                }
                                
                            </Box>
                        </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={1}/>
                    <Grid item xs={11}>
                        <Grid container justifyContent="flex-start">

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                >
                                <SinglePicker formats={timePref} setFormats={setTimePref} options={times} disabled={periodsDisabled}/>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

      </>
    );
  }
  