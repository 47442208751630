import React from "react";
import LocationBox from "./LocationBox";
import ConditionBox from "../../ConditionBox";

import Grid from "@material-ui/core/Grid";
import ConditionTimeBox from "../../ConditionTimeBox";


export default function ConditionCards({
    selectedLocation, 
    weatherConditionsList, 
    timeConditionsList,
    updatingAlertId, 
    setWeatherConditionsList,
    setTimeConditionsList,
    tempPref,
    speedPref,
    distancePref,
    setUpdatingIndex,
    setNewCondition,
    hasHistorical,
    activity
    }) {


    return (
        <>
            <Grid item={true} xs={12} sm={6} md={4} lg={3} style={{display: 'flex'}}>
                <LocationBox selectedLocation={selectedLocation}/>         
            </Grid>
            {weatherConditionsList.map((w, index) => {
                    return (
                        <Grid item={true} xs={12} sm={6} md={4} lg={3} key={updatingAlertId + w.title + index} style={{display: 'flex'}}>
                            <ConditionBox title={w.title} subtitle={w.description} periodsDisabled={w.periodDisabled} periodSelected={w.periodSelected} control={w.control} setWeatherConditionsList={setWeatherConditionsList} index={index} tempPref={tempPref} speedPref={speedPref} distancePref={distancePref} setUpdatingIndex={setUpdatingIndex} setNewCondition={setNewCondition} enabled={hasHistorical} activity={activity} key={activity+w.title+w.periodSelected}/>
                        </Grid>
                    )
                })}
            {timeConditionsList.map((t, index) => {
                    return (
                        <Grid item={true} xs={12} sm={6} md={4} lg={3} key={updatingAlertId + t.title + index} style={{display: 'flex'}}>
                            <ConditionTimeBox title={t.title} subtitle={t.description} control={t.control} setTimeConditionsList={setTimeConditionsList} index={index} hideDelete={false}/>
                        </Grid>
                    )
                })}
      </>
    );
  }
  