import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ReportActivitySettings from "./ReportActivitySettings"



function ReportLoaded({report, activity, tempPref, distancePref, speedPref}) {

    const activityReport = report[activity]

    const totalGoodDays = activityReport.total;

    const conditions = activityReport.conditions;

    return (
        
        <Box
            display="flex"
            padding = {0}
            sx={{ 
                padding: "0", 
                margin: "0px",
            }}
        >
            <Grid item xs={12}>
                    <Typography variant="h5" display="inline">
                        You'll get around&nbsp;
                    </Typography>
                    <Typography variant="h5" display="inline" color="primary">
                        <strong>{totalGoodDays}</strong>
                    </Typography>
                    <Typography variant="h5" display="inline">
                        &nbsp;alerts this year with these settings        
                    </Typography>
                    <ReportActivitySettings conditions={conditions} tempPref={tempPref} speedPref={speedPref} distancePref={distancePref}/>
            </Grid>
        </Box>
        
      );
    }

export default ReportLoaded;



