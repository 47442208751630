import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { useAuth } from "../util/auth";


function handleActivateAlert(passingObj, hasLapsedSubscription, signedUpNoSubscription, notSignedUp) {
    // the aim of this is to build a URL I can send to the alerts page, 
    // and have the alerts page rebuild this with the data inside the URL
    
    console.log("User has the following states:")
    console.log("hasLapsed: " + hasLapsedSubscription)
    console.log("signedUpNotSubd: " + signedUpNoSubscription)
    console.log("notSignedUp: " + notSignedUp)
    
}

function getPassingObjectFromReport(longitude, latitude, report, activity) {

    const alert = report[activity].conditions;

    const passingObj = {
        longitude,
        latitude,
        alert,
        activity,
    }

    return passingObj
}

function ReportExitRoute({longitude, latitude, report, activity}) {

// if user is logged in
//      if user has a paid account let them save it, or edit it.
// else 
//      path to sign up
//      edit this alert
// if user is not logged in
//      path to sign up
//      edit this alert

    const auth = useAuth();

    const hasActiveSubscription = auth.user && auth.user.stripeSubscriptionId && auth.user.stripeSubscriptionStatus==="active"
    const hasLapsedSubscription = auth.user && auth.user.stripeSubscriptionId && auth.user.stripeSubscriptionStatus!=="active"
    const signedUpNoSubscription = auth.user && !auth.user.stripeSubscriptionId
    const notSignedUp = !auth.user

    const passingObj = getPassingObjectFromReport(longitude, latitude, report, activity)

    return (
        
        <Box
            display="flex"
            padding = {0}
            sx={{ 
                padding: "0", 
                margin: "4px",
            }}
        >
            <Grid item xs={12}>
                <Box display="flex"
                        justifyContent="center"
                        alignItems="center"
                        padding={0}
                        >
                    {!hasActiveSubscription && false && // disable for now
                    <Box padding={1}>
                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            onClick={() => handleActivateAlert(passingObj, hasLapsedSubscription, signedUpNoSubscription, notSignedUp)}
                        >
                            Activate Alert
                        </Button>
                    </Box>
                    }
                    <Box padding={1}>
                        <Link to ={{
                            pathname: "alerts",
                        }}
                        style={{ textDecoration: 'none' }}>
                            <Button
                                variant="contained"
                                size="medium"
                                color="secondary"
                            >
                                Change Location
                            </Button>
                        </Link>
                    </Box>
                    <Box padding={1}>
                        <Link to ={{
                            pathname: "alerts",
                            state: { passingObj: passingObj}
                        }}
                        style={{ textDecoration: 'none' }}>
                            <Button
                                variant="contained"
                                size="medium"
                                color="primary"
                            >
                                View in Editor
                            </Button>
                        </Link>
                    </Box>
                </Box>
            </Grid>
            
        </Box>
        
      );
    }

export default ReportExitRoute;
