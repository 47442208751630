import React from "react";
import LongContent from "./LongContent";

function LegalPrivacy(props) {
  return (
    <LongContent>
      <h1>Privacy Policy</h1>
      <p>
        Your privacy is important to us. It’s our policy to respect your privacy
        and comply with any applicable law and regulation regarding the personal
        information we collect about you through this website, {props.domain},
        and through any associated services and third-party platforms we may
        use.
      </p>
      <p>This policy was last updated on January 6th, 2023.</p>
      <h2>Application</h2>
      <p>This policy applies to the following data subjects:</p>
      <ul>
        <li><strong>Customers</strong> - Those who register for an account on WxTxt.co and to whom WxTxt provides its Services.</li>
        <li><strong>Website Visitors</strong> - Those who visit our website.</li>
      </ul>
      <p>WxTxt.co is not intended for, nor designed to attract, individuals under the age of eighteen. WxTxt does not knowingly collect personal information from any person under the age of eighteen. You represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Websites and Services.</p>
      <h2>Information we collect</h2>
      <p>We may collect the following types of data in order to provide and improve our Services:</p>
      <ul>
        <li><strong>Personal Data</strong> - While using our Services, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to your name, email address, phone number, and billing addresses necessary to process payment and delivery of Services.</li>
        <li><p><strong>Payment Information</strong> - We do not require our Customers to have payment information on file with us unless they have a paid subscription to our Services.</p><p>When you sign up for one of our paid Subscriptions, you must provide payment and billing information. The information you will need to submit depends on which payment method you choose. For clarity, we do not store full credit card numbers or personal account numbers, these are handled by our payment provider.</p></li>
        <li><strong>Usage Data</strong> - When you visit our Websites, we gather certain information and store it in log files to be able to provide you with our Services. This information may include but is not limited to Internet Protocol (IP) addresses, device or system information, URLs of referrer pages, browser information and language preferences.</li>
        <li><p><strong>Cookie Data</strong> - We use essential cookies on our Websites to provide our Services. Without these cookies, the services that you have asked for cannot be provided, and we only use these cookies to provide you those services.</p></li>
      </ul>
      <h2>How we use the information</h2>
      <p>We only processes personal information in a way that is relevant for the purpose for which it was collected or authorized:</p>
      <ul>
        <li>To enable you to access and use the Website and Services.</li>
        <li>To operate and improve the Website and Services.</li>
        <li>To send transactional messages, provide customer support, updates, security alerts, and account related messages.</li>
        <li>To process and complete transactions, and send you related information, including purchase confirmations and invoices.</li>
        <li>To comply with legal obligations to investigate and prevent fraudulent transactions, unauthorized access, and other illegal activities.</li>
        <li>To personalize the Website and Services.</li>
        <li>To process your data for other purposes for which we obtain your consent.</li>
      </ul>
      <h2>Sharing of information</h2>
      <p>We do not sell or rent personal information.</p>
      <p>We may disclose personal information to service providers who help us run the Services and our business (“Service Providers”). These Service Providers may only process personal information pursuant to our instructions and in compliance both with this Privacy Policy and other applicable security measures and regulations.</p>
      <p>In addition to sharing with Service Providers as described above, we also may share your information with others in the following circumstances:</p>
      <ul>
        <li>In the event of a merger, sale, change in control, or reorganisation of all or part of our business.</li>
        <li>When we are required to disclose personal information to respond to subpoenas, court orders, or legal process, or to establish or exercise our legal rights or defend against legal claims.</li>
        <li>Where we have a good-faith belief sharing is necessary to investigate, prevent or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, or violations of our Terms and Conditions, or as otherwise required to comply with our legal obligations.</li>
        <li>Other purposes for which we obtain your consent.</li>
      </ul>
      <h2>Data Transfer</h2>
      <p>Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those in your jurisdiction.</p>
      <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer. We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</p>
      <h2>Retention Period</h2>
      <p>We may retain your information for as long as your account is active or as needed to provide you services, comply with our legal obligations, resolve disputes and enforce our agreements. In certain circumstances we may be required by law to retain your personal information, or may need to retain your personal information in order to continue providing a service.</p>
      <p>Even if you delete your account, keep in mind that the deletion by our third party providers may not be immediate and that the deleted information may persist in backup copies for a reasonable period of time.</p>
      <h2>Communication Preferences</h2>
      <p>WxTxt will send you service-related communications. You may manage your receipt of commercial communications by clicking on the “unsubscribe” link located on the bottom of such emails, through your account settings if you have an account.</p>
      <p>To stop receiving texts you can (i) deactivate all alerts in your account setting or (ii) remove your phone number from your account.</p>
      <h2>Notification of changes</h2>
      <p>If we make changes to this Policy that we believe materially impact the privacy of your personal information, we will promptly provide notice of any such changes (and, where necessary, obtain consent), as well as post the updated Policy on this website noting the date of any changes via the "Last updated" date at the top of this Privacy Policy. We encourage you to periodically review this page for the latest information on our privacy practices.</p>
      <h2>UK and EU Residents</h2>
      <p>Please note that the “personal information” referenced in this Privacy Policy means “personal data” as that term is defined under the European Union (“EU”) General Data Protection Regulations (“GDPR”) and its United Kingdom (“UK”) GDPR counterpart. Cornelius Services Limited is the data controller for the Personal Data of Customers and Website Visitors.</p>
      <p>If you are an individual from the European Economic Area (the “EEA”), the UK or Switzerland, please note that our legal basis for collecting and using your personal information will depend on the personal information collected and the specific context in which we collect it.</p>
      <p>However, we will normally collect personal data from you only (i) where the processing is in our legitimate interests and not overridden by your rights; (ii) where we need the personal data to perform a contract with you or (iii) where we have your consent to do so. In some cases, we may also have a legal obligation to collect personal data from you.</p>
      <p>Please note that in most cases, if you do not provide the requested information, we will not be able to provide the requested service to you. In some cases, we may also have a legal obligation to collect personal information from you, or may need the personal information to protect your vital interests or those of another person.</p>
      <p>Where we rely on your consent to process your personal data, you have the right to withdraw or decline consent at any time. Where we rely on our legitimate interests to process your personal data, you have the right to object.</p>
      <h2>California Residents</h2>
      <p>We do not sell, rent, or share personal information with third parties as defined under the California Consumer Privacy Act of 2018 (California Civil Code Sec. 1798.100 et seq.), nor do we sell, rent, or share personal information with third parties for their direct marketing purposes as defined under California Civil Code Sec. 1798.83.</p>
      <h2>Business transactions</h2>
      <p>We may assign or transfer this Policy, as well as information covered by this Policy, in the event of a merger, sale, change in control, or reorganisation of all or part of our business.</p>
      <h2>Contact Us</h2>
      <p>If you have any questions you may contact us through the contact form, or via email to <a href="mailto:matt@wxtxt.co">matt@wxtxt.co</a></p>
      <br></br>
      <p>{props.companyName} is a Limited Company registered in England - company number 12819435</p>
      
    </LongContent>
  );
}

export default LegalPrivacy;
