import React, { useEffect, useState } from "react";
// import Plot from 'react-plotly.js';
//import Plotly from "plotly.js-basic-dist-min";
import Plotly from "../util/plotly-custom-min"
import createPlotlyComponent from "react-plotly.js/factory"
import { rawNumberToColour } from "../util/graphing";
import { useDarkMode } from "../util/theme";
import { graphMap } from "../util/graphMap";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import _ from "lodash";
import {
    useTheme
  } from "@material-ui/core/styles";

import { translateValueToPref, valuetext } from "./ConditionSlider";

const Plot = createPlotlyComponent(Plotly)

function getContext(dateKey, wcTitle, notBelow, notAbove, minValFound, maxValFound, unitType, isMulti, tempPref, distancePref, speedPref) {

    let lower = notBelow;
    let upper = notAbove;
    if (lower === "min") {
        lower = "Min";
    }
    if (upper === "max") {
        upper = "Max";
    } else if (upper==="mean") {
        upper = "Average";
    } else if (upper==="total") {
        upper = "Total";
    }

    let context = "<b>" + dateKey + "</b><br>" + wcTitle + "<br>"

    let minV = translateValueToPref(minValFound, unitType, tempPref, speedPref, distancePref);
    minV = valuetext(minV, unitType, tempPref, speedPref, distancePref)
    let maxV = translateValueToPref(maxValFound, unitType, tempPref, speedPref, distancePref);
    maxV = valuetext(maxV, unitType, tempPref, speedPref, distancePref)
    

    if (isMulti) {
        if (minValFound === maxValFound) {
            // probably average or similar, just one line
            context += upper + ": " + maxV
        } else {
            context += lower + ": " + minV + "<br>" + upper + ": " + maxV
        }
    } else {
        context += upper + ": " + maxV
    }
    

    return context

}

function isActiveMonth(date, options) {
    const asDate = new Date(date)
    const month = asDate.toLocaleString('default', { month: 'short' }).substring(0, 3);
    const inActive = options.includes(month);
    return inActive
    
}

function isActiveDayOfWeek(date, options) {
    const asDate = new Date(date)
    const dayOfWeek = asDate.toLocaleString('default', { weekday: 'short' }).substring(0, 3);
    const inActive = options.includes(dayOfWeek)
    return inActive
}

function consecutiveDayCheck(date, sortedAggregate, daysWanted) {

    const startDate = new Date(date)

    let pass = true;
    const checkNextDays = daysWanted -1;

    for (let i=0; i<checkNextDays; i++) {
        const nextDate = new Date(startDate)
        nextDate.setDate(startDate.getDate() + i + 1)
        const nextDateKey = nextDate.toISOString().substring(0, 10);
        if (nextDateKey in sortedAggregate) {
            pass *= sortedAggregate[nextDateKey]
        } else {
            pass = false;
        }
    }

    return pass
}

function passesTimeConditionsCheck(timeConditionsList, date) {
    let passes = true;
    timeConditionsList.forEach((tc) => {
        if (tc.title === "Choose Months") {
            passes *= isActiveMonth(date, tc.control.selected);
        } else if (tc.title === "Choose Days") {
            passes *= isActiveDayOfWeek(date, tc.control.selected)
        }
    })

    return passes;
}

function passesConsecutiveDaysCheck(timeConditionsList, date, sortedAggregate) {
    let passes = true;
    timeConditionsList.forEach((tc) => {
        if (tc.title === "Consecutive Days") {
            if (tc.control.valueWanted > 1) {
                passes *= consecutiveDayCheck(date, sortedAggregate, tc.control.valueWanted)
            }
        }
    })
    return passes;
}

function getWindValFromCompassPoint(p) {

    switch(p) {
        case "S":
            return 0;
        case "SSW":
            return 0.25;
        case "SW":
            return 0.5;
        case "WSW":
            return 0.75;
        case "W":
            return 1;
        case "WNW":
            return 1.25;
        case "NW":
            return 1.5;
        case "NNW": 
            return 1.75;
        case "N":
            return 2;
        case "NNE": 
            return 2.25;
        case "NE":
            return 2.5;
        case "ENE":
            return 2.75;
        case "E":
            return 3;
        case "ESE":
            return 3.25;
        case "SE":
            return 3.5;
        case "SSE":
            return 3.75;
        default:
            console.log("Not a valid wind direction!")
            return "Huh?"
    }

}

function checkWindValidity(minValFound, minWanted, maxValFound, maxWanted) {

    const floorAdjustment = Math.floor(minWanted / 4);

    const newMinWanted = minWanted - (4 * floorAdjustment);
    const newMaxWanted = maxWanted - (4 * floorAdjustment);

    const minFoundAsNum = getWindValFromCompassPoint(minValFound)
    let maxFoundAsNum = getWindValFromCompassPoint(maxValFound)

    if (maxFoundAsNum < minFoundAsNum) {
        // need to change the range of max found
        maxFoundAsNum +=4
    }

    let pass = false;
    let mins = [minFoundAsNum, minFoundAsNum+4]
    let maxs = [maxFoundAsNum, maxFoundAsNum+4]

    mins.forEach((min) => {
        if ((min>=newMinWanted) && (min<=newMaxWanted)) {
            maxs.forEach((max) => {
                if ((max>=newMinWanted) && (max<=newMaxWanted)) {
                    pass = true;
                }
            })
        }
    })

    return pass;
}

function PlotHits({historical, weatherConditionsList, timeConditionsList, setPercentage, tempPref, distancePref, speedPref}) {


    const theme = useTheme();
    const aboveSM = useMediaQuery(theme.breakpoints.up('sm'));

    const margin = (aboveSM) ? {b:20, l:10, r:10, t:0} : {b:40, l:10, r:10, t:0};

    // DATA MANIPULATION
    const records = historical[0].records;
    const wcHits = [];
    const aggregate = {}
    let lastMinValFound = 0;
    let lastMaxValFound = 0;
    if (weatherConditionsList !== undefined) {
        weatherConditionsList.forEach((wc) => {
            const unitType = wc.control.unitType;
            const location = graphMap[wc.title].location;
            const usePeriods = graphMap[wc.title].usePeriods;
            const notBelow = graphMap[wc.title].filter.notBelow;
            const notAbove = graphMap[wc.title].filter.notAbove;
            const periodSelected = wc.periodSelected[0];
                let p = ""
                if (periodSelected === "Day") {
                    p="day";
                } else if (periodSelected === "Night") {
                    p = "night";
                } else if (periodSelected==="24 Hr") {
                    p = "all"
                }
            if ("maxValueWanted" in wc.control) {
                // multi slider
                const xDates = []
                const yHits = []
                const context = [];
                const minWanted = wc.control.minValueWanted;
                const maxWanted = wc.control.maxValueWanted;
                records.forEach((record) => {
                    const dateKey = Object.keys(record)[0]
                    if (!(dateKey in aggregate)) {
                        aggregate[dateKey] = 1;
                    }
                    xDates.push(new Date(dateKey))
                    let minValFound = 0;
                    let maxValFound = 0;
                    if (usePeriods) {
                        if (_.isEmpty(record[dateKey][location])) {
                            console.log("Backfilling a missing value in chart...")
                            minValFound = lastMinValFound
                            maxValFound = lastMaxValFound
                        } else {
                            minValFound = record[dateKey][location][p][notBelow];
                            maxValFound = record[dateKey][location][p][notAbove];
                        }
                    } else {
                        if (_.isEmpty(record[dateKey][location])) {
                            console.log("Backfilling a missing value in chart...")
                            minValFound = lastMinValFound
                            maxValFound = lastMaxValFound
                        } else {
                            minValFound = record[dateKey][location][notBelow];
                            maxValFound = record[dateKey][location][notAbove];
                        }
                    }
                    lastMinValFound = minValFound //need these to backfill missing vals
                    lastMaxValFound = maxValFound
                    // need to handle wind rose...
                    if (graphMap[wc.title].isRadar) {
                        const valid = checkWindValidity(minValFound, minWanted, maxValFound, maxWanted)
                        if (valid) {
                            const passes = passesTimeConditionsCheck(timeConditionsList, dateKey)
                            if (passes) {
                                yHits.push(1);
                            } else {
                                yHits.push(0);
                            }
                            const minFoundAsNum = getWindValFromCompassPoint(minValFound)
                            const maxFoundAsNum = getWindValFromCompassPoint(maxValFound)
                            context.push(getContext(dateKey, wc.title, notBelow, notAbove, minFoundAsNum, maxFoundAsNum, unitType, true, tempPref, distancePref, speedPref));                            
                        } else {
                            yHits.push(0);
                            aggregate[dateKey] = 0;
                            context.push("Not a hit");
                        }
                    } else {
                        if ((minValFound >= minWanted) && (maxValFound <= maxWanted)) {
                            // we have a hit / good date
                            const passes = passesTimeConditionsCheck(timeConditionsList, dateKey)
                            if (passes) {
                                yHits.push(1);
                            } else {
                                yHits.push(0);
                            }
                            context.push(getContext(dateKey, wc.title, notBelow, notAbove, minValFound, maxValFound, unitType, true, tempPref, distancePref, speedPref));
                        } else {
                            yHits.push(0);
                            aggregate[dateKey] = 0;
                            context.push("Not a hit");
                        }
                    }
                })
                wcHits.push({"condition": wc.title, "xDates": xDates, "yHits": yHits, "context": context})
            } else {
                // single point
                const xDates = []
                const yHits = []
                const context=[];
                const maxWanted = wc.control.valueWanted;
                records.forEach((record) => {
                    const dateKey = Object.keys(record)[0]
                    xDates.push(new Date(dateKey))
                    let maxValFound = 0;
                    if (usePeriods) {
                        maxValFound = record[dateKey][location][p][notAbove];
                    } else {
                        maxValFound = record[dateKey][location][notAbove];
                    }
                    if (maxValFound <= maxWanted) {
                        // we have a hit / good date
                        const passes = passesTimeConditionsCheck(timeConditionsList, dateKey)
                        if (passes) {
                            yHits.push(1);
                        } else {
                            yHits.push(0);
                        }
                        context.push(getContext(dateKey, wc.title, null, notAbove, null, maxValFound, unitType, false, tempPref, distancePref, speedPref));
                    } else {
                        yHits.push(0);
                        aggregate[dateKey] = 0;
                        context.push("Not a hit");
                    }
                })
                wcHits.push({"condition": wc.title, "xDates": xDates, "yHits": yHits, "context": context})
            }
        })
    }
    
    wcHits.reverse()

    let traces = [];

    // aggregate
    const sortedAggregate = Object.keys(aggregate).sort().reduce(
        (obj, key) => {
            obj[key] = aggregate[key];
            return obj;
        },
        {}
    );

    let xAgg = []
    const yAgg = []
    const goodDaysContext = []

    for (const [k, v] of Object.entries(sortedAggregate)) {
        xAgg.push(new Date(k))
        if (v===1) {
            const passes = passesConsecutiveDaysCheck(timeConditionsList, k, sortedAggregate) * passesTimeConditionsCheck(timeConditionsList, k)
            if (passes) {
                yAgg.push(1)        
            } else {
                yAgg.push(0)
            }
        } else {
            yAgg.push(0)
        }
        goodDaysContext.push("<b>" + k +"</b> would have<br>matched your alert.")
    }


    // reorder all traces such that they are all in the same (fake) year.
    const minYear = xAgg[0].getFullYear();
    xAgg = xAgg.map((x) => {
        return new Date(x.setFullYear(minYear))
    })

    wcHits.forEach((plt) => {
        plt.xDates = plt.xDates.map((x) => {
            return new Date(x.setFullYear(minYear))
        })
    })
    

    const hitCount = yAgg.reduce((a, b) => a + b, 0);
    const totals = yAgg.length;
    useEffect(() => {
        setPercentage(hitCount/totals)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hitCount, totals])
    
    const t = {
        "type": "bar",
        "x":xAgg,
        "y": yAgg,
        "width": 1000*24*3600,
        "text": goodDaysContext,
        "hoverinfo": "text",
        "textposition": "none",
        "marker": {"color": rawNumberToColour(hitCount, 1, totals, 0)},
    }
    traces.push(t)

    wcHits.forEach((plt, index) => {

        const hitCount = plt.yHits.reduce((a, b) => a + b, 0)
        const totals = plt.yHits.length;
        const t = {
            "type": "bar",
            "name": plt.condition,
            "x": plt.xDates,
            "y": plt.yHits,
            "width": 1000*24*3600,
            "text": plt.context,
            "hoverinfo": "text",
            "textposition": "none",
            "marker": {"color": rawNumberToColour(hitCount, 0.25, totals, 0)},
            "yaxis": "y" + (index + 2),
        }
        traces.push(t)
    })

    // GRAPHING BELOW
    const darkMode = useDarkMode();

    const [fontColor, setFontColor] = useState('#000')
    const [gridColor, setGridColor] = useState('rgba(255, 255, 255, 0.2)')

    useEffect(() => {
        setFontColor(darkMode.value ? '#FFF' : '#000');
        setGridColor(darkMode.value ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)');
    }, [darkMode])

    function getDomainFromIndex(pltIndex, total) {
        const spacing = 0.02;
        const totalSpacing = (total-1) * spacing;
        let height = (1-totalSpacing) / (total + 1);
        if (pltIndex===1) {
            height = height * 2;
        }
        let start = (pltIndex-1) * (height + spacing)
        if (pltIndex > 1) {
            start += height;
        }
        return [start, start+ height]

        /* const spacing = 0.02
        const totalspacing = (total) * spacing;
        let height = (1-totalspacing)/total;
        const start = (pltIndex-1) * (height + spacing)
        return [start, start+height] */
    }


    const totalPlots = traces.length;

    
    const layout = {
        paper_bgcolor: 'rgba(0, 0, 0, 0)',
        plot_bgcolor: 'rgba(0, 0, 0, 0)',
        showlegend: false,
        legend: {"orientation": "h"},
        height: 100,
        margin: margin,
        font: {color: fontColor},
        xaxis: {
            tickformatstops: [
                {
                    "dtickrange": [null, "M1"],
                    "value": "%d-%b"
                },
                {
                    "dtickrange": ["M1", null],
                    "value": "%B"
                },
            ], 
        },
        yaxis: {
            showticklabels: false, 
            gridcolor: gridColor,
            showgrid: false,
            zeroline: false,
            domain: getDomainFromIndex(1, totalPlots)
        },
    }

    traces.forEach((t, index) => {
        layout["yaxis" + (index+1)] = {
            showticklabels: false, 
            gridcolor: gridColor,
            showgrid: false,
            zeroline: false,
            domain: getDomainFromIndex(index+1, totalPlots)
        }
    })

    const config = {
        displayModeBar: false, // this is the line that hides the bar.
      };
        
  
    return (
        <Plot
          data={traces}
          layout={layout}
          config={config}
          useResizeHandler={true}
          style={{width: "100%", height: "100%"}}
        />
    )
}

export default PlotHits;
