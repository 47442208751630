import React, { useState } from "react";
import Alert from "@material-ui/lab/Alert";
import Meta from "./../components/Meta";
import PageLoader from "./../components/PageLoader";
import { useRouter } from "./../util/router";
import VerifyAlert from "../components/VerifyAlert";

function VerifyPage(props) {
  const router = useRouter();
  const [formAlert, setFormAlert] = useState();

  return (
    <>
      <Meta title="SMS verification" />
      <PageLoader>
        {router.query.id && (
          <VerifyAlert id={router.query.id} formAlert = {formAlert} setFormAlert={setFormAlert}/>
        )}
        {!(router.query.id) && (
            <Alert severity="error" style={{ maxWidth: "500px" }}>
                "No verification ID provided - URL should look like wxtxt.co/verify?id=ABCDEFGHI",
          </Alert>
        )}
      </PageLoader>
    </>
  );
}

export default VerifyPage;
