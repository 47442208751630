import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid";
import ConditionSlider from "./ConditionSlider";





export default function ConditionBoxControl({darkColour, control, setWeatherConditionsList, index, tempPref, speedPref, distancePref, updateWeatherListObj, markers, localSliderValues, setLocalSliderValues}) {

    const useStyles = makeStyles((theme) => ({
        control: {
          backgroundColor: 
          theme.palette.type === "dark" ? darkColour : "#F7F7F7",
        }
    }));    

    const classes = useStyles()
    return (
        <>
      
            <Box 
                display="flex"
                padding={2}
                sx={{ 
                    width: "100%",
                    padding: "12px 0px 8px 14px", 
                    borderRadius: 4
                }} 
                className={classes.control}
                key={control.title + index}
            >   <Grid container alignItems="center">
                    <Grid item xs={12}>
                        {control.flavour === "slider" &&
                        <>
                            <ConditionSlider 
                                control={control} 
                                setWeatherConditionsList={setWeatherConditionsList} 
                                index={index} 
                                tempPref={tempPref} 
                                speedPref={speedPref} 
                                distancePref={distancePref} 
                                updateWeatherListObj={updateWeatherListObj} 
                                markers={markers} 
                                localSliderValues={localSliderValues} 
                                setLocalSliderValues={setLocalSliderValues}/>
                        </>}
                        {control.flavour === "picker" &&
                        <>
                        <Typography variant="body1">Picker</Typography>
                        </>}
                    </Grid>
                </Grid>
                
            </Box>
      </>
    );
  }
  