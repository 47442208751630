import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { rawNumberToColour } from "../util/graphing";



function DaysIndicator({percentage}) {

    const displayPercentage = Math.round(percentage*365)

    let colour = rawNumberToColour(displayPercentage, 0.5, 365, 0);
    let borderColour= rawNumberToColour(displayPercentage, 0.9, 365, 0);

    
  return (
    <Box 
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    sx={{ 
        width: "100%",
        height: "100%",
        padding: "10px 10px 10px 10px", 
        borderRadius: 4,
        bgcolor: colour,
        borderColor: borderColour,
        border: '2px solid'
    }} 
    >
        <Typography variant="h5" paragraph={true}>
            {(displayPercentage===1) && 
                <>Based on last year you should get around <strong>{displayPercentage}</strong> alert this year with these settings</>
            }
            {(displayPercentage!==1) && 
                <>Based on last year you should get around <strong>{displayPercentage}</strong> alerts this year with these settings</>
            }
        </Typography>
        <Typography variant="subtitle1">
            Play with the sliders above to change the number of alerts. Aim for around 10.
            
        </Typography>
    </Box>
    
  );
}

export default DaysIndicator;
