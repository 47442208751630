export const weatherConditions = [
    {
        "title": "Average Temperature",
        "description": "The chart shows the average temperatures each day for the last year. Play with the sliders to reduce the range of temperatures that work for you.",
        "description2": "As you adjust the sliders you will see the number of days that match your conditions changes.",
        "periodDisabled": [false, false, false],
        "periodSelected": ["Day"],
        "control": {
            "flavour": "slider",
            "unitType": "temp",
            "minValue": -20,
            "maxValue": 40,
            "increment": 1,
            "minValueWanted": 20,
            "maxValueWanted": 25,
            "markers": {
                "type": "percentiles", 
                "values": [0, 25, 50, 75, 100]
            },
        },
        "minMaxCalc": {
            "location": "Temperature",
            "hasPeriod": true,
            "fieldOverride": false,
            "minBound": false,
            "maxBound": false,
            "excess": {"type": "fixed", "value": 5},
        }
    },
    {
        "title": "Hourly Temperature",
        "description": "These charts show the minimum and maximum temperature that occured during each day.",
        "description2": "The lower value on the slider will enforce a minimum temperature requirement for every hour, the maximum will set an upper limit on the temperature in any hour.",
        "periodDisabled": [false, false, false],
        "periodSelected": ["Day"],
        "control": {
            "flavour": "slider",
            "unitType": "temp",
            "minValue": -20,
            "maxValue": 40,
            "increment": 1,
            "minValueWanted": 15,
            "maxValueWanted": 30,
            "markers": {
                "type": "percentiles", 
                "values": [0, 25, 50, 75, 100]
            },
        },
        "minMaxCalc": {
            "location": "Temperature",
            "hasPeriod": true,
            "fieldOverride": false,
            "minBound": false,
            "maxBound": false,
            "excess": {"type": "fixed", "value": 5},
        },
    },
    {
        "title": "Average 'Feels Like' Temp",
        "description": "The chart shows the average 'feels-like' temperatures each day for the last year. Play with the sliders to reduce the range of temperatures that work for you.",
        "description2": "As you adjust the sliders you will see the number of days that match your conditions changes.",
        "periodDisabled": [false, false, false],
        "periodSelected": ["Day"],
        "control": {
            "flavour": "slider",
            "unitType": "temp",
            "minValue": -20,
            "maxValue": 40,
            "increment": 1,
            "minValueWanted": 20,
            "maxValueWanted": 25,
            "markers": {
                "type": "percentiles", 
                "values": [0, 25, 50, 75, 100]
            },
        },
        "minMaxCalc": {
            "location": "FeelsLike",
            "hasPeriod": true,
            "fieldOverride": false,
            "minBound": false,
            "maxBound": false,
            "excess": {"type": "fixed", "value": 5},
        },
    },
    {
        "title": "Hourly 'Feels Like' Temp",
        "description": "These charts show the minimum and maximum 'feels-like' temperature that occured during each day.",
        "description2": "The lower value on the slider will enforce a minimum 'feels-like' temperature requirement for every hour, the maximum will set an upper limit on the 'feels-like' temperature in any hour.",
        "periodDisabled": [false, false, false],
        "periodSelected": ["Day"],
        "control": {
            "flavour": "slider",
            "unitType": "temp",
            "minValue": -20,
            "maxValue": 40,
            "increment": 1,
            "minValueWanted": 15,
            "maxValueWanted": 30,
            "markers": {
                "type": "percentiles", 
                "values": [0, 25, 50, 75, 100]
            },
        },
        "minMaxCalc": {
            "location": "FeelsLike",
            "hasPeriod": true,
            "fieldOverride": false,
            "minBound": false,
            "maxBound": false,
            "excess": {"type": "fixed", "value": 5},
        },
    },
    {
        "title": "Average Humidity",
        "description": "The chart shows the average humidity each day for the last year. Play with the sliders to reduce the range of humidities that work for you.",
        "description2": "As you adjust the sliders you will see the number of days that match your conditions changes.",
        "periodDisabled": [false, false, false],
        "periodSelected": ["Day"],
        "control": {
            "flavour": "slider",
            "minValue": 0,
            "unitType": "percent",
            "maxValue": 100,
            "increment": 1,
            "minValueWanted": 0,
            "maxValueWanted":75,
            "markers": {
                "type": "percentiles", 
                "values": [0, 25, 50, 75, 100]
            },
        },
        "minMaxCalc": {
            "location": "Humidity",
            "hasPeriod": true,
            "fieldOverride": false,
            "minBound": 0,
            "maxBound": 100,
            "excess": {"type": "fixed", "value": 5},
        },
    },
    {
        "title": "Humidity",
        "description": "These charts show the minimum and maximum humidity that occured during each day.",
        "description2": "The lower value on the slider will enforce a minimum humidty requirement for every hour, the maximum will set an upper limit on the humidity in any hour.",
        "periodDisabled": [false, false, false],
        "periodSelected": ["Day"],
        "control": {
            "flavour": "slider",
            "minValue": 0,
            "unitType": "percent",
            "maxValue": 100,
            "increment": 1,
            "minValueWanted": 0,
            "maxValueWanted":75,
            "markers": {
                "type": "percentiles", 
                "values": [0, 25, 50, 75, 100]
            },
        },
        "minMaxCalc": {
            "location": "Humidity",
            "hasPeriod": true,
            "fieldOverride": false,
            "minBound": 0,
            "maxBound": 100,
            "excess": {"type": "fixed", "value": 5},
        },
    },
    {
        "title": "Average Cloud Cover",
        "description": "The chart shows the average percentage of cloud cover each day for the last year. 0% means completely clear skies, 100% means completely cloudy.",
        "description2": "As you adjust the sliders you will see the number of days that match your conditions changes.",
        "periodDisabled": [false, false, false],
        "periodSelected": ["Day"],
        "control": {
            "flavour": "slider",
            "minValue": 0,
            "unitType": "percent",
            "maxValue": 100,
            "increment": 1,
            "minValueWanted": 0,
            "maxValueWanted":40,
            "markers": {
                "type": "percentiles", 
                "values": [0, 25, 50, 75, 100]
            },
        },
        "minMaxCalc": {
            "location": "Cloud",
            "hasPeriod": true,
            "fieldOverride": false,
            "minBound": 0,
            "maxBound": 100,
            "excess": {"type": "fixed", "value": 5},
        },
    },
    {
        "title": "Hourly Cloud Cover",
        "description": "These charts show the minimum and maximum level of cloud cover that occured during each day.",
        "description2": "The lower value on the slider will enforce a minimum cloud cover percentage requirement for every hour, the maximum will set an upper limit on the percentage of cloud in any hour.",
        "periodDisabled": [false, false, false],
        "periodSelected": ["Day"],
        "control": {
            "flavour": "slider",
            "minValue": 0,
            "unitType": "percent",
            "maxValue": 100,
            "increment": 1,
            "minValueWanted": 0,
            "maxValueWanted":50,
            "markers": {
                "type": "percentiles", 
                "values": [0, 25, 50, 75, 100]
            },
        },
        "minMaxCalc": {
            "location": "Cloud",
            "hasPeriod": true,
            "fieldOverride": false,
            "minBound": 0,
            "maxBound": 100,
            "excess": {"type": "fixed", "value": 5},
        },
    },
    {
        "title": "Visibility",
        "description": "These charts show the minimum and maximum visibility that occured during each day, helping you avoid foggy or hazy days.",
        "description2": "The lower value on the slider will enforce a minimum visibility requirement for every hour, the maximum will set an upper limit on the visibility in any hour.",
        "periodDisabled": [false, false, false],
        "periodSelected": ["Day"],
        "control": {
            "flavour": "slider",
            "minValue": 0,
            "unitType": "distancekm",
            "maxValue": 30,
            "increment": 1,
            "minValueWanted": 10,
            "maxValueWanted": 30,
            "markers": {
                "type": "percentiles", 
                "values": [0, 25, 50, 75, 100]
            },
        },
        "minMaxCalc": {
            "location": "Visibility",
            "hasPeriod": true,
            "fieldOverride": false,
            "minBound": 0,
            "maxBound": false,
            "excess": {"type": "fixed", "value": 5},
        },
    },
    {
        "title": "Expected Rain Hours",
        "description": "The chart shows the amount of rainy hours each day for the last year. We estimate the number of rainy hours in the forecast based on the chance of rain in each hour.",
        "description2": "As you adjust the sliders you will see the number of days that match your conditions changes.",
        "periodDisabled": [false, false, false],
        "periodSelected": ["24 Hr"],
        "control": {
            "flavour": "slider",
            "minValue": 0,
            "unitType": "time",
            "maxValue": 24,
            "increment": 0.5,
            "minValueWanted": 0,
            "maxValueWanted": 4,
            "markers": {
                "type": "percentiles", 
                "values": [0, 25, 50, 75, 100]
            },
        },
        "minMaxCalc": {
            "location": "Rain",
            "hasPeriod": true,
            "fieldOverride": "rainHours",
            "minBound": 0,
            "maxBound": 24,
            "excess": {"type": "fixed", "value": 1},
        },
    },
    {
        "title": "Hourly Rain",
        "description": "These charts show the minimum and maximum amount of rain per hour that occured during each day.",
        "description2": "The lower value on the slider will enforce a minimum amount of rain for every hour (should you like it wet), the maximum will set an upper limit on the amount of rain in any hour.",
        "periodDisabled": [false, false, false],
        "periodSelected": ["Day"],
        "control": {
            "flavour": "slider",
            "minValue": 0,
            "unitType": "distance",
            "maxValue": 50,
            "increment": 0.1,
            "minValueWanted": 0,
            "maxValueWanted": 10,
            "markers": {
                "type": "percentiles", 
                "values": [0, 25, 50, 75, 100]
            },
        },
        "minMaxCalc": {
            "location": "Rain",
            "hasPeriod": true,
            "fieldOverride": false,
            "minBound": 0,
            "maxBound": false,
            "excess": {"type": "percentage", "value": 5},
        },
    },
    {
        "title": "Total Rain",
        "description": "The chart shows the total rain each day for the last year.",
        "description2": "As you adjust the sliders you will see the number of days that match your conditions changes.",
        "periodDisabled": [false, false, false],
        "periodSelected": ["Day"],
        "control": {
            "flavour": "slider",
            "minValue": 0,
            "unitType": "distance",
            "maxValue": 102,
            "increment": 0.1,
            "minValueWanted": 0,
            "maxValueWanted": 25,
            "markers": {
                "type": "percentiles", 
                "values": [0, 25, 50, 75, 100]
            },
        },
        "minMaxCalc": {
            "location": "Rain",
            "hasPeriod": true,
            "fieldOverride": "total",
            "minBound": 0,
            "maxBound": false,
            "excess": {"type": "percentage", "value": 5},
        },
    },
    {
        "title": "Total Snow",
        "description": "The chart shows the total snow each day for the last year.",
        "description2": "As you adjust the sliders you will see the number of days that match your conditions changes.",
        "periodDisabled": [false, false, false],
        "periodSelected": ["Day"],
        "control": {
            "flavour": "slider",
            "minValue": 0,
            "unitType": "distancecm", // to do change this to distanceCM
            "maxValue": 102,
            "increment": 0.1,
            "minValueWanted": 0,
            "maxValueWanted": 25,
            "markers": {
                "type": "percentiles", 
                "values": [0, 25, 50, 75, 100]
            },
        },
        "minMaxCalc": {
            "location": "Snow",
            "hasPeriod": true,
            "fieldOverride": "total",
            "minBound": 0,
            "maxBound": false,
            "excess": {"type": "percentage", "value": 5},
        },
    },
    {
        "title": "Snow Depth",
        "description": "These charts show the minimum and maximum amount of snow layed down per hour that occured during each day.",
        "description2": "The lower value on the slider will enforce a minimum amount of snow for every hour, the maximum will set an upper limit on the depth of the snow in any hour.",
        "periodDisabled": [false, false, false],
        "periodSelected": ["Day"],
        "control": {
            "flavour": "slider",
            "minValue": 0,
            "unitType": "distancecm",
            "maxValue": 254,
            "increment": 1,
            "minValueWanted": 0,
            "maxValueWanted": 25,
            "markers": {
                "type": "percentiles", 
                "values": [0, 25, 50, 75, 100]
            },
        },
        "minMaxCalc": {
            "location": "SnowDepth",
            "hasPeriod": true,
            "fieldOverride": false,
            "minBound": 0,
            "maxBound": false,
            "excess": {"type": "percentage", "value": 5},
        },
    },
    {
        "title": "Average Wind Speed",
        "description": "The chart shows the average wind speed each day for the last year.",
        "description2": "As you adjust the sliders you will see the number of days that match your conditions changes.",
        "periodDisabled": [false, false, false],
        "periodSelected": ["Day"],
        "control": {
            "flavour": "slider",
            "minValue": 0,
            "unitType": "speed",
            "maxValue": 50,
            "increment": 1,
            "minValueWanted": 0,
            "maxValueWanted": 10,
            "markers": {
                "type": "percentiles", 
                "values": [0, 25, 50, 75, 100]
            },
        },
        "minMaxCalc": {
            "location": "WindSpeed",
            "hasPeriod": true,
            "fieldOverride": false,
            "minBound": 0,
            "maxBound": false,
            "excess": {"type": "percentage", "value": 5},
        },
    },
    {
        "title": "Hourly Wind Speed",
        "description": "These charts show the minimum and maximum hourly wind speed that occured during each day.",
        "description2": "The lower value on the slider will enforce a minimum wind speed for every hour (ideal for sailing), the maximum will set an upper limit on the wind speed in any hour.",
        "periodDisabled": [false, false, false],
        "periodSelected": ["Day"],
        "control": {
            "flavour": "slider",
            "minValue": 0,
            "unitType": "speed",
            "maxValue": 50,
            "increment": 1,
            "minValueWanted": 0,
            "maxValueWanted": 18,
            "markers": {
                "type": "percentiles", 
                "values": [0, 25, 50, 75, 100]
            },
        },
        "minMaxCalc": {
            "location": "WindSpeed",
            "hasPeriod": true,
            "fieldOverride": false,
            "minBound": 0,
            "maxBound": false,
            "excess": {"type": "percentage", "value": 5},
        },
    },
    {
        "title": "Wind Gust",
        "description": "Set the max wind gust that the forecast must not exceed in any hour.",
        "description2": "You can choose to apply this rule to just the daytime, just the nightime, or the whole 24 hour period.",
        "periodDisabled": [false, false, false],
        "periodSelected": ["Day"],
        "control": {
            "flavour": "slider",
            "minValue": 0,
            "unitType": "speed",
            "maxValue": 100,
            "increment": 1,
            "valueWanted": 50,
            "markers": {
                "type": "percentiles", 
                "values": [0, 25, 50, 75, 100]
            },
        },
        "minMaxCalc": {
            "location": "WindGust",
            "hasPeriod": true,
            "fieldOverride": false,
            "minBound": 0,
            "maxBound": false,
            "excess": {"type": "percentage", "value": 5},
        },
    },
    {
        "title": "Average Wind Direction",
        "description": "Set the compass rose range that the wind may blow from on average over the period",
        "description2": "You can choose to apply this rule to just the daytime, just the nightime, or the whole 24 hour period.",
        "periodDisabled": [false, false, false],
        "periodSelected": ["Day"],
        "control": {
            "flavour": "slider",
            "minValue": 0,
            "unitType": "compass",
            "maxValue": 8,
            "increment": 0.25,
            "minValueWanted": 2,
            "maxValueWanted": 6,
            "markers": {
                "type": "percentiles", 
                "values": [0, 25, 50, 75, 100]
            },
        },
        "minMaxCalc": {
            "location": "none",
            "hasPeriod": false,
            "fieldOverride": false,
            "minBound": false,
            "maxBound": false,
            "excess": {"type": "fixed", "value": 0},
        },
    },
    {
        "title": "Hourly Wind Direction",
        "description": "Set the compass rose range that the wind may blow from in all hours.",
        "description2": "You can choose to apply this rule to just the daytime, just the nightime, or the whole 24 hour period.",
        "periodDisabled": [false, false, false],
        "periodSelected": ["Day"],
        "control": {
            "flavour": "slider",
            "minValue": 0,
            "unitType": "compass",
            "maxValue": 8,
            "increment": 0.25,
            "minValueWanted": 2,
            "maxValueWanted": 6,
            "markers": {
                "type": "percentiles", 
                "values": [0, 25, 50, 75, 100]
            },
        },
        "minMaxCalc": {
            "location": "none",
            "hasPeriod": false,
            "fieldOverride": false,
            "minBound": false,
            "maxBound": false,
            "excess": {"type": "fixed", "value": 0},
        },
    },
    {
        "title": "Sunlight Hours",
        "description": "Set the min and max sunlight hours for the day. This is broadly equivalent to turning some months on or off.",
        "description2": "This rule can only be applied to the whole 24 hour period",
        "periodDisabled": [true, true, false],
        "periodSelected": ["24 Hr"],
        "control": {
            "flavour": "slider",
            "minValue": 0,
            "unitType": "time",
            "maxValue": 16,
            "increment": 1,
            "minValueWanted": 8,
            "maxValueWanted": 16,
            "markers": {
                "type": "percentiles", 
                "values": [0, 25, 50, 75, 100]
            },
        },
        "minMaxCalc": {
            "location": "SunlightHours",
            "hasPeriod": false,
            "fieldOverride": "total",
            "minBound": 0,
            "maxBound": 24,
            "excess": {"type": "fixed", "value": 1},
        },
    },
    {
        "title": "Max UV",
        "description": "Set the maximum acceptable UV value experienced in any forecast hour, particularly useful for those young children or sensitive skin.",
        "description2": "This rule can only be applied to the daytime",
        "periodDisabled": [false, true, true],
        "periodSelected": ["Day"],
        "control": {
            "flavour": "slider",
            "minValue": 0,
            "unitType": "none",
            "maxValue": 10,
            "increment": 0.5,
            "valueWanted": 4,
            "markers": {
                "type": "percentiles", 
                "values": [0, 25, 50, 75, 100]
            },
        },
        "minMaxCalc": {
            "location": "UV",
            "hasPeriod": true,
            "fieldOverride": false,
            "minBound": 0,
            "maxBound": false,
            "excess": {"type": "fixed", "value": 2},
        },
    },
    {
        "title": "Moon Illumination",
        "description": "Set min and max acceptable illumination of the moon during the forecast. 100% is a full moon. Set Moon Hours if you require a certain amount of moon time during the night.",
        "description2": "This rule can only be applied to the nighttime",
        "periodDisabled": [true, false, true],
        "periodSelected": ["Night"],
        "control": {
            "flavour": "slider",
            "minValue": 0,
            "unitType": "percent",
            "maxValue": 100,
            "increment": 1,
            "minValueWanted": 0,
            "maxValueWanted":100,
            "markers": {
                "type": "percentiles", 
                "values": [0, 25, 50, 75, 100]
            },
        },
        "minMaxCalc": {
            "location": "MoonIllumination",
            "hasPeriod": false,
            "fieldOverride": "total",
            "minBound": 0,
            "maxBound": 100,
            "excess": {"type": "fixed", "value": 1},
        },
    },
    {
        "title": "Moonlight Hours",
        "description": "Set min and max acceptable hours of moonlight hours during the night time - handy if you want to enfore a visible and bright moon for a night walk. Set Moon Illumination to specify how much of the moon must be present.",
        "description2": "This rule can only be applied to the nighttime",
        "periodDisabled": [true, false, true],
        "periodSelected": ["Night"],
        "control": {
            "flavour": "slider",
            "minValue": 0,
            "unitType": "time",
            "maxValue": 16,
            "increment": 1,
            "minValueWanted": 4,
            "maxValueWanted":12,
            "markers": {
                "type": "percentiles", 
                "values": [0, 25, 50, 75, 100]
            },
        },
        "minMaxCalc": {
            "location": "MoonlightHours",
            "hasPeriod": false,
            "fieldOverride": "total",
            "minBound": 0,
            "maxBound": 24,
            "excess": {"type": "fixed", "value": 1},
        },
    },
   
];