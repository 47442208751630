import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid";
import SinglePicker from "./SinglePicker";
import { Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Launch";
import ConditionBoxControl from "./ConditionBoxControl";


function Disabled({disabled, children}) {
    if (disabled) {
        return (
            <div style={{opacity:0.5, pointerEvents: "none"}} disabled>
                {children}
            </div>
        );
    }

    return <React.Fragment>{children}</React.Fragment>
}

const useStyles = makeStyles((theme) => ({
    summary: {
      backgroundColor: 
      theme.palette.type === "dark" ? theme.palette.action.selected : "#F7F7F7",
    }
}));


export default function ConditionBox({title, subtitle, periodsDisabled, periodSelected, control, setWeatherConditionsList, index, tempPref, speedPref, distancePref, setUpdatingIndex, setNewCondition, enabled, activity}) {

    const times = ["Day", "Night", "24 Hr"]

    const [timePref, setTimePref] = useState(periodSelected);

    function deleteCondition(index) {
        setWeatherConditionsList((draft) => {
            draft.splice(index, 1)
        });
    }

    function editCondition(index) {
        setUpdatingIndex(index)
        setNewCondition(true)
    }

    useEffect(() => {
        // change the weather conditions object on changes to the child.
        setWeatherConditionsList((draft) => {
            if (Array.isArray(timePref)) {
                draft[index].periodSelected = timePref;
            } else {
                draft[index].periodSelected = [timePref];
            }
        });
        
    }, [timePref, setWeatherConditionsList, index]);

    const classes = useStyles()
    return (
        <>
            <Disabled disabled={!enabled}>
            <Box 
                display="flex"
                padding={2}
                sx={{ 
                    width: "100%",
                    padding: "12px 0px 8px 14px", 
                    borderRadius: 4
                }} 
                className={classes.summary}
            >   <Grid container alignItems="center">
                    <Grid item xs={9}>
                        <Grid container direction="row" alignItems="center" spacing={1}>
                            <Grid item>
                                <Typography variant="body1"><strong>{title}</strong></Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container justifyContent="center">
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                >
                                <Tooltip title="View in Editor">
                                    <IconButton
                                        edge="end"
                                        aria-label="Edit alert"
                                        onClick={() => editCondition(index)}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    </Tooltip>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={10}>
                        <ConditionBoxControl darkColour={"#606060"} control={control} setWeatherConditionsList={setWeatherConditionsList} index={index} tempPref={tempPref} speedPref={speedPref} distancePref={distancePref} updateWeatherListObj={true}/>
                    </Grid>
                    <Grid item xs={9}>
                        <Grid container justifyContent="flex-start">
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                >
                                <SinglePicker formats={timePref} setFormats={setTimePref} options={times} disabled={periodsDisabled} key={activity+title+timePref}/>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container justifyContent="center">
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                >
                                <Tooltip title="Delete">
                                    <IconButton
                                        edge="end"
                                        aria-label="delete alert"
                                        onClick={() => deleteCondition(index)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                    </Tooltip>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                
            </Box>
            </Disabled>
      </>
    );
  }
  