import React, { useEffect } from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import useFetch from "react-fetch-hook";

const containerStyle = {
  width: '100%',
  height: '400px'
};

function GoogleMapPicker({setSelectedLocation, marker, setMarker, setMap, center, setCenter }) {

  const { data, isLoading } = useFetch(
        "https://api.weatherapi.com/v1/ip.json?key=3b4d6ff32081410e984130721231402&q=auto:ip"
  )

  useEffect(() => {
    if (data) {
        if (marker.length > 0) {
          setCenter({lat: marker[0].lat, lng: marker[0].lng});       // if we're loading in, then set center to location
        } else {
          setCenter({lat: data.lat, lng: data.lon});       
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

   const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBP7_OsrVhdOLyAw9Ao5ayQnCo39VaXhDs'
  })


  const onLoad = React.useCallback(function callback(map) {
    map.setZoom(10);
    setMap(map)
  }, [setMap])

  const onUnmount = React.useCallback(function callback() {
    setMap(null)
  }, [setMap])

  function setLatLng(e) {
    const latitude = e.latLng.lat();
    const longitude = e.latLng.lng();
    setSelectedLocation({ latitude: latitude, longitude: longitude });
  }

  const handleMapClick=(e) => {
    setLatLng(e);
    setMarker([
        {
            lat: e.latLng.lat(),
            lng: e.latLng.lng()
        }
    ]);
  };

  return (isLoaded & (!isLoading)) ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        clickableIcons={false}
        zoom={8}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onClick={handleMapClick}
      >
        {marker.map((m) => {
            return (<Marker 
                key = "marker"
                position = { {
                    lat: m.lat,
                    lng: m.lng
                }}
            />)
        })}
      </GoogleMap>
  ) : <></>
}

export default React.memo(GoogleMapPicker)