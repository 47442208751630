import React, { useState } from "react";
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid";
import PlotHits from "../PlotHits";
import DaysIndicator from "../DaysIndicator";


export default function HitVisualiser({historical, weatherConditionsList, timeConditionsList, tempPref, distancePref, speedPref}) {


    const [percentage, setPercentage] = useState(1);

    return (
        <>
            <Grid container={true}>
            <Grid item={true} xs={12}>
                    <Box 
                            display="flex"
                            justifyContent="flex-start"
                            alignItems="center"
                            padding={0}
                            paddingBottom={1}
                            paddingTop={2}
                            >
                        <DaysIndicator percentage={percentage}/>
                        </Box>
                    </Grid>
                    <Grid item={true} xs={12}>
                    <Box 
                            display="flex"
                            justifyContent="flex-start"
                            alignItems="center"
                            padding={0}
                            paddingTop={1}
                            >
                        <PlotHits historical={historical} weatherConditionsList={weatherConditionsList} timeConditionsList={timeConditionsList} setPercentage={setPercentage} tempPref={tempPref} distancePref={distancePref} speedPref={speedPref}/>
                        </Box>
                    </Grid>
                    
                </Grid>
      </>
    );
  }
  