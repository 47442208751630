import React, { useEffect } from "react";
import Button from '@material-ui/core/Button'
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid";
import ActivityPicker from "./ActivityPicker";
import { getAlertSettings } from "../../util/activityAlertSettings"; 
import ConditionCards from "./ConditionCards/ConditionCards";
import { weatherConditions } from "../../util/weatherConditions";
import HitVisualiser from "./HitVisualiser";
import Divider from "@material-ui/core/Divider";
import _ from "lodash"
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
    useTheme
  } from "@material-ui/core/styles";

function generateReport(historical) {
    return getAlertSettings(historical)
}

function fillWeatherConditionsFromObj(setWeatherConditionsList, weatherConditions, suggested) {
    setWeatherConditionsList([])
    if (suggested !== false) {
        suggested.conditions.forEach((condition) => {
        const wc = _.cloneDeep(weatherConditions[condition.weatherConditionsIndex])
        let multislider = false;
        if ("maxValueWanted" in wc.control) {
            multislider = true;
        }
        if (multislider) {
            // we expect to update the min and max
            // but if min or max is false, set it to the bottom / top of the slider range
            if (condition.min === false) {
            wc.control.minValueWanted = condition.minInData
            wc.control.maxValueWanted = condition.maxFound;
            } else if (condition.max === false) {
            wc.control.maxValueWanted = condition.maxInData;
            wc.control.minValueWanted = condition.minFound;
            } else {
            // range
            wc.control.minValueWanted = condition.minFound;
            wc.control.maxValueWanted = condition.maxFound;
            }
            wc.control.minValue = condition.minInData
            wc.control.maxValue = condition.maxInData
            // set period
            if (condition.period !== false) {
            if (condition.period === "day") {
                wc.periodSelected = ['Day']
            } else if (condition.period === "night") {
                wc.periodSelected = ['Night']
            } else {
                wc.periodSelected = ['24 Hr']
            }
            }
        } else {
            console.error("Not supported yet. We didn't have single value optimisations until now...")
        }
        setWeatherConditionsList((draft) => {
            draft.push(wc)
        })
        })
    }
  
  }


export default function AlertBuilder({
        selectedLocation, 
        historical, 
        weatherConditionsList, 
        timeConditionsList,
        updatingAlertId, 
        setWeatherConditionsList,
        setTimeConditionsList,
        tempPref,
        speedPref,
        distancePref,
        setUpdatingIndex,
        setNewCondition,
        setNewTimeCondition,
        hasHistorical,
        activity, 
        setActivity,
        setFixLocation,
        usedLoader,
        setUsedLoader
        }) {

    const [report, setReport] = React.useState(null);
    const [hasReport, setHasReport] = React.useState(false);

    useEffect(()=> {
        if (historical !== undefined) {
            setReport(generateReport(historical))
            setHasReport(true)
        }
    }, [historical])

    useEffect(() => {
        if (updatingAlertId !== null) {
            setActivity("Custom")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [suggested, setSuggested] = React.useState(null);

    const hasConditions = (weatherConditionsList.length > 0)

    const showActivityPicker = (updatingAlertId === null)

    useEffect(() => {
        if (hasReport) {
            if (updatingAlertId === null) { // only overwrite if not editing an alert.
                if (activity in report) {
                    setSuggested(report[activity])
                } else {
                    // user's selected None
                    setSuggested(false)
                }
            } 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activity, hasReport])

    useEffect(() => {
        if (suggested !== null) {
            if (!usedLoader) {
                fillWeatherConditionsFromObj(setWeatherConditionsList, weatherConditions, suggested)
            } else {
                setUsedLoader(false)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [suggested])

    function setNewLocation() {
        setFixLocation(false)
    }

    const theme = useTheme();
    const aboveSM = useMediaQuery(theme.breakpoints.up('sm'));

    const locationButton = (aboveSM) ? "Change Location" : "New Location"
    const weatherButton = (aboveSM) ? "Add a Weather Condition" : "Weather Rule";
    const timeButton = (aboveSM) ? "Add a Time Condition": "Time Rule";

    return (
        <>
      <div>
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding = {0}>
            <Grid container>
                {showActivityPicker &&
                <>
                <Grid item xs={12}>
                    <ActivityPicker activity={activity} setActivity={setActivity}/>
                </Grid>
            
                </>
                }    
                <Grid item xs={12}>
                    <Grid container={true} spacing={2} alignItems="stretch">
                        <ConditionCards 
                            selectedLocation={selectedLocation}
                            weatherConditionsList={weatherConditionsList}
                            timeConditionsList={timeConditionsList}
                            updatingAlertId={updatingAlertId}
                            setWeatherConditionsList={setWeatherConditionsList}
                            setTimeConditionsList={setTimeConditionsList}
                            tempPref={tempPref}
                            speedPref={speedPref}
                            distancePref={distancePref}
                            setUpdatingIndex={setUpdatingIndex}
                            setNewCondition={setNewCondition}
                            hasHistorical={hasHistorical}
                            activity={activity}
                        />
                    </Grid>
                </Grid>
                <Grid container={true}>
                    <Grid item={true} xs={12}>
                        <Box 
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            padding={2}
                            >
                            <Box 
                            padding={1}
                            >
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="secondary"
                                    disabled={!hasHistorical}
                                    onClick={() => setNewLocation()}
                                >
                                    {locationButton}
                                </Button>
                            </Box>
                            <Box 
                            padding={1}
                            >
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="secondary"
                                    disabled={!hasHistorical}
                                    onClick={() => setNewCondition(true)}
                                >
                                    {weatherButton}
                                </Button>
                            </Box>
                            <Box padding={1}>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="secondary"
                                    disabled={!hasHistorical}
                                    onClick={() => setNewTimeCondition(true)}
                                >
                                    {timeButton}
                                </Button>
                                </Box>
                        </Box>
                    
                    </Grid>
                </Grid>
                {hasConditions && 
            <>
            <Divider variant="middle" />
            <HitVisualiser
                historical={historical}
                weatherConditionsList={weatherConditionsList}
                timeConditionsList={timeConditionsList}
                tempPref={tempPref}
                distancePref={distancePref}
                speedPref={speedPref}
            />
            </>
            }
            </Grid>
        </Box>
      </div>
      </>
    );
  }
  