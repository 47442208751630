import React from "react";
import LongContent from "./LongContent";

function LegalTerms(props) {
  return (
    <LongContent>
      <h1>Terms of Service</h1>
      <p>
        These Terms of Service govern your use of {props.domain} and any related
        services provided by {props.companyName} ("{props.domain}", "{props.companyName}", "us", "we", or "our"). By accessing {props.domain} ("Service"),
        you agree to abide by these Terms of Service and to comply with all
        applicable laws and regulations.
      </p>
      <p>
        If you do not agree with these Terms of Service, you are prohibited from
        using or accessing {props.domain} and from using any other services we
        provide. We reserve the right to review and amend any of these Terms of
        Service at our sole discretion. Upon doing so, we will update this page.
        Any changes to these Terms of Service will take effect immediately from
        the date of publication.
      </p>
      <p>This policy was last updated on January 6th, 2023.</p>
      <h2>Eligibility</h2>
      <p>By agreeing to these Terms, you represent and warrant to us: (i) that you are at least eighteen (18) years of age; (ii) that you have not previously been suspended or removed from our Service and (iii) that your use of our Service is in compliance with any and all applicable laws and regulations.</p>
      <h2>Indemnity</h2>
      <p>You agree that you will be responsible for your use of the Service, and you agree to defend, indemnify, and us hold harmless and our officers, directors, employees, consultants, affiliates, subsidiaries and agents (collectively, the "Company Entities") from and against any and all claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees and costs, arising out of or in any way connected with (i) your access to, use of, or alleged use of the Service; (ii) your violation of these Terms or any representation, warranty, or agreements referenced herein, or any applicable law or regulation; (iii) your violation of any third-party right, including without limitation any intellectual property right, publicity, confidentiality, property or privacy right; or (iv) any disputes or issues between you and any third party. We reserve the right, at our own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you (and without limiting your indemnification obligations with respect to such matter), and in such case, you agree to cooperate with our defense of such claim.</p>
      <h2>Termination</h2>
      <p>We may at our sole discretion suspend or terminate your access to our Service at any time, with or without notice for any reason or no reason at all. We also reserve the right to modify or discontinue the Service at any time (including, without limitation, by limiting or discontinuing certain features of the Service) without notice to you. We will have no liability whatsoever on account of any change to the Service or any suspension or termination of your access to or use of the Service.</p>
      <h2>Accounts</h2>
      <p>When you create an account with us, you must provide us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.</p>
      <p>You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third-party service.</p>
      <p>You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>
      <h2>Subscriptions</h2>
      <p>Some parts of the Service are billed on a subscription basis ("Subscription(s)"). You will be billed in advance on a recurring and periodic basis ("Billing Cycle"). Billing cycles are charged on an annual basis.</p>
      <p>At the end of each Billing Cycle, your Subscription will automatically renew under the exact same conditions unless you cancel it or we cancel it. You may cancel your Subscription renewal either through your online account management page or by contacting the team.</p>
      <p>A valid payment method, including credit card, is required to process the payment for your Subscription. You shall provide our payment partners with accurate and complete billing information including full name, address, telephone number, and a valid payment method information. By submitting such payment information, you automatically authorise us to charge all Subscription fees incurred through your account to any such payment instruments.</p>
      <p>Should automatic billing fail to occur for any reason, we may issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice.</p>
      <h2>Fee Changes</h2>
      <p>We may, at our sole discretion and at any time, modify the Subscription fees for the Subscriptions. Any Subscription fee change will become effective at the end of the then-current Billing Cycle.</p>
      <p>We will provide you with a reasonable prior notice of any change in Subscription fees to give you an opportunity to terminate your Subscription before such change becomes effective.</p>
      <p>Your continued use of the Service after the Subscription fee change comes into effect constitutes your agreement to pay the modified Subscription fee amount.</p>
      <h2>Refunds</h2>
      <p>To avoid being charged for your Subscription, you must cancel the Subscription before your renewal date. Otherwise, you’ll be charged and will retain access to the Service through the end of the Billing Cycle.</p>
      <p>Except when required by law, paid Subscription fees are non-refundable. However, you may contact us and at our sole discretion we may issue a refund on a case-by-case basis.</p>
      <h2>Third-party Websites</h2>
      <p>The Service may contain links to third-party websites or services that are not owned or controlled by us.</p>
      <p>We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third party websites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such websites or services.</p>
      <h2>Third-party Providers</h2>
      <p>The Service relies on third-party services to operate. No responsibility is taken for the unavailability of those services, either on an ongoing or temporary basis.</p>
      <h2>Limitation of Liability</h2>
      <p><strong>IN NO EVENT WILL THE COMPANY ENTITIES BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES ARISING OUT OF OR RELATING TO YOUR ACCESS TO OR USE OF, OR YOUR INABILITY TO ACCESS OR USE, THE SERVICE OR ANY MATERIALS OR CONTENT ON THE SERVICE, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE, OR ANY OTHER LEGAL THEORY, WHETHER OR NOT THE COMPANY ENTITIES HAVE BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE.</strong></p>
      <p><strong>YOU USE THE WEATHER FORECAST DATA AT YOUR OWN RISK AND SHOULD SEEK CORROBORATING EVIDENCE BEFORE MAKING ANY PLANS BASED ON THE SERVICE ALERTS PROVIDED.</strong></p>
      <h2>Disclaimers</h2>
      <p><strong>THE SERVICE ARE MADE AVAILABLE TO YOU ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITH THE EXPRESS UNDERSTANDING THAT THE COMPANY ENTITIES HAVE NO OBLIGATION TO MONITOR, CONTROL, OR VET THE CONTENT OR DATA APPEARING ON THE SERVICE. AS SUCH, YOUR USE OF THE SERVICE IS AT YOUR OWN DISCRETION AND RISK. THE COMPANY ENTITIES MAKE NO CLAIMS OR PROMISES ABOUT THE QUALITY, ACCURACY, OR RELIABILITY OF THE SERVICE AND EXPRESSLY DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.</strong></p>
      <h2>Governing Law</h2>
      <p>These Terms shall be governed and construed in accordance with the laws of England, without regard to its conflict of law provisions.</p>
      <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.</p>
      <h2>Contact Us</h2>
      <p>If you have any questions you may contact us through the contact form, or via email to <a href="mailto:matt@wxtxt.co">matt@wxtxt.co</a></p>
      <br></br>
      <p>{props.companyName} is a Limited Company registered in England - company number 12819435</p>
  
    </LongContent>
  );
}

export default LegalTerms;
