export const timeConditions = [
    {
        "title": "Choose Months",
        "description": "Choose which months you want the alert to be active for.",
        "description2": "By default all months are selected, but you can turn some off using this control. This is helpful when looking for out of season days where the weather is better than expected.",
        "control": {
            "flavour": "picker",
            "ofType": "multi",
            "options": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            "selected": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        }
    },
    {
        "title": "Choose Days",
        "description": "Choose which days of the week you want the alert to be active for.",
        "description2": "By default all days are selected, but you can turn some off using this control. This is helpful when you only want to search for weekend adventures.",
        "control": {
            "flavour": "picker",
            "ofType": "multi",
            "options": ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            "selected": ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        }
    },
    {
        "title": "Consecutive Days",
        "description": "Set the number of days in a row you need your weather conditions to match.",
        "description2": "For example, you might require 3 days of good weather for a long distance hike. Without this control we just look for one matching day.",
        "control": {
            "flavour": "slider",
            "unitType": "none",
            "minValue": 1,
            "maxValue": 7,
            "increment": 1,
            "valueWanted": 1,
            "marks": [1, 2, 3, 4, 5, 6, 7]
        }
    },
];
