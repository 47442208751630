export const graphMap = {
    "Average Temperature": {
        "filter": {
            "notBelow": "mean",
            "notAbove": "mean",
        },
        "location": "Temperature",
        "charts":[
            {
                "field": "mean",
                "showTimeline": true,
                "showDistribution": true,
                "display": {
                    "showUnits": true,
                    "axis": {
                        "fieldName": "Temperature",
                        "descriptor": "Average",
                    },
                    "hover": {
                        "fieldName": "Temp",
                        "descriptor": "Avg",
                        "decimals": 1,
                    },
                },
            }, 
        ], 
        "usePeriods": true,
        "invertColours": false,
        "unit": "tempPref",
        "isRadar": false,
    },
    "Hourly Temperature":{
        "filter": {
            "notBelow": "min",
            "notAbove": "max",
        },
        "location": "Temperature",
        "charts":[
            {
                "field": "min",
                "showTimeline": true,
                "showDistribution": true,
                "display": {
                    "showUnits": true,
                    "axis": {
                        "fieldName": "Temperature",
                        "descriptor": "Min",
                    },
                    "hover": {
                        "fieldName": "Temp",
                        "descriptor": "Min",
                        "decimals": 1,
                    },
                },
            }, 
            {
                "field": "max",
                "showTimeline": true,
                "showDistribution": true,
                "display": {
                    "showUnits": true,
                    "axis": {
                        "fieldName": "Temperature",
                        "descriptor": "Max",
                    },
                    "hover": {
                        "fieldName": "Temp",
                        "descriptor": "Max",
                        "decimals": 1,
                    },
                },
            }, 
        ], 
        "usePeriods": true,
        "invertColours": false,
        "unit": "tempPref",
        "isRadar": false,
    },
    "Average 'Feels Like' Temp": {
        "filter": {
            "notBelow": "mean",
            "notAbove": "mean",
        },
        "location": "FeelsLike",
        "charts":[
            {
                "field": "mean",
                "showTimeline": true,
                "showDistribution": true,
                "display": {
                    "showUnits": true,
                    "axis": {
                        "fieldName": "'Feels Like' Temp",
                        "descriptor": "Avg",
                    },
                    "hover": {
                        "fieldName": "'Feels Like'",
                        "descriptor": "Avg",
                        "decimals": 1,
                    },
                },
            }, 
        ], 
        "usePeriods": true,
        "invertColours": false,
        "unit": "tempPref",
        "isRadar": false,
    },
    "Hourly 'Feels Like' Temp":{
        "filter": {
            "notBelow": "min",
            "notAbove": "max",
        },
        "location": "FeelsLike",
        "charts":[
            {
                "field": "min",
                "showTimeline": true,
                "showDistribution": true,
                "display": {
                    "showUnits": true,
                    "axis": {
                        "fieldName": "'Feels Like' Temp'",
                        "descriptor": "Min",
                    },
                    "hover": {
                        "fieldName": "'Feels Like'",
                        "descriptor": "Min",
                        "decimals": 1,
                    },
                },
            }, 
            {
                "field": "max",
                "showTimeline": true,
                "showDistribution": true,
                "display": {
                    "showUnits": true,
                    "axis": {
                        "fieldName": "'Feels Like' Temp'",
                        "descriptor": "Max",
                    },
                    "hover": {
                        "fieldName": "'Feels Like'",
                        "descriptor": "Max",
                        "decimals": 1,
                    },
                },
            }, 
        ], 
        "usePeriods": true,
        "invertColours": false,
        "unit": "tempPref",
        "isRadar": false,
    },
    "Average Humidity":{
        "filter": {
            "notBelow": "mean",
            "notAbove": "mean",
        },
        "location": "Humidity",
        "charts":[
            {
                "field": "mean",
                "showTimeline": true,
                "showDistribution": true,
                "display": {
                    "showUnits": true,
                    "axis": {
                        "fieldName": "Humidity",
                        "descriptor": "Average",
                    },
                    "hover": {
                        "fieldName": "Humidity",
                        "descriptor": "Avg",
                        "decimals": 0,
                    },
                },
            }, 
        ], 
        "usePeriods": true,
        "invertColours": false,
        "unit": "percent",
        "isRadar": false,
    },
    "Humidity":{
        "filter": {
            "notBelow": "min",
            "notAbove": "max",
        },
        "location": "Humidity",
        "charts":[
            {
                "field": "min",
                "showTimeline": true,
                "showDistribution": true,
                "display": {
                    "showUnits": true,
                    "axis": {
                        "fieldName": "Humidity",
                        "descriptor": "Min",
                    },
                    "hover": {
                        "fieldName": "Humidity",
                        "descriptor": "Min",
                        "decimals": 0,
                    },
                },
            }, 
            {
                "field": "max",
                "showTimeline": true,
                "showDistribution": true,
                "display": {
                    "showUnits": true,
                    "axis": {
                        "fieldName": "Humidity",
                        "descriptor": "Max",
                    },
                    "hover": {
                        "fieldName": "Humidity",
                        "descriptor": "Max",
                        "decimals": 0,
                    },
                },
            }, 
        ], 
        "usePeriods": true,
        "invertColours": false,
        "unit": "percent",
        "isRadar": false,
    },
    "Average Cloud Cover": {
        "filter": {
            "notBelow": "mean",
            "notAbove": "mean",
        },
        "location": "Cloud",
        "charts":[
            {
                "field": "mean",
                "showTimeline": true,
                "showDistribution": true,
                "display": {
                    "showUnits": true,
                    "axis": {
                        "fieldName": "Cloud Cover",
                        "descriptor": "Average",
                    },
                    "hover": {
                        "fieldName": "Cloud Cover",
                        "descriptor": "Avg",
                        "decimals": 0,
                    },
                },
            }, 
        ], 
        "usePeriods": true,
        "invertColours": false,
        "unit": "percent",
        "isRadar": false,
    },
    "Hourly Cloud Cover": {
        "filter": {
            "notBelow": "min",
            "notAbove": "max",
        },
        "location": "Cloud",
        "charts":[
            {
                "field": "min",
                "showTimeline": true,
                "showDistribution": true,
                "display": {
                    "showUnits": true,
                    "axis": {
                        "fieldName": "Cloud Cover",
                        "descriptor": "Min",
                    },
                    "hover": {
                        "fieldName": "Cloud Cover",
                        "descriptor": "Min",
                        "decimals": 0,
                    },
                },
            }, 
            {
                "field": "max",
                "showTimeline": true,
                "showDistribution": true,
                "display": {
                    "showUnits": true,
                    "axis": {
                        "fieldName": "Cloud Cover",
                        "descriptor": "Max",
                    },
                    "hover": {
                        "fieldName": "Cloud Cover",
                        "descriptor": "Max",
                        "decimals": 0,
                    },
                },
            }, 
        ], 
        "usePeriods": true,
        "invertColours": false,
        "unit": "percent",
        "isRadar": false,
    },
    "Visibility": {
        "filter": {
            "notBelow": "min",
            "notAbove": "max",
        },
        "location": "Visibility",
        "charts":[
            {
                "field": "min",
                "showTimeline": true,
                "showDistribution": true,
                "display": {
                    "showUnits": true,
                    "axis": {
                        "fieldName": "Visibility",
                        "descriptor": "Min",
                    },
                    "hover": {
                        "fieldName": "Visibility",
                        "descriptor": "Min",
                        "decimals": 0,
                    },
                },
            }, 
            {
                "field": "max",
                "showTimeline": true,
                "showDistribution": true,
                "display": {
                    "showUnits": true,
                    "axis": {
                        "fieldName": "Visibility",
                        "descriptor": "Max",
                    },
                    "hover": {
                        "fieldName": "Visibility",
                        "descriptor": "Max",
                        "decimals": 0,
                    },
                },
            }, 
        ], 
        "usePeriods": true,
        "invertColours": true,
        "unit": "distancePrefkm",
        "isRadar": false,
    },
    "Expected Rain Hours": {
        "filter": {
            "notBelow": "rainHours",
            "notAbove": "rainHours",
        },
        "location": "Rain",
        "charts":[
            {
                "field": "rainHours",
                "showTimeline": true,
                "showDistribution": true,
                "display": {
                    "showUnits": true,
                    "axis": {
                        "fieldName": "Rain Hours",
                        "descriptor": "Total",
                    },
                    "hover": {
                        "fieldName": "Rain Hours",
                        "descriptor": "Total",
                        "decimals": 0,
                    },
                },
            }, 
           
        ], 
        "usePeriods": true,
        "invertColours": false,
        "unit": "hours",
        "isRadar": false,
    },
    "Hourly Rain": {
        "filter": {
            "notBelow": "min",
            "notAbove": "max",
        },
        "location": "Rain",
        "charts":[
            {
                "field": "min",
                "showTimeline": true,
                "showDistribution": true,
                "display": {
                    "showUnits": true,
                    "axis": {
                        "fieldName": "Rainfall",
                        "descriptor": "Min Hourly",
                    },
                    "hover": {
                        "fieldName": "Rainfall",
                        "descriptor": "Min",
                        "decimals": 1,
                    },
                },
            }, 
            {
                "field": "max",
                "showTimeline": true,
                "showDistribution": true,
                "display": {
                    "showUnits": true,
                    "axis": {
                        "fieldName": "Rainfall",
                        "descriptor": "Max Hourly",
                    },
                    "hover": {
                        "fieldName": "Rainfall",
                        "descriptor": "Max",
                        "decimals": 1,
                    },
                },
            }, 
        ], 
        "usePeriods": true,
        "invertColours": false,
        "unit": "distancePrefmm",
        "isRadar": false,
    },
    "Total Rain": {
        "filter": {
            "notBelow": "total",
            "notAbove": "total",
        },
        "location": "Rain",
        "charts":[
            {
                "field": "total",
                "showTimeline": true,
                "showDistribution": true,
                "display": {
                    "showUnits": true,
                    "axis": {
                        "fieldName": "Rainfall",
                        "descriptor": "Total",
                    },
                    "hover": {
                        "fieldName": "Rainfall",
                        "descriptor": "Total",
                        "decimals": 1,
                    },
                },
            }, 
        ], 
        "usePeriods": true,
        "invertColours": false,
        "unit": "distancePrefmm",
        "isRadar": false,
    },
    "Total Snow": {
        "filter": {
            "notBelow": "total",
            "notAbove": "total",
        },
        "location": "Snow",
        "charts":[
            {
                "field": "total",
                "showTimeline": true,
                "showDistribution": true,
                "display": {
                    "showUnits": true,
                    "axis": {
                        "fieldName": "Snow",
                        "descriptor": "Total",
                    },
                    "hover": {
                        "fieldName": "Snow",
                        "descriptor": "Total",
                        "decimals": 1,
                    },
                },
            }, 
        ], 
        "usePeriods": true,
        "invertColours": false,
        "unit": "distancePrefcm",
        "isRadar": false,
    },
    "Snow Depth": {
        "filter": {
            "notBelow": "min",
            "notAbove": "max",
        },
        "location": "SnowDepth",
        "charts":[
            {
                "field": "min",
                "showTimeline": true,
                "showDistribution": true,
                "display": {
                    "showUnits": true,
                    "axis": {
                        "fieldName": "Snow Depth",
                        "descriptor": "Min Hourly",
                    },
                    "hover": {
                        "fieldName": "Snow Depth",
                        "descriptor": "Min",
                        "decimals": 1,
                    },
                },
            }, 
            {
                "field": "max",
                "showTimeline": true,
                "showDistribution": true,
                "display": {
                    "showUnits": true,
                    "axis": {
                        "fieldName": "Snow Depth",
                        "descriptor": "Max Hourly",
                    },
                    "hover": {
                        "fieldName": "Snow Depth",
                        "descriptor": "Max",
                        "decimals": 1,
                    },
                },
            }, 
        ], 
        "usePeriods": true,
        "invertColours": false,
        "unit": "distancePrefcm",
        "isRadar": false,
    },
    "Average Wind Speed": {
        "filter": {
            "notBelow": "mean",
            "notAbove": "mean",
        },
        "location": "WindSpeed",
        "charts":[
            {
                "field": "mean",
                "showTimeline": true,
                "showDistribution": true,
                "display": {
                    "showUnits": true,
                    "axis": {
                        "fieldName": "Wind Speed",
                        "descriptor": "Average",
                    },
                    "hover": {
                        "fieldName": "Wind Speed",
                        "descriptor": "Avg",
                        "decimals": 1,
                    },
                },
            }, 
        ], 
        "usePeriods": true,
        "invertColours": false,
        "unit": "speedPref",
        "isRadar": false,
    },
    "Hourly Wind Speed": {
        "filter": {
            "notBelow": "min",
            "notAbove": "max",
        },
        "location": "WindSpeed",
        "charts":[
            {
                "field": "min",
                "showTimeline": true,
                "showDistribution": true,
                "display": {
                    "showUnits": true,
                    "axis": {
                        "fieldName": "Wind Speed",
                        "descriptor": "Min",
                    },
                    "hover": {
                        "fieldName": "Wind Speed",
                        "descriptor": "Min",
                        "decimals": 1,
                    },
                },
            }, 
            {
                "field": "max",
                "showTimeline": true,
                "showDistribution": true,
                "display": {
                    "showUnits": true,
                    "axis": {
                        "fieldName": "Wind Speed",
                        "descriptor": "Max",
                    },
                    "hover": {
                        "fieldName": "Wind Speed",
                        "descriptor": "Max",
                        "decimals": 1,
                    },
                },
            }, 
        ], 
        "usePeriods": true,
        "invertColours": false,
        "unit": "speedPref",
        "isRadar": false,
    },
    "Wind Gust": {
        "filter": {
            "notAbove": "max",
        },
        "location": "WindGust",
        "charts":[
            {
                "field": "max",
                "showTimeline": true,
                "showDistribution": true,
                "display": {
                    "showUnits": true,
                    "axis": {
                        "fieldName": "Gust Speed",
                        "descriptor": "Max",
                    },
                    "hover": {
                        "fieldName": "Gust Speed",
                        "descriptor": "Max",
                        "decimals": 1,
                    },
                },
            }, 
        ], 
        "usePeriods": true,
        "invertColours": false,
        "unit": "speedPref",
        "isRadar": false,
    },
    "Average Wind Direction": {
        "filter": {
            "notBelow": "mean",
            "notAbove": "mean",
        },
        "location": "WindDirection",
        "charts":[
            {
                "field": "mean",
                "showTimeline": false,
                "showDistribution": true,
                "display": {
                    "showUnits": false,
                    "axis": {
                        "fieldName": "Wind Direction",
                        "descriptor": "Average",
                    },
                    "hover": {
                        "fieldName": "Wind Direction",
                        "descriptor": "Avg",
                        "decimals": 0,
                    },
                },
            }, 
        ], 
        "usePeriods": true,
        "invertColours": false,
        "unit": "none",
        "isRadar": true,
    },
    "Hourly Wind Direction": {
        "filter": {
            "notBelow": "min",
            "notAbove": "max",
        },
        "location": "WindDirection",
        "charts":[
            {
                "field": "mean",
                "showTimeline": false,
                "showDistribution": true,
                "display": {
                    "showUnits": false,
                    "axis": {
                        "fieldName": "Wind Direction",
                        "descriptor": "Average",
                    },
                    "hover": {
                        "fieldName": "Wind Direction",
                        "descriptor": "Avg",
                        "decimals": 0,
                    },
                },
            }, 
        ], 
        "usePeriods": true,
        "invertColours": false,
        "unit": "none",
        "isRadar": true,
    },
    "Sunlight Hours": {
        "filter": {
            "notBelow": "total",
            "notAbove": "total",
        },
        "location": "SunlightHours",
        "charts":[
            {
                "field": "total",
                "showTimeline": true,
                "showDistribution": true,
                "display": {
                    "showUnits": true,
                    "axis": {
                        "fieldName": "Sunlight",
                        "descriptor": "Total",
                    },
                    "hover": {
                        "fieldName": "Sunlight",
                        "descriptor": "Total",
                        "decimals": 1,
                    },
                },
            }, 
        ], 
        "usePeriods": false,
        "invertColours": false,
        "unit": "hours",
        "isRadar": false,
    },
    "Max UV": {
        "filter": {
            "notAbove": "max",
        },
        "location": "UV",
        "charts":[
            {
                "field": "total",
                "showTimeline": true,
                "showDistribution": true,
                "display": {
                    "showUnits": false,
                    "axis": {
                        "fieldName": "UV",
                        "descriptor": "Total",
                    },
                    "hover": {
                        "fieldName": "UV",
                        "descriptor": "Total",
                        "decimals": 0,
                    },
                },
            }, 
            {
                "field": "max",
                "showTimeline": true,
                "showDistribution": true,
                "display": {
                    "showUnits": false,
                    "axis": {
                        "fieldName": "UV",
                        "descriptor": "Max",
                    },
                    "hover": {
                        "fieldName": "UV",
                        "descriptor": "Max",
                        "decimals": 0,
                    },
                },
            }, 
        ], 
        "usePeriods": true,
        "invertColours": false,
        "unit": "none",
        "isRadar": false,
    },
    "Moon Illumination": {
        "filter": {
            "notBelow": "total",
            "notAbove": "total",
        },
        "location": "MoonIllumination",
        "charts":[
            {
                "field": "total",
                "showTimeline": true,
                "showDistribution": true,
                "display": {
                    "showUnits": true,
                    "axis": {
                        "fieldName": "Illumination",
                        "descriptor": "Moon",
                    },
                    "hover": {
                        "fieldName": "Illumination",
                        "descriptor": "Moon",
                        "decimals": 0,
                    },
                },
            }, 
        ], 
        "usePeriods": false,
        "invertColours": false,
        "unit": "percent",
        "isRadar": false,
    },
    "Moonlight Hours": {
        "filter": {
            "notBelow": "total",
            "notAbove": "total",
        },
        "location": "MoonlightHours",
        "charts":[
            {
                "field": "total",
                "showTimeline": true,
                "showDistribution": true,
                "display": {
                    "showUnits": true,
                    "axis": {
                        "fieldName": "Moonlight",
                        "descriptor": "Total",
                    },
                    "hover": {
                        "fieldName": "Moonlight",
                        "descriptor": "Total",
                        "decimals": 1,
                    },
                },
            }, 
        ], 
        "usePeriods": false,
        "invertColours": false,
        "unit": "hours",
        "isRadar": false,
    },
}
