import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
    useTheme
  } from "@material-ui/core/styles";
import { translateValueToPref, valuetext } from "./ConditionSlider";
import { useDarkMode } from "../util/theme";


function getLocation(condition, aboveSM) {

    if (aboveSM) {
        if (condition.period !== false) {
            if (condition.period === "day") {
                return "Daily " + condition.condition;
            } else if (condition.period==="night") {
                return "Nightly " + condition.condition;
            } else {
                return condition.condition + " (24 Hours)";
            }
        } else {
            return condition.condition;
        }
    } else {
        if (condition.condition === "Average Temperature") {
            return "Avg Temp"
        } else if (condition.condition === "Average Cloud Cover") {
            return "Cloud Cover"
        } else if (condition.condition === "Average Wind Speed") {
            return "Wind Speed"
        } else if (condition.condition === "Moon Illumination") {
            return "Visible Moon"
        } else if (condition.condition==="Moonlight Hours") {
            return "Moonlight"
        } else if (condition.condition === "Hourly Wind Speed") {
            return "Min Wind"
        }
        return (condition.condition)
    }

    

}

function getDesired(condition) {
    return condition.desired;
}

function getActual(condition, tempPref, speedPref, distancePref, aboveSM) {

    
    if (condition.min === false) {
        const v = translateValueToPref(condition.maxFound, condition.unitType, tempPref, speedPref, distancePref)
        const withUnits = valuetext(v, condition.unitType, tempPref, speedPref, distancePref)
        if (v===0) {
            return "None"
        }
        return "Under " + withUnits
    }
    
    if (condition.max === false) {
        const v = translateValueToPref(condition.minFound, condition.unitType, tempPref, speedPref, distancePref)
        const withUnits = valuetext(v, condition.unitType, tempPref, speedPref, distancePref)
        return "Over " + withUnits
    }

    const vMin = translateValueToPref(condition.minFound, condition.unitType, tempPref, speedPref, distancePref)
    const minWithUnits = valuetext(vMin, condition.unitType, tempPref, speedPref, distancePref)

    const vMax = translateValueToPref(condition.maxFound, condition.unitType, tempPref, speedPref, distancePref)
    const maxWithUnits = valuetext(vMax, condition.unitType, tempPref, speedPref, distancePref)
    
    
    return minWithUnits + " - " + maxWithUnits
}

function OneConditionRow({index, condition, tempPref, speedPref, distancePref}) {

    const darkMode = useDarkMode();

    const theme = useTheme();
    const aboveSM = useMediaQuery(theme.breakpoints.up('sm'));

    const location = getLocation(condition, aboveSM)
    const desired = getDesired(condition)
    const actual = getActual(condition, tempPref, speedPref, distancePref, aboveSM)
    
    let colour = (darkMode.value) ? ((index % 2 ===0) ? "#4f4f4f" : "#5c5c5c") : ((index % 2 ===0) ? "#f4f4f4" : "#e7e7e7")
    

    let borderColour = (darkMode.value) ? "#565656" : "#EEEEEE";

    borderColour = (aboveSM) ? colour : borderColour


    
    return (
        <>
        <Grid item xs={6}>
            <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            padding = {0}
            sx={{ 
                padding: "0", 
                margin: "0px",
                borderRadius: "0%",
                bgcolor: colour,
                borderColor: borderColour,
                border: '1px solid'
            }} 
            >
                <Grid container>                   
                    <Grid item xs={12}>
                        <Typography variant="body1" style={{marginTop: 10}}>
                            {location}
                        </Typography>          
                    </Grid>
                    
                </Grid>
            </Box>
            
        </Grid>
        { aboveSM &&
        <Grid item xs={4} sm={2}>
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            padding = {0}
            sx={{ 
                padding: "0", 
                margin: "0px",
                borderRadius: "0%",
                bgcolor: colour,
                borderColor: borderColour,
                border: '1px solid'
            }} 
            >
                <Grid container>                   
                    <Grid item xs={12}>
                        <Typography variant="body1" style={{marginTop: 10}}>
                            {desired}
                        </Typography>          
                    </Grid>
                    
                </Grid>
            </Box>
            
        </Grid>
        }
        <Grid item xs={6} sm={4}>
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            padding = {0}
            sx={{ 
                padding: "0", 
                margin: "0px",
                borderRadius: "0%",
                bgcolor: colour,
                borderColor: borderColour,
                border: '1px solid'
            }} 
            >
                <Grid container>                   
                    <Grid item xs={12}>
                        <Typography variant="body1" style={{marginTop: 10}}>
                            {actual}
                        </Typography>          
                    </Grid>
                    
                </Grid>
            </Box>
            
        </Grid>
        
        </>
      );
    }

export default OneConditionRow;



