import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { rehydrate } from "./DashboardAlerts";
import { timeConditions } from "../util/timeConditions";
import { smsConditions } from "../util/smsConditions";
import { createLocalStorageStateHook } from "use-local-storage-state";


const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

function AlertLoader({setWeatherConditionsList, setTimeConditionsList, setSMSConditionsList, setSelectedLocation, weatherConditions, setMarker, setCenter, seekingAlertId, setResetDefaults, setFixLocation, setActivity, setUsedLoader}) {

    const classes = useStyles();
    // need to fetch the data from the API

    const [success, setSuccess] = React.useState(false)
    const [error, setError] = React.useState(false)

    // ApDARqMWhFRozCcEMfK1 is good

    const referrerLS = createLocalStorageStateHook("referrer");
    // eslint-disable-next-line no-unused-vars
    const [referrer, setReferrer] = referrerLS();


    useEffect(() => {
        fetch('https://us-central1-wxtxtco.cloudfunctions.net/getAlert', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
                'Origin': 'localhost:3000',
            },
            body: JSON.stringify({
                'alertId': seekingAlertId
            })
        })
        .then((response) => response.json())
        .then((data) => {
            if ("error" in data) {
                setError(true);
            } else {
                setSuccess(true);
                setActivity("Custom")
                setSelectedLocation({ latitude: data.latitude, longitude: data.longitude })
                setMarker([{lat: data.latitude, lng: data.longitude}])
                setCenter({lat: data.latitude, lng: data.longitude});  
                // here is where I need to rehydrate the weatherConditionsList etc based on the data in alert.
                setWeatherConditionsList(rehydrate(data.weatherConditions, weatherConditions))
                setTimeConditionsList(rehydrate(data.timeConditions, timeConditions))
                setSMSConditionsList(rehydrate(data.smsConditions, smsConditions))
                setReferrer(data.owner);
                setResetDefaults(false)
                setFixLocation(true)
                setUsedLoader(true)
            }
            handleClose();
        })
        .catch((error) => {
            console.log(error)
            setError(true);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
        };

    return (
    <>
        {success && (
        <Box mx="auto" mb={4} maxWidth={400}>
            <Alert severity="success">
              Alert loaded! Use code PERFECTDAY for a 25% discount on all plans for the first year.
              <span
                role="img"
                aria-label="party"
                style={{ marginLeft: "10px" }}
              >
                🥳
              </span>
            </Alert>
          </Box>)}

          {error && (
        <Box mx="auto" mb={4} maxWidth={400}>
            <Alert severity="error">
              Couldn't find that alert - it may have been deleted by the user, or there's a typo.
              <span
                role="img"
                aria-label="sad"
                style={{ marginLeft: "10px" }}
              >
                😢
              </span>
            </Alert>
          </Box>)}
        <div>
          <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
    </>
    )
}

export default AlertLoader;











