import React, { useState } from "react";
import { styled } from "@material-ui/styles"
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

export default function ConditionTimePicker({control, setTimeConditionsList, index}) {
  const StyledToggleButton = styled(ToggleButton)(({ selectedcolor }) => ({
    '&.Mui-selected, &.Mui-selected:hover': {
        color: 'white',
        backgroundColor: selectedcolor,
    },
  }));

  const options = control.options;
  const selected = control.selected;

  const isExclusive = control.ofType === "single";

  const [formats, setFormats] = useState(selected)

  const handleFormat = (event, newFormats
  ) => {
    if (newFormats !== null) {
        if (newFormats.length >0) {
            setFormats(newFormats);
            setTimeConditionsList((draft) => {
                draft[index].control.selected = newFormats;
            });
        }
    }
    
  };

  return (
    <ToggleButtonGroup
        style={{ flexWrap: "wrap" }}
        color="primary"
        value={formats}
        onChange={handleFormat}
        exclusive = {isExclusive}
        size="small"
        >
        {options.map((m, index) => {
            return (
                <StyledToggleButton 
                    key = {m}
                    value={m}
                    aria-label={m}
                    selectedcolor='#61d1d5'
                >
                {m}
                </StyledToggleButton>
            )
        })}
    </ToggleButtonGroup>
  );
}