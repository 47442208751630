import React, { useEffect, useState } from "react";
// import Plot from 'react-plotly.js';
//import Plotly from "plotly.js-basic-dist-min";
import Plotly from "../util/plotly-custom-min"
import createPlotlyComponent from "react-plotly.js/factory"
import { useDarkMode } from "../util/theme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
    useTheme
  } from "@material-ui/core/styles";

//var Plotly = require("../util/plotly-custom-min.js")
const Plot = createPlotlyComponent(Plotly);

function PlotTimeline({graph}) {

    const darkMode = useDarkMode();

    const [fontColor, setFontColor] = useState('#000')
    const [gridColor, setGridColor] = useState('rgba(255, 255, 255, 0.2)')

    useEffect(() => {
        setFontColor(darkMode.value ? '#FFF' : '#000');
        setGridColor(darkMode.value ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)');
    }, [darkMode])

    // graph object is now a list
    // Each one contains [xVals, yVals, barColours, yAxisLabel, hoverTemplate: {fieldName, descriptor, showUnits, displayUnits (optional), decimals}]
    const theme = useTheme();
    const aboveSM = useMediaQuery(theme.breakpoints.up('sm'));

    const margin = (aboveSM) ? {b:20, l:69, r:69, t:30} : {b:20, l:15, r:0, t:0};
    const radarMargin = (aboveSM) ? {b:20, l:0, r:0, t:20} : {b:0, l:31, r:31, t:0}
    const useTitle = (aboveSM) ? graph.title : "";
    const useYAxisLabel = (aboveSM) ? graph.yAxisLabel : "";


    let traces = []

    let hov = "%{x:.1f}% of the time the " + graph.hoverTemplate.descriptor + "<br>" + graph.hoverTemplate.fieldName + " is less than %{y:." + graph.hoverTemplate.decimals + "f}<extra></extra>";
    if (graph.hoverTemplate.showUnits) {
        hov = "%{x:.1f}% of the time the " + graph.hoverTemplate.descriptor + "<br>" + graph.hoverTemplate.fieldName + " is less than %{y:." + graph.hoverTemplate.decimals + "f}" + graph.hoverTemplate.displayUnits + "<extra></extra>";
    }
    if (graph.isTimeline) {
        if (graph.hoverTemplate.showUnits) {
            hov = "<b>%{x}</b><br>" + graph.hoverTemplate.descriptor + " " + graph.hoverTemplate.fieldName + ": %{y:." + graph.hoverTemplate.decimals + "f}" + graph.hoverTemplate.displayUnits + "<extra></extra>";    
        } else {
            hov = "<b>%{x}</b><br>" + graph.hoverTemplate.descriptor + " " + graph.hoverTemplate.fieldName + ": %{y:." + graph.hoverTemplate.decimals + "f}<extra></extra>";
        }
    }

    const bars = {
        x: graph.xVals,
        y: graph.yVals,
        type: "bar",
        marker: { color: graph.barColours},
        hoverinfo: 'x+y',
        hovertemplate: hov,
    };
    traces.push(bars)

    let layout = {
        paper_bgcolor: 'rgba(0, 0, 0, 0)',
        plot_bgcolor: 'rgba(0, 0, 0, 0)',
        showlegend: false,
        height: 300,
        margin: margin,
        font: {color: fontColor},
        title: {
            text: useTitle,
        },
        yaxis: {
            showticklabels: true, 
            gridcolor: gridColor,
            title: useYAxisLabel
        },
        xaxis: {
            dtick: 10,
            gridcolor: gridColor,
        }
    }

    if (graph.isRadar) {
        traces = [{
            r: graph.yVals,
            theta: graph.xVals,
            marker: {color: graph.barColours},
            type: "barpolar",
            hoverinfo: 'r+theta',
            hovertemplate: "On <b>%{r}</b> days the wind blew<br>on average from the %{theta}<extra></extra>",
        }]

        layout = {
            margin: radarMargin,
            paper_bgcolor: 'rgba(0, 0, 0, 0)',
            plot_bgcolor: 'rgba(0, 0, 0, 0)',
            font: {color: fontColor},
            height: 300,
            polar: {
                barmode: "overlay",
                bargap: 0.1,
                bgcolor: 'rgba(0, 0, 0, 0)',
                angularaxis: {direction: "clockwise", showticklabels: true, visible: true},
                radialaxis: {showticklabels: false, visible:false}
                
            },
            
        }
    }
    
    if (graph.isTimeline) {
        layout = {
            paper_bgcolor: 'rgba(0, 0, 0, 0)',
            plot_bgcolor: 'rgba(0, 0, 0, 0)',
            showlegend: false,
            title: {
                text: useTitle,
            },
            height: 300,
            margin: margin,
            font: {color: fontColor},
            yaxis: {
                showticklabels: true, 
                gridcolor: gridColor,
                title: useYAxisLabel
            },
        }
    }

    
    
    const config = {
        displayModeBar: false, // this is the line that hides the bar.
      };
  
    return <Plot
            data={traces}
            layout={layout}
            config={config}
            useResizeHandler={true}
            style={{width: "100%", height: "100%"}}
        />
    
}

export default PlotTimeline;
