import React from "react";
import Grid from "@material-ui/core/Grid";
import Button  from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
    useTheme
  } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";

function ActivityBoxes({activityName, activityIcon, activity, setActivity, singleWidth}) {

    const theme = useTheme();
    const aboveSM = useMediaQuery(theme.breakpoints.up('sm'));

    let selected = false;
    if (activity === activityName) {
        selected = true;
    }

    const iconColor = selected ? "#ffc23e" : "#BEBEBE"

    function handleActivityClick(a) {
        setActivity(a)
    }

    const iconSize = aboveSM ? "3x" : "2x";

    let xsSize = 3
    let mdSize = 4
    let lgSize = 3

    if (singleWidth) {
        xsSize=4
        mdSize=1
        lgSize=1
    }

    const tooltip = aboveSM ? "" : activityName;

    const typographyVariant = singleWidth ? "body2": "body1"

    
    return (
        <Grid item xs={xsSize} md={mdSize} lg={lgSize}>
            
            <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding = {0}
            sx={{ 
                padding: "0", 
                margin: "4px"
            }} 
            >
            <Tooltip title={tooltip}>
            <Button 
                onClick={() => handleActivityClick(activityName)}
                fullWidth>
                <Grid container>
                    <Grid item xs={12}>
                        <FontAwesomeIcon icon={activityIcon} size={iconSize} color={iconColor} style={{marginTop: 10}}/>
                    </Grid>
                    
                    <Grid item xs={12}>
                        {aboveSM && 
                        <Typography variant={typographyVariant} style={{marginTop: 10}}>
                            {activityName}
                        </Typography>          
                        }
                    </Grid>
                    
                </Grid>
             
             
                </Button>
                </Tooltip>
            </Box>
            
        </Grid>
      );
    }

export default ActivityBoxes;



