// generating a continuous color scale for wattage values relative to max
// https://rdrr.io/cran/wesanderson/src/R/colors.R
// Zissou1 = c("#3B9AB2", "#78B7C5", "#EBCC2A", "#E1AF00", "#F21A00"),
// define it on the range halfMeanPower to maxPower
const colours = [
    [59, 154, 178], // #3B9AB2
    [120, 183, 197], // #78B7C5
    [235, 204, 42], // #EBCC2A
    [225, 175, 0], // #E1AF00
    [242, 26, 0], // #F21A00
  ];
  
  function interpolateColours(
    first,
    second,
    distance,
  ) {
    return [
      Math.round(first[0] * (1 - distance) + second[0] * distance),
      Math.round(first[1] * (1 - distance) + second[1] * distance),
      Math.round(first[2] * (1 - distance) + second[2] * distance),
    ];
  }
  export function rawNumberToColour(
    x,
    alpha,
    upperBound,
    lowerBound,
  ) {
    // take a number x, for our wattage
    // work out where that fits in our scale 0...1
    let position = 0;
    if (upperBound!==lowerBound) {
        position = (x - lowerBound) / (upperBound - lowerBound);
    }
    const startColour = Math.floor(position * (colours.length - 1));
    const firstColour = colours[startColour];
    const secondColour = colours[Math.min(startColour + 1, colours.length - 1)];
    const [r, g, b] = interpolateColours(
      firstColour,
      secondColour,
      (position * (colours.length - 1)) % 1,
    );
    return `rgba(${r},${g},${b},${alpha})`;
  }
  