import React, { useState } from "react";
import Button from '@material-ui/core/Button'
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid";
import ConditionAddModal from "../ConditionAddModal";
import ConditionTimeAddModal from "../ConditionTimeAddModal";
import GoogleMapPicker from "./GoogleMapPicker";
import AlertBuilder from "./AlertBuilder";
import FetchingHistorical from "./FetchingHistorical";
import Alert from "@material-ui/lab/Alert";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
    useTheme
  } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";

export default function AlertEditor({tempPref, speedPref, distancePref, setWeatherConditionsList, setTimeConditionsList, setSMSConditionsList, weatherConditionsList, timeConditionsList, smsConditionsList, updatingAlertId, hasHistorical, historicalStatus, historical, weatherConditions, setWeatherConditions, selectedLocation, setSelectedLocation, marker, setMarker, map, setMap, center, setCenter, fixLocation, setFixLocation, activity, setActivity, usedLoader, setUsedLoader}) {

    const [newCondition, setNewCondition] = useState(false);
    const [updatingIndex, setUpdatingIndex] = useState(null);
    const [newTimeCondition, setNewTimeCondition] = useState(false)

    const theme = useTheme();
    const aboveXS = useMediaQuery(theme.breakpoints.up('xs'));

    function handleRequestHistorical() {
        const latitude = selectedLocation.latitude;
        const longitude = selectedLocation.longitude;

        fetch('https://us-central1-wxtxtco.cloudfunctions.net/initialiseHistory', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
                'Origin': 'localhost:3000',
            },
            body: JSON.stringify({
                'longitude': longitude,
                'latitude': latitude,
            })
        })
        .then((response) => response.json())
        .catch((error) => {
            console.log(error);
            // todo, tell the user about the error
            // then revert to map open close the backdrop
            setFixLocation(false)
        })
    }

    function handleLoadAndFix() {
        handleRequestHistorical()
        setFixLocation(true)
    }

    const hasMarker = marker.length > 0;

    return (
        <>
      <div>
        <Box sx={{ py: 1 }}>
            {!fixLocation && 
            <>
             <GoogleMapPicker setSelectedLocation={setSelectedLocation} marker={marker} setMarker={setMarker} map={map} setMap={setMap} center={center} setCenter={setCenter}/>
             <Grid item xs={12}>
                    { (historicalStatus==="loading") &&
                    <>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        padding={2}
                        >
                        <CircularProgress/>
                        </Box>
                    </>
                    }
                    { ((historicalStatus==="success") || (historicalStatus==="idle")) &&
                    <>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        padding={1}
                        >
                        { hasHistorical &&
                        <Box padding={1}>
                            <Button
                                variant="contained"
                                size="medium"
                                color="primary"
                                onClick={() => handleLoadAndFix()}
                            >
                                Set Location
                            </Button>
                        </Box>
                        }
                        {!hasHistorical && hasMarker &&
                        <>
                        <Grid container>
                        {aboveXS &&
                            <Grid item xs={12} sm={1} md={2} lg={3}></Grid>
                        }
                        <Grid item={true} xs={12} sm={10} md={8} lg={6}>
                            <Alert variant="outlined" severity="info">
                            🙏 You're the first person to choose this location! Fetching and analysing weather will take around 10 seconds the first time, but will be instant from then on.
                            </Alert>
                        </Grid>
                        <Grid item={true} xs={12}>
                        <Box display="flex"
                            justifyContent="center"
                            alignItems="center"
                            padding={1}>
                            <Button
                                variant="contained"
                                size="medium"
                                color="primary"
                                onClick={() => handleLoadAndFix()}
                            >
                                Set Location
                            </Button>
                        </Box>
                        </Grid>
                        </Grid>
                        
                        
                    </>
                        }
                        {!hasMarker &&
                        <Box padding={1}>
                            <Typography variant="h5">
                                Choose a location to monitor by clicking on the map.
                                </Typography>
                        </Box>
                        }
                    </Box>
                    </>
                    }
                </Grid>
                </>
            }
            {fixLocation && hasHistorical && 
            <>
                <AlertBuilder 
                    selectedLocation={selectedLocation}
                    historical={historical} 
                    weatherConditionsList={weatherConditionsList} 
                    timeConditionsList={timeConditionsList}
                    updatingAlertId={updatingAlertId}
                    setWeatherConditionsList={setWeatherConditionsList}
                    setTimeConditionsList={setTimeConditionsList}
                    tempPref={tempPref}
                    speedPref={speedPref}
                    distancePref={distancePref}
                    setUpdatingIndex={setUpdatingIndex}
                    setNewCondition={setNewCondition}
                    setNewTimeCondition={setNewTimeCondition}
                    hasHistorical={hasHistorical}
                    activity={activity}
                    setActivity={setActivity}
                    setFixLocation={setFixLocation}
                    usedLoader={usedLoader}
                    setUsedLoader={setUsedLoader}
                />
            </>
            }
            {fixLocation && !hasHistorical && 
            <>
                <FetchingHistorical/>
                </>
            }
        </Box>
      </div>
      {newCondition && (
        <ConditionAddModal
          setWeatherConditionsList={setWeatherConditionsList}
          weatherConditionsList={weatherConditionsList}
          updatingIndex={updatingIndex}
          historical={historical}
          tempPref={tempPref}
          distancePref={distancePref}
          speedPref={speedPref}
          weatherConditions={weatherConditions}
          setWeatherConditions={setWeatherConditions}
          onDone={() => {
            setNewCondition(false);
            setUpdatingIndex(null);
          }}
        />
      )}
      {newTimeCondition && (
        <ConditionTimeAddModal
          setTimeConditionsList={setTimeConditionsList}
          onDone={() => setNewTimeCondition(false)}
        />
      )}
      </>
    );
  }
  