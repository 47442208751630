import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid";
import { Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import HelpIcon from "@material-ui/icons/Help";
import ConditionTimeBoxControl from "./ConditionTimeBoxControl";


const useStyles = makeStyles((theme) => ({
    summary: {
      backgroundColor: 
      theme.palette.type === "dark" ? theme.palette.action.selected : "#F7F7F7",
    }
}));


export default function ConditionTimeBox({title, subtitle, control, setTimeConditionsList, index, hideDelete}) {

    function deleteCondition(index) {
        setTimeConditionsList((draft) => {
            draft.splice(index, 1)
        });
    }

    const picker = control.flavour === "picker"

    const size = (picker) ? 12 : 10;

    const classes = useStyles()
    return (
        <>
      
            <Box 
                display="flex"
                padding={2}
                sx={{ 
                    width: "100%",
                    padding: "20px 0px 8px 14px", 
                    borderRadius: 4
                }} 
                className={classes.summary}
            >   <Grid container alignItems="center">
                    <Grid item xs={12}>
                        <Grid container direction="row" alignItems="center" spacing={1}>
                            <Grid item>
                                <Tooltip title={subtitle} enterTouchDelay={0} leaveTouchDelay={4000}>
                                    <HelpIcon style={{ fontSize: "24px"}}/>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Typography variant="body1"><strong>{title}</strong></Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={size}>
                        <ConditionTimeBoxControl control={control} setTimeConditionsList={setTimeConditionsList} index={index}/>
                    </Grid>
                    <Grid item xs={9}></Grid>
                    <Grid item xs={3}>
                        <Grid container justifyContent="center">
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                >
                                {!hideDelete && 
                                <Tooltip title="Delete">
                                    <IconButton
                                        edge="end"
                                        aria-label="delete alert"
                                        onClick={() => deleteCondition(index)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                    </Tooltip>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                
            </Box>
      </>
    );
  }
  