import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import StarIcon from "@material-ui/icons/Star";
import EditIcon from "@material-ui/icons/Edit";
import MoneyIcon from "@material-ui/icons/MonetizationOn"
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "./../util/auth";
import { updateAlert, deleteAlert, useAlertsByOwner } from "./../util/db";
import { Tooltip } from "@material-ui/core";
import { timeConditions } from "../util/timeConditions";
import { smsConditions } from "../util/smsConditions";
import ShareModal from "./ShareModal"

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#F3F3F6",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
}));

export function rehydrate(alertConditions, conditions) {
    let hydratedList = []
    alertConditions.forEach((c) => {
        hydratedList.push(structuredClone(conditions.find(x => x.title === c.condition)))
        // overwrite the default values with the ones from the alert object.
        if (c.flavour === "slider") {
            if (c.value.length === 1) {
                // single value
                hydratedList[hydratedList.length-1].control.valueWanted = c.value[0]
            } else {
                hydratedList[hydratedList.length-1].control.minValueWanted = c.value[0]
                hydratedList[hydratedList.length-1].control.maxValueWanted = c.value[1]
            }
        } else {
            // picker
            hydratedList[hydratedList.length-1].control.selected = c.value;
        }
        if ("period" in c) {
            hydratedList[hydratedList.length-1].periodSelected = c.period;
        }
    })

    return hydratedList
}

function DashboardAlerts({setCountActiveAlerts, selectedLocation, setSelectedLocation, setMarker, map, updatingAlertId, setUpdatingAlertId, setWeatherConditionsList, setTimeConditionsList, setSMSConditionsList, weatherConditions, setResetDefaults, setFixLocation}) {
  const classes = useStyles();

  const auth = useAuth();

  const {
    data: alerts,
    status: alertsStatus,
    error: alertsError,
  } = useAlertsByOwner(auth.user.uid);

  const alertsAreEmpty = !alerts || alerts.length === 0;

  var alertsActiveCount = 0
  if (!alertsAreEmpty) {
    alerts.forEach((alert) => {
        if (alert.active) {
            alertsActiveCount+=1;
        }
      })
  }
  
  useEffect(() => {
    setCountActiveAlerts(alertsActiveCount);
  }, [alertsActiveCount, setCountActiveAlerts])
  

  const hasPhoneNumber = (auth.user.phone && (auth.user.phone.length > 0));

  const canUseStar =
    auth.user.planIsActive && (alertsActiveCount < auth.user.allowedAlerts) && hasPhoneNumber

  const [shareAlertId, setShareAlertId] = React.useState(null)
  const [showShareModal, setShowShareModal] = React.useState(false)

  function handleRequestHistorical() {
    if (selectedLocation!== null) {
      const latitude = selectedLocation.latitude;
      const longitude = selectedLocation.longitude;

    fetch('https://us-central1-wxtxtco.cloudfunctions.net/initialiseHistory', {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            'Origin': 'localhost:3000',
        },
        body: JSON.stringify({
            'longitude': longitude,
            'latitude': latitude,
        })
    })
    .then((response) => response.json())
    .catch((error) => {
        console.log(error);
        // todo, tell the user about the error
    })
    }
}

  const handleEditAlert = (alert) => {
    setUpdatingAlertId(alert.id)
    setSelectedLocation({ latitude: alert.latitude, longitude: alert.longitude })
    setMarker([{lat: alert.latitude, lng: alert.longitude}])
    if (map!== null) {
      map.panTo({lat: alert.latitude, lng: alert.longitude})
    }
    // here is where I need to rehydrate the weatherConditionsList etc based on the data in alert.
    setWeatherConditionsList(rehydrate(alert.weatherConditions, weatherConditions))
    setTimeConditionsList(rehydrate(alert.timeConditions, timeConditions))
    setSMSConditionsList(rehydrate(alert.smsConditions, smsConditions)) 
    setResetDefaults(false)
    setFixLocation(true);
    handleRequestHistorical()
  }

  const handleDeleteAlert = (alert) => {
    deleteAlert(alert.id)
    if (updatingAlertId === alert.id) {
        setUpdatingAlertId(null)
    }
  }

  const handleShareModal = (alert) => {
    setShareAlertId(alert.id)
    setShowShareModal(true)
  }

/*   const handleCopyAlert = (alert) => {
    // same as edit but without setting the alert id
    setUpdatingAlertId(null)
    setSelectedLocation({ latitude: alert.latitude, longitude: alert.longitude })
    setMarker([{lat: alert.latitude, lng: alert.longitude}])
    if (map!==null) {
      map.panTo({lat: alert.latitude, lng: alert.longitude})
    }
    // here is where I need to rehydrate the weatherConditionsList etc based on the data in alert.
    setWeatherConditionsList(rehydrate(alert.weatherConditions, weatherConditions))
    setTimeConditionsList(rehydrate(alert.timeConditions, timeConditions))
    setSMSConditionsList(rehydrate(alert.smsConditions, smsConditions))
    setResetDefaults(false)
    setFixLocation(true);
  } */

  const handleStarAlert = (myAlert) => {
    if (myAlert.active) {
        updateAlert(myAlert.id, { active: !myAlert.active });  
    } else {
        if (canUseStar) {
            updateAlert(myAlert.id, { active: !myAlert.active });
          } else {
            if (auth.user.planIsActive) {
                if (hasPhoneNumber) {
                    alert("Please disable an existing alert (or upgrade your account) before adding a new one. You may only have " + auth.user.allowedAlerts + " alerts at this subscription level.");
                } else {
                    // TODO would rather do this with a modal, but this will do for now.
                    alert("Please enter a phone number in your settings page");
                }
                
            }
            else {
                alert("You must subscribe to a plan before enabling alerts.");
            }
          }
    }
    
  };

  return (
    <>
      {alertsError && (
        <Box mb={3}>
          <Alert severity="error">{alertsError.message}</Alert>
        </Box>
      )}

      <Paper className={classes.paperItems}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <Typography variant="h5">Saved Alerts</Typography>
        </Box>
        <Divider />

        {(alertsStatus === "loading" || alertsAreEmpty) && (
          <Box py={5} px={3} align="center">
            {alertsStatus === "loading" && <CircularProgress size={32} />}

            {alertsStatus !== "loading" && alertsAreEmpty && (
              <>Nothing yet. Click the map above to add your first alert.</>
            )}
          </Box>
        )}

        {alertsStatus !== "loading" && alerts && alerts.length > 0 && (
          <List disablePadding={true}>
            {alerts.map((alert, index) => (
              <ListItem
                key={index}
                divider={index !== alerts.length - 1}
                className={alert.active ? classes.featured : ""}
              >
                <ListItemText>
                    <Box sx={{ pr: 17 }}>
                        {alert.name}
                    </Box>
                </ListItemText>
                <ListItemSecondaryAction>
                <Tooltip title={alert.active ? "Disable SMS" : "Enable SMS"}>
                  <IconButton
                    edge="end"
                    aria-label={alert.active ? "Disable SMS for this alert" : "Enable SMS for this alert"}
                    onClick={() => handleStarAlert(alert)}
                    className={alert.active ? classes.starFeatured : ""}
                  >
                    <StarIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                  <IconButton
                    edge="end"
                    aria-label="edit alert"
                    onClick={() => handleEditAlert(alert)}
                  >
                    <EditIcon />
                  </IconButton>
                  </Tooltip>
                 {/*  <Tooltip title="Copy">
                  <IconButton
                    edge="end"
                    aria-label="Copy alert"
                    onClick={() => handleCopyAlert(alert)}
                  >
                    <FileCopyIcon />
                  </IconButton>
                </Tooltip> */}
                  <Tooltip title="Delete">
                  <IconButton
                    edge="end"
                    aria-label="delete alert"
                    onClick={() => handleDeleteAlert(alert)}
                  >
                    <DeleteIcon />
                  </IconButton>
                  </Tooltip>
                  <Tooltip 
                    title="Earn Credits"
                  >
                  <IconButton
                    edge="end"
                    aria-label="Share alert"
                    onClick={() => handleShareModal(alert)}
                  >
                    <MoneyIcon />
                  </IconButton>
                </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}
      </Paper>
      {showShareModal && (
        <ShareModal
          shareAlertId={shareAlertId}
          onDone={() => setShowShareModal(false)}
        />
      )}

    </>
  );
}

export default DashboardAlerts;
