import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
  },
  avatarWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  avatar: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
}));

function CV(props) {
  const classes = useStyles();

  const avatar = "https://ik.imagekit.io/critz/profile__1_.png?ik-sdk-version=javascript-1.4.3&updatedAt=1672400165974"
  const name =  "Matt";


  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Grid container={true} justifyContent="center" spacing={4}>
          <Grid item={true} xs={12}>
              <Grid container={true}>
                  <Grid item={true} xs={12} sm={3}>
                    <Box className={classes.avatarWrapper}>
                      <Avatar
                        src={avatar}
                        alt={name}
                        className={classes.avatar}
                      />
                    </Box>
                  </Grid>
                  <Grid item={true} xs={12} sm={9}>
                  <Box
                        display="flex"
                        justifyContent="flex-start"
                        padding={2}
                        sx={{marginTop:"25px"}}
                      >
                    <Box 
                    >
                    <Typography variant="h3">
                     👋 Hi, I'm {name}
                    </Typography>
                    <Typography variant="h5">
                      I'm a Product Manager who likes playing with code.
                    </Typography>
                    </Box>
                    </Box>
                  </Grid>
                  <Grid item={true} xs={12} sm={3}>

                  </Grid>
                  <Grid item={true} xs={12} sm={9} md={7}>
                  <Box
                        display="flex"
                        justifyContent="flex-start"
                        padding={2}
                      >
                    <Box 
                    >
                    <Typography variant="h6" style={{marginBottom:"10px"}}>
                     I'm an{" "} 
                     <Link href="https://en.wikipedia.org/wiki/Aphantasia" target="_blank" rel="noreferrer">
                     aphantastic
                    </Link>
                    {" "} 
                     <Link href="https://en.wikipedia.org/wiki/Neophile" target="_blank" rel="noreferrer">
                     neophile
                    </Link>
                    , which either helps you understand me a lot, or not at all.
                    </Typography>
                    <Typography variant="body1">
                     These attributes make me particularly well suited to working in growing startups in a wide range of fields. If you're struggling to find product market fit, or define a strategic path forward I can help bring order to the chaos.
                    </Typography>
                    <Typography variant="h6" style={{marginBottom:"10px", marginTop:"25px"}}>
                      Aphantasia
                    </Typography>
                    <Typography variant="body1" style={{marginBottom:"10px"}}>
                     I don't see images in my mind - the idea of counting sheep? Yeah, that's just counting to me.
                    </Typography>
                    <Typography variant="body1" style={{marginBottom:"10px"}}>
                     And that may seem like a bad thing to you, (or you may be thinking, "what, people can see things in their heads?" - in which case check out this{" "}
                     <Link href="https://aphantasia.com/vviq/" target="_blank" rel="noreferrer">
                     test
                    </Link>
                     {" "}) - but it's actually a super power!
                    </Typography>
                    <Typography variant="body1" style={{marginBottom:"10px"}}>
                     Because I don't have mental imagery, I think about everything as an abstract concept - I can still move things around in 3D, "look" at them from different perspectives, I'm just not seeing any images. And this is great for codebases and business structures. I've grown up only thinking conceptually, so my brain isn't overwhelmed trying to store non-physical attributes on an object, and can see connections and patterns that a more visual person would miss. It does mean I find design harder though!
                    </Typography>
                    <Typography variant="h6" style={{marginBottom:"10px", marginTop:"25px"}}>
                      Neophilia
                    </Typography>
                    <Typography variant="body1" style={{marginBottom:"10px"}}>
                     I crave novelty - and this is a good thing! If you don't know why it is, then please read the short{" "}
                     <Link href="https://en.wikipedia.org/wiki/Neophile" target="_blank" rel="noreferrer">
                     wiki page
                    </Link>
                     {" "}I linked to before.
                    </Typography>
                    <Typography variant="body1" style={{marginBottom:"10px"}}>
                     It is this novelty seeking that makes it a joy for me to immerse myself in a new business, to learn as much as I can, as quickly as I can.
                    </Typography>
                    <Typography variant="body1" style={{marginBottom:"10px"}}>
                     And it translates into better products - experiments, learning, pushing the boundaries of what we can collectively achieve.
                    </Typography>
                    <Typography variant="body1" style={{marginBottom:"10px"}}>
                     But, it does mean I won't stick around forever - but I will leave you in a better place; more stable, more consistent day to day.
                    </Typography>
                    <Typography variant="h6" style={{marginBottom:"10px", marginTop:"25px"}}>
                      Experience
                    </Typography>
                    <Typography variant="body1" style={{marginBottom:"10px"}}>
                     I'm not going to list all my jobs here (you can check out my{" "}
                     <Link href="https://www.linkedin.com/in/mattharbord/" target="_blank" rel="noreferrer">
                     LinkedIn
                    </Link>
                     {" "}profile for that) - but here's a few highlights:
                    </Typography>
                    <Typography variant="body1" style={{marginBottom:"10px"}}>
                     I build stuff - things like this website, other projects like {" "}
                     <Link href="https://www.critz.cc" target="_blank" rel="noreferrer">
                     critz.cc
                    </Link>
                     {" "} and{" "}
                     <Link href="https://www.thediamondhandbook.com" target="_blank" rel="noreferrer">
                     thediamondhandbook.com
                    </Link>
                     . But also more practical things. I made my own wedding ring out of meteorite; I CAD designed, and then built my kitchen; I did my loft conversion to learn about joinery; I've tinkered with electronics, and VR.

                    </Typography>
                    <Typography variant="body1" style={{marginBottom:"10px"}}>
                     I've been a beta tester for Wahoo for a long time, and have consulted for other FitTech companies. I've a cracking idea for using accelerometer and gyroscope data to measure road smoothness while out on the bike that I'll build at some point!
                    </Typography>
                    <Typography variant="body1" style={{marginBottom:"10px"}}>
                     I've written white papers for blockchain companies on market making, and am consulting on GPT4 for an old client.
                    </Typography>
                    <Typography variant="body1" style={{marginBottom:"10px"}}>
                     I set up a {" "}
                     <Link href="https://www.kickstarter.com/projects/ohzoe/a-diverse-and-inclusive-personalised-picture-book" target="_blank" rel="noreferrer">
                     personalised book company 
                    </Link>
                     {" "}- with illustrations carrying the widest range of skin tones, hair styles and physical aids available market.
                    </Typography>
                    <Typography variant="body1" style={{marginBottom:"10px"}}>
                     Regardless of the sector your company is in, you'll be amazed at how quickly I get up to speed and start having an impact. It's always beneficial to have an outside perspective, so if you'd like to talk further just drop me an{" "}
                     <Link href="mailto:matt@ohzoe.com" target="_blank" rel="noreferrer">
                     email
                    </Link>
                     .
                    </Typography>
                    </Box>
                    </Box>
                  </Grid>
              </Grid>
          </Grid>
        </Grid>
        </Container>
    </Section>
  );
}

export default CV;




/* {items.map((item, index) => (
  <Grid item={true} xs={12} key={index}>
    <Card className={classes.card}>
      <Grid container>
      <CardContent>
        <Grid item={true} xs={3}>
          <Box className={classes.avatarWrapper}>
            <Avatar
              src={item.avatar}
              alt={item.name}
              className={classes.avatar}
            />
          </Box>
        </Grid>
        <Grid item={true} xs={8}>
        <Box textAlign="center" pt={3}>
          <Typography variant="body2" component="p">
            {item.name}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
          >
            {item.role}
          </Typography>
          <Box mt={2}>
            <Typography variant="body1" component="p">
              {item.bio}
            </Typography>
          </Box>
        </Box>
        </Grid>
      </CardContent>
      </Grid>
    </Card>
  </Grid>
))}
</Grid>
</Container> */