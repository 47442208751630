import React from "react";
import Container from "@material-ui/core/Container";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

const useStyles = makeStyles((theme) => ({
  accordion: {
    // Remove shadow
    boxShadow: "none",
    "&:before": {
      // Remove default divider
      display: "none",
    },
    // Add a custom border
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  expanded: {
    margin: `0 !important`,
  },
  summary: {
    minHeight: 78,
  },
  summaryContent: {
    margin: "0 !important",
  },
}));

function FaqSection(props) {
  const classes = useStyles();

  const items = [
    {
      question: "Why Wx?",
      answer:
        'Wx has been used as an abbreviation for "weather" since the 19th century. Two characters in morse code take a lot less effort (and is therefore cheaper) than writing out the full word!',
    },
    {
      question: "Why only SMS alerts?",
      answer:
        "I'm more likely to notice an SMS than an email or mobile notification, so while it's more expensive to operate, it's a better fit for what I want WxTxt to do.",
    },
    {
      question: "Where are your forecasts from?",
      answer:
        "Visual Crossing provides excellent forecasts for most of the world. With their API we can look 14 days ahead, giving you as much notification as possible for good weather windows.",
    },
    {
      question: "Why does a subscription cost so much?",
      answer:
        "Sending text messages is relatively expensive - the subscription fees are only meant to cover these costs plus the cost of the infrastructure and API fees. Although I could set this up for email alerts more cheaply, it's not what I wanted to do with a passion project - though I may consider it if enough people complain.",
    },
    {
      question: "Why does a subscription cost so little?",
      answer:
        "I'm really only trying to cover the cost of running the service rather than make much of a profit! I built this mostly for myself, and to see if I could learn some front end coding!",
    },
    {
      question: "What happens to my SMS credits after a year?",
      answer:
        "Your SMS credits remain in your account until you've used them but you must have an active subscription to continue receiving notifications.",
    },
    {
      question: "How do I get in touch?",
      answer:
        "Use the contact form; find me on twitter (@MattHarbord); or drop me an email to matt@wxtxt.co",
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="md">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />

        {items.map((item, index) => (
          <Accordion
            classes={{
              root: classes.accordion,
              expanded: classes.expanded,
            }}
            key={index}
          >
            <AccordionSummary
              classes={{
                root: classes.summary,
                content: classes.summaryContent,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`faq-panel-${index}`}
            >
              <Typography variant="h6">{item.question}</Typography>
            </AccordionSummary>
            <AccordionDetails id={`faq-panel-${index}`}>
              <Typography>{item.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </Section>
  );
}

export default FaqSection;
