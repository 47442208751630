import * as React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { styled } from '@material-ui/styles';


export default function SinglePicker({formats, setFormats, options, disabled}) {

  const StyledToggleButton = styled(ToggleButton)(({ selectedcolor }) => ({
    '&.Mui-selected, &.Mui-selected:hover': {
        color: 'white',
        backgroundColor: selectedcolor,
    },
    '&.Mui-disabled, &.Mui-disabled:hover': {
      backgroundColor: "rgba(233, 30, 99, 0.04)",
  },
  }));

  const handleFormat = (event, newFormats
  ) => {
    if (newFormats !== null) {
      setFormats(newFormats);
    }
  };

  return (
    <>
        <ToggleButtonGroup
        style={{ flexWrap: "wrap" }}
        size="small"
        color="primary"
        value={formats}
        exclusive
        onChange={handleFormat}
        >
        {options.map((m, index) => {
            return (
                <StyledToggleButton 
                    key = {m}
                    value={m}
                    aria-label={m}
                    selectedcolor='#61d1d5'
                    disabled={disabled[index]}
                >
                {m}
                </StyledToggleButton>
            )
        })}
        </ToggleButtonGroup>
    </>
  );
}