import React, { useEffect } from "react";
import Slider from "@material-ui/core/Slider"
import { styled } from "@material-ui/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
    useTheme
  } from "@material-ui/core/styles";



export function valuetext(value, unitType, tempPref, speedPref, distancePref) {
    if (unitType==="temp") {
        if (tempPref === "°C") {
            return `${value}°C`;        
        } else {
            return `${value}°F`;        
        }
        
    }
    if (unitType ==="speed") {
        if (speedPref === "kph") {
            return `${value}kph`;    
        } else {
            return `${value}mph`;    
        }
    }
    if (unitType ==="distance") {
        if (distancePref === "mm") {
            return `${value}mm`;    
        } else {
            return `${value}″`;    
        }
    }
    if (unitType ==="distancecm") {
        if (distancePref === "mm") {
            return `${value}cm`;    
        } else {
            return `${value}″`;    
        }
    }
    if (unitType ==="distancekm") {
        if (speedPref === "kph") {
            return `${value}km`;    
        } else {
            return `${value}miles`;    
        }
    }
    if (unitType ==="time") {
        return `${value} hrs`;    
    }
    if (unitType ==="percent") {
        return `${value}%`;    
    }
    if (unitType ==="none") {
        return `${value}`;    
    }
    if (unitType ==="compass") {
        return compassPointFromValue(value);    
    }
  }

const CustomSlider = styled(Slider)(({ theme }) => ({
    "& .MuiSlider-thumb": {
      height: 15
    },
    "& .MuiSlider-rail": {
      height: 5
    },
    "& .MuiSlider-track": {
      height: 7
    },
    "& .MuiSlider-mark": {
        height: 5
      },
  }));

  export function translateValueToPref(value, unitType, tempPref, speedPref, distancePref) {
    if (unitType === "temp") {
        if (tempPref==="°C") {
            return Math.round(value)
        } else {
            return Math.round(((value * 9/5) + 32))
        }
    }
    if (unitType === "speed") {
        if (speedPref==="kph") {
            return Math.round(value)
        } else {
            return Math.round(((value * 0.6213711922)))
        }
    }
    if (unitType === "distance") {
        if (distancePref==="mm") {
            return Math.round(value*10)/10
        } else {
            return (Math.round((value / 25.4)*10)/10)
        }
    }
    if (unitType === "distancecm") {
        if (distancePref==="mm") {
            return Math.round(value*10)/10
        } else {
            return (Math.round((value / 2.54)*10)/10)
        }
    }
    if (unitType === "distancekm") {
        if (speedPref==="kph") {
            return Math.round(value)
        } else {
            return Math.round(((value * 0.6213711922)))
        }
    }
    if (unitType === "time") {
        return value
    }
    if (unitType === "percent") {
        return Math.round(value);
    }
    if (unitType === "compass") {
        return value
    }
    if (unitType === "none") {
        return value
    }
  }

  export function valueInDefaultUnits(value, unitType, tempPref, speedPref, distancePref) {
    if (unitType==="temp") {
        if (tempPref==="°C") {
            return value
        } else {
            return Math.round(((value - 32) * 5/9))
        }
    }
    if (unitType==="speed") {
        if (speedPref==="kph") {
            return value
        } else {
            return Math.round(value / 0.6213711922)
        }
    }
    if (unitType==="distance") {
        if (distancePref==="mm") {
            return value
        } else {
            return Math.round(value * 25.4)
        }
    }
    if (unitType==="distancecm") {
        if (distancePref==="mm") {
            return value
        } else {
            return Math.round(value * 2.54)
        }
    }
    if (unitType==="distancekm") {
        if (speedPref==="kph") {
            return value
        } else {
            return Math.round(value / 0.6213711922)
        }
    }
    if (unitType==="time") {
        return value
    }
    if (unitType==="percent") {
        return value
    }
    if (unitType === "compass") {
        return value
    }
    if (unitType==="none") {
        return value
    }
  }

  function compassPointFromValue(value) {
    // [S, W, N, E, S, W, N, E, S]
    const point = value % 4;
    const of16 = Math.round(point*4)
    switch (of16) {
        case 0:
            return "S"
        case 1:
            return "SSW"
        case 2:
            return "SW"
        case 3:
            return "WSW"
        case 4:
            return "W"
        case 5:
            return "WNW"
        case 6:
            return "NW"
        case 7:
            return "NNW"
        case 8:
            return "N"
        case 9:
            return "NNE"
        case 10:
            return "NE"
        case 11:
            return "ENE"
        case 12:
            return "E"
        case 13:
            return "ESE"
        case 14: 
            return "SE"
        case 15: 
            return "SSE"
        case 16:
            return "S";
        default:
            return "Huh?"
    }
  }

  function getValueLabelCompass(value) {
    return compassPointFromValue(value)
  }

  function getValueLabelBlank(value) {
    return Math.round(value*1000)/1000;
  }

  function getStep(increment, unitType, distancePref) {
    if (unitType === "distance") {
        if (distancePref === "inch") {
            return 0.1 * increment
        }
    }
    return increment
  }

export default function ConditionSlider({control, setWeatherConditionsList, index, tempPref, speedPref, distancePref, updateWeatherListObj, markers, localSliderValues, setLocalSliderValues}) {

    const theme = useTheme();
    const aboveSM = useMediaQuery(theme.breakpoints.up('sm'));

    let multiSlider = true
    if ("valueWanted" in control) {
        multiSlider = false
    }
    
    const unitType = control.unitType; //"temp", "speed", "distance", "time", "percent", "none"

    // default units are degrees C, KPH and mm, hours
    // we set the values of these, but when the tempPref etc change we change the display
    // we translate all updates back into their default units when writing to the object

    const markList = []

    if (markers === undefined) {
        if (multiSlider) {
            const range = control.maxValue - control.minValue;
            const halfWay = control.minValue + (range/2);
            const diff = control.maxValueWanted - control.minValueWanted
            const mean = (control.maxValueWanted + control.minValueWanted)/2
            const perc = diff / range;
            let hideLower = true;
            if (mean > halfWay) {
                hideLower = false;
            }
            
            if (hideLower && (perc<=0.22)) {
                // just the upper
                markList.push({"value":control.maxValueWanted, "label":control.maxValueWanted, "labelType": "dynamic"});    
            } else if (!hideLower && (perc<=0.22)) {
                // just the lower
                markList.push({"value":control.minValueWanted, "label":control.minValueWanted, "labelType": "dynamic"});
            } else {
                // both
                markList.push({"value":control.minValueWanted, "label":control.minValueWanted, "labelType": "dynamic"});
                markList.push({"value":control.maxValueWanted, "label":control.maxValueWanted, "labelType": "dynamic"});    
            }
        } else {
            markList.push({"value":control.valueWanted, "label": control.valueWanted, "labelType": "dynamic"});
        }
    } else if (markers === null) {
        // no-op
    } else {
        let minMark = 0;
        let maxMark = 0;
        if (multiSlider) {
            minMark = control.minValue;
            maxMark = control.maxValue;
        } else {
            maxMark = control.maxValue;
        }

        // this sets the bounds, can now use markers to define them
        if (markers.type === "percentiles") {
            if (aboveSM) {
                markers.values.forEach((p) => {
                    const lowWeight = (100-p)/100;
                    const highWeight = p/100;
                    const pointWanted = (lowWeight * minMark) + (highWeight * maxMark);
                    markList.push({"value":pointWanted, "label":pointWanted, "labelType": "dynamic"})
                })
            } else {
                // just add first and last for small screens
                markList.push({"value":minMark, "label":minMark, "labelType": "dynamic"})
                markList.push({"value":maxMark, "label":maxMark, "labelType": "dynamic"})
            }
            
        } 
       /*  else if (markers.type==="defined") {
            markers.values.forEach((marker) => {
                for (const [k, v] of Object.entries(marker)) {
                    const label = k;
                    if ((v>=minMark) && (v<=maxMark)) {
                        // we can plot it
                        markList.push({"value":v, "label":k, "labelType": "fixed"})
                    } else if (v>maxMark) {
                        markList.push({"value":maxMark, "label":k, "labelType": "fixed"})
                    }
                }

            })
        } */
    }

    const marks = markList.map((mark) => {
        const markPosition = mark.value;
        const markLabel = mark.label;
        const labelType = mark.labelType;
        const translatedValue = translateValueToPref(markPosition, unitType, tempPref, speedPref, distancePref )
        if (labelType==="dynamic") {
            const tranlsatedLabel = translateValueToPref(markLabel, unitType, tempPref, speedPref, distancePref )
            return (
                {
                    "value": translatedValue, 
                    "label": valuetext(tranlsatedLabel, unitType, tempPref, speedPref, distancePref)}
            )
        } else {
            return (
                {
                    "value": translatedValue, 
                    "label": markLabel,
                }
            )
        }
    })

    // expect whatever comes out of the control to be in default units
    let defaultSet
    if (multiSlider) {
        const lowerBound = translateValueToPref(control.minValueWanted, unitType, tempPref, speedPref, distancePref);
        const upperBound = translateValueToPref(control.maxValueWanted, unitType, tempPref, speedPref, distancePref);
        
        defaultSet = [lowerBound, upperBound];
    } else {
        const valueWanted = translateValueToPref(control.valueWanted, unitType, tempPref, speedPref, distancePref);
        defaultSet = valueWanted
    }
    const [value, setValue] = React.useState(defaultSet);
    

    useEffect(() => {
        let newSet
        if (multiSlider) {
            const lowerBound = translateValueToPref(control.minValueWanted, unitType, tempPref, speedPref, distancePref);
            const upperBound = translateValueToPref(control.maxValueWanted, unitType, tempPref, speedPref, distancePref);
            newSet = [lowerBound, upperBound]
        } else {
            const valueWanted = translateValueToPref(control.valueWanted, unitType, tempPref, speedPref, distancePref);
            newSet = valueWanted
        }
        setValue(newSet)
        if (localSliderValues !== undefined) {
            setLocalSliderValues(newSet);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tempPref, speedPref, distancePref, control.minValueWanted, control.maxValueWanted, control.valueWanted, multiSlider, unitType]);

    const handleChangeCommitted = (event, newValue) => {
        if (multiSlider) {
            if (updateWeatherListObj) {
                setWeatherConditionsList((draft) => {
                    draft[index].control.minValueWanted = valueInDefaultUnits(newValue[0], unitType, tempPref, speedPref, distancePref);
                    draft[index].control.maxValueWanted = valueInDefaultUnits(newValue[1], unitType, tempPref, speedPref, distancePref);
                });
            }
        } else {
            if (updateWeatherListObj) {
                setWeatherConditionsList((draft) => {
                    draft[index].control.valueWanted = valueInDefaultUnits(newValue, unitType, tempPref, speedPref, distancePref);
                });
            }
        }
        setValue(newValue);
    }

    const handleChange = (event, newValue) => {
        if (multiSlider) {
            if (!Array.isArray(newValue)) {
                return;
              }
            if (unitType === "compass") {
                // let's bound it so you can't go more than 360 degrees

                if (newValue[1] - newValue[0] > 4) {
                    const knownLower = control.minValueWanted;
                    if (newValue[0] < knownLower) {
                        // we're pulling left
                        newValue[1] = newValue[0] + 4
                    } else {
                        // we're dragging right
                        newValue[0] = newValue[1] - 4
                    }
                }
            }
            setValue(newValue);
            if (localSliderValues !== undefined) {
                setLocalSliderValues(newValue);
            }
        } else {
            setValue(newValue);
            if (localSliderValues !== undefined) {
                setLocalSliderValues(newValue);
            }
        }
      };

    return (
        <>
        <CustomSlider
            getAriaLabel={() => 'Minimum distance shift'}
            value={value}
            min={translateValueToPref(control.minValue, unitType, tempPref, speedPref, distancePref)}
            max={translateValueToPref(control.maxValue, unitType, tempPref, speedPref, distancePref)}
            onChange={handleChange}
            onChangeCommitted={handleChangeCommitted}
            valueLabelDisplay="auto"
            valueLabelFormat={(unitType === "compass") ? getValueLabelCompass : getValueLabelBlank}
            marks={marks}
            step={getStep(control.increment, unitType, distancePref)}
        />
      </>
    );
  }
  