import React, { useEffect } from "react";
import Slider from "@material-ui/core/Slider"
import { styled } from "@material-ui/styles"


function valuetext(value, unitType) {
    if (unitType ==="time") {
        return `${value} hours`;    
    }
    if (unitType ==="days") {
        if (value === 1) {
            return `${value} day`;    
        } else {
            return `${value} days`;        
        }
    }
    if (unitType ==="none") {
        return `${value}`;    
    }
  }

const CustomSlider = styled(Slider)(({ theme }) => ({
    "& .MuiSlider-thumb": {
      height: 15
    },
    "& .MuiSlider-rail": {
      height: 5
    },
    "& .MuiSlider-track": {
      height: 7
    },
    "& .MuiSlider-mark": {
        height: 5
      },
  }));

export default function ConditionTimeSlider({control, setTimeConditionsList, index}) {

    let multiSlider = true
    if ("valueWanted" in control) {
        multiSlider = false
    }
    
    const unitType = control.unitType; // "time", "none" supported, "days"

    const marks = control.marks.map((mark) => {
        return (
            {
                "value": mark, 
                "label": valuetext(mark, unitType)}
        )
    })

    // expect whatever comes out of the control to be in default units
    let defaultSet
    if (multiSlider) {
        defaultSet = [control.minValueWanted, control.maxValueWanted];
    } else {
        defaultSet = control.valueWanted
    }
    const [value, setValue] = React.useState(defaultSet);
    

    useEffect(() => {
        let newSet
        if (multiSlider) {
            newSet = [control.minValueWanted, control.maxValueWanted]
        } else {
            newSet = control.valueWanted
        }
        setValue(newSet)
    }, [control.minValueWanted, control.maxValueWanted, control.valueWanted, multiSlider]);

    const handleChange = (event, newValue, activeThumb) => {
        if (multiSlider) {
            if (!Array.isArray(newValue)) {
                return;
              }
            setValue(newValue);
            
        } else {
            setValue(newValue);
        }
      };

      const handleChangeCommitted = (event, newValue) => {
        if (multiSlider) {
            if (!Array.isArray(newValue)) {
                return;
              }
              setTimeConditionsList((draft) => {
                draft[index].control.minValueWanted = newValue[0];
                draft[index].control.maxValueWanted = newValue[1];
            });
        } else {
            setTimeConditionsList((draft) => {
                draft[index].control.valueWanted = newValue;
            });
        }
        setValue(newValue);
    }

    return (
        <>
        <CustomSlider
            getAriaLabel={() => 'Minimum distance shift'}
            value={value}
            min={control.minValue}
            max={control.maxValue}
            onChange={handleChange}
            onChangeCommitted={handleChangeCommitted}
            valueLabelDisplay="auto"
            marks={marks}
            step={control.increment}
        />
      </>
    );
  }
  