import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
    useTheme
  } from "@material-ui/core/styles";
import { useDarkMode } from "../util/theme";

function ConditionRowTableHeader() {

    const darkMode = useDarkMode();
    
    // let color = (darkMode.value) ? ((index % 2 ===0) ? "#4f4f4f" : "#5c5c5c") : ((index % 2 ===0) ? "#f4f4f4" : "#e7e7e7")
    const colour = (darkMode.value) ? "#31b4b8" : "#61D1D5"
    let borderColour = (darkMode.value) ? "#61D1D5" : "#31b4b8";

    const theme = useTheme();
    const aboveSM = useMediaQuery(theme.breakpoints.up('sm'));

    borderColour = (aboveSM) ? colour : borderColour

    const weatherHeader = (aboveSM) ? "Weather Condition" : "Weather"

    
    return (
        <>
        <Grid item xs={6} sm={6}>
            <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            padding = {0}
            sx={{ 
                padding: "0", 
                margin: "0px",
                borderRadius: "0%",
                bgcolor: colour,
                borderColor: borderColour,
                border: '1px solid'
            }} 
            >
                <Grid container>                   
                    <Grid item xs={12}>
                        <Typography variant="body1" style={{marginTop: 10}}>
                            <strong>{weatherHeader}</strong>
                        </Typography>          
                    </Grid>
                    
                </Grid>
            </Box>
            
        </Grid>
        {aboveSM &&
        <Grid item xs={6} sm={2}>
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            padding = {0}
            sx={{ 
                padding: "0", 
                margin: "0px",
                borderRadius: "0%",
                bgcolor: colour,
                borderColor: borderColour,
                border: '1px solid'
            }} 
            >
                <Grid container>                   
                    <Grid item xs={12}>
                        <Typography variant="body1" style={{marginTop: 10}}>
                            <strong>Ideal</strong>
                        </Typography>          
                    </Grid>
                    
                </Grid>
            </Box>
            
        </Grid>
}
        <Grid item xs={6} sm={4}>
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            padding = {0}
            sx={{ 
                padding: "0", 
                margin: "0px",
                borderRadius: "0%",
                bgcolor: colour,
                borderColor: borderColour,
                border: '1px solid'
            }} 
            >
                <Grid container>                   
                    <Grid item xs={12}>
                        <Typography variant="body1" style={{marginTop: 10}}>
                            <strong>Best for you</strong>
                        </Typography>          
                    </Grid>
                    
                </Grid>
            </Box>
            
        </Grid>
        
        </>
      );
    }

export default ConditionRowTableHeader;



