import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid";
import MiniMap from "./MiniMap";

const useStyles = makeStyles((theme) => ({
    summary: {
      backgroundColor: 
      theme.palette.type === "dark" ? theme.palette.action.selected : "#F4F4F4",
    }
}));


export default function LocationBox({selectedLocation}) {

    const classes = useStyles()

    return (
        <>
            <Box 
                display="flex"
                padding={2}
                sx={{ 
                    width: "100%",
                    padding: "4px 4px 4px 4px", 
                    borderRadius: 4
                }} 
                className={classes.summary}
            >   <Grid container alignItems="center">
                    <Grid item xs={12}>
                        <MiniMap selectedLocation={selectedLocation}/>
                    </Grid>
                </Grid>
            </Box>
      </>
    );
  }
  