import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CheckIcon from "@material-ui/icons/Check";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link } from "./../util/router";
import { useAuth } from "./../util/auth";
import useLocalStorageState from "use-local-storage-state";
import { createLocalStorageStateHook } from "use-local-storage-state";
import SinglePicker from "./SinglePicker";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(3),
  },
  price: {
    display: "flex",
    alignItems: "baseline",
  },
  listItem: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  perkIcon: {
    minWidth: 34,
    color: theme.palette.success.main,
  },
  lastUsedIndicator: {
    position: "absolute",
    top: "-35px",
    right: "0px",
    fontSize: "18px",
    lineHeight: 1,
    textTransform: "initial",
    padding: "5px 7px",
    borderRadius: "40px",
    color: "black",
    backgroundColor: theme.palette.secondary.main,
    opacity: 1,
  },
}));

function PricingSection(props) {
  const classes = useStyles();

  const auth = useAuth();

  const currencySymbol = props.currencySymbol;

  const currencies = ["USD ($)", "GBP (£)", "EUR (€)"]
  const localCurrencyPref = createLocalStorageStateHook("localCurrencyPref");
  const [currencyPref, setCurrencyPref] = localCurrencyPref();
  if (currencyPref === undefined) {
    if (currencySymbol !== undefined) {
      if (currencySymbol === "£") {
          setCurrencyPref("GBP (£)");
      } else if (currencySymbol==="€") {
        setCurrencyPref("EUR (€)");
      } else {
        setCurrencyPref("USD ($)");
      }
    } else {
      setCurrencyPref("USD ($)");
    }
  }

  // eslint-disable-next-line no-unused-vars
  const [referrer, setReferrer] = useLocalStorageState("referrer")

  // users must come through here to sign up, if auth'd we should write any referrer to their firestore
  if (auth.user) {
    if (referrer) {
      const data = {"referrer": referrer}
      auth.updateProfile(data)
    }     
  }

  const plans = [
    {
      id: "starter",
      name: "Starter",
      priceUSD: "3",
      priceGBP: "3",
      priceEUR: "3",
      ppmUSD: "25c",
      ppmGBP: "25p",
      ppmEUR: "25c",
      perksUSD: ["2 Alerts/Locations", "30 SMS Credits (10c / SMS)"],
      perksGBP: ["2 Alerts/Locations", "30 SMS Credits (10p / SMS)"],
      perksEUR: ["2 Alerts/Locations", "30 SMS Credits (10c / SMS)"],
    },
    {
      id: "regular",
      name: "Regular",
      priceUSD: "10",
      priceGBP: "8",
      priceEUR: "9",
      ppmUSD: "83c",
      ppmGBP: "67p",
      ppmEUR: "75c",
      perksUSD: [
        "10 Alerts/Locations",
        "125 SMS Credits (8c / SMS)"
      ],
      perksGBP: [
        "10 Alerts/Locations",
        "125 SMS Credits (6.4p / SMS)"
      ],
      perksEUR: [
        "10 Alerts/Locations",
        "125 SMS Credits (7.2c / SMS)"
      ],
    },
    {
      id: "pro",
      name: "Patron",
      priceUSD: "50",
      priceGBP: "40",
      priceEUR: "45",
      ppmUSD: "$4.17",
      ppmGBP: "£3.34",
      ppmEUR: "€3.75",
      perksUSD: [
        "100 Alerts/Locations",
        "750 SMS Credits (6.7c / SMS)",
        "Hall of Fame"
      ],
      perksGBP: [
        "100 Alerts/Locations",
        "750 SMS Credits (5.3p / SMS)",
        "Hall of Fame"
      ],
      perksEUR: [
        "100 Alerts/Locations",
        "750 SMS Credits (6c / SMS)",
        "Hall of Fame"
      ],
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
       <Grid container justifyContent="center">
            <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            padding = {2}
                            >
              <SinglePicker formats={currencyPref} setFormats={setCurrencyPref} options={currencies} disabled={[false, false, false]} key={currencyPref}/>
              </Box>
          </Grid>
        <Grid container={true} justifyContent="center" spacing={4}>
          {plans.map((plan, index) => (
            <Grid item={true} xs={12} md={4} key={index}>
              <Card className={classes.card}>              
                <CardContent className={classes.cardContent}>
                  <Typography variant="h6" component="h2">
                    {plan.name}
                  </Typography>
                  {((currencyPref!=="EUR (€)") && (currencyPref!=="GBP (£)")) &&
                  <>
                  <Box className={classes.price} mt={1}>
                    <Typography variant="h3">${plan.priceUSD}</Typography>
                    <Typography variant="h5" color="textSecondary">
                      /year
                    </Typography>
                  </Box>
                   <Box className={classes.price} mt={1}>
                   <Typography variant="body1" color="textSecondary">
                    Just {plan.ppmUSD} per month!
                    </Typography>
                 </Box>
                 </>
                 }
                 {(currencyPref==="EUR (€)") && 
                 <>
                  <Box className={classes.price} mt={1}>
                    <Typography variant="h3">€{plan.priceEUR}</Typography>
                    <Typography variant="h5" color="textSecondary">
                      /year
                    </Typography>
                  </Box>
                   <Box className={classes.price} mt={1}>
                   <Typography variant="body1" color="textSecondary">
                    Just {plan.ppmEUR} per month!
                    </Typography>
                 </Box>
                 </>
                  }
                  {(currencyPref==="GBP (£)") && 
                  <>
                  <Box className={classes.price} mt={1}>
                    <Typography variant="h3">£{plan.priceGBP}</Typography>
                    <Typography variant="h5" color="textSecondary">
                      /year
                    </Typography>
                  </Box>
                   <Box className={classes.price} mt={1}>
                   <Typography variant="body1" color="textSecondary">
                    Just {plan.ppmGBP} per month!
                    </Typography>
                 </Box>
                 </>
                  }
                  {plan.description && (
                    <Box mt={2}>
                      <Typography component="p" color="textSecondary">
                        {plan.description}
                      </Typography>
                    </Box>
                  )}
                  {((currencyPref!=="EUR (€)") && (currencyPref!=="GBP (£)")) &&
                    <Box mt={1}>
                      <List aria-label="perks">
                        {plan.perksUSD.map((perk, index) => (
                          <ListItem
                            className={classes.listItem}
                            disableGutters={true}
                            key={index}
                          >
                            <ListItemIcon className={classes.perkIcon}>
                              <CheckIcon />
                            </ListItemIcon>
                            <ListItemText>{perk}</ListItemText>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  }
                  {(currencyPref==="EUR (€)") &&
                    <Box mt={1}>
                      <List aria-label="perks">
                        {plan.perksEUR.map((perk, index) => (
                          <ListItem
                            className={classes.listItem}
                            disableGutters={true}
                            key={index}
                          >
                            <ListItemIcon className={classes.perkIcon}>
                              <CheckIcon />
                            </ListItemIcon>
                            <ListItemText>{perk}</ListItemText>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  }
                  {(currencyPref==="GBP (£)") &&
                    <Box mt={1}>
                      <List aria-label="perks">
                        {plan.perksGBP.map((perk, index) => (
                          <ListItem
                            className={classes.listItem}
                            disableGutters={true}
                            key={index}
                          >
                            <ListItemIcon className={classes.perkIcon}>
                              <CheckIcon />
                            </ListItemIcon>
                            <ListItemText>{perk}</ListItemText>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  }

                  <Box mt="auto" pt={3}>
                    <Button
                      component={Link}
                      to={
                        auth.user
                          ? `/purchase/${plan.id}`
                          : `/auth/signup?next=/purchase/${plan.id}`
                      }
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth={true}
                    >
                      Choose
                    
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
}

export default PricingSection;
