export const smsConditions = [
    {
        "title": "Send At Most",
        "description": "Choose how often to send alerts for the same day.",
        "description2": "",
        "control": {
            "flavour": "picker",
            "ofType": "single",
            "options": ["Every 3 Days", "Every 5 Days", "Every 7 Days", "Only Once"],
            "selected": ["Only Once"],
        }
    },
    {
        "title": "Cool Off Period",
        "description": "Choose how long to pause daily alerts for after receiving one. If there is a good forecast on Monday, and you have a cooling off of 2 days, we won't tell you about Tuesday or Wednesday if they match.",
        "description2": "",
        "control": {
            "flavour": "slider",
            "unitType": "days",
            "minValue": 0,
            "maxValue": 7,
            "increment": 1,
            "valueWanted": 2,
            "marks": [0, 2, 4, 6]
        }

    },
    {
        "title": "Look Ahead Period",
        "description": "Choose how far ahead you want to look - setting the right slider to 7 will mean you only get notifications for good forecasts within the next 7 days.",
        "description2": "",
        "control": {
            "flavour": "slider",
            "unitType": "days",
            "minValue": 1,
            "maxValue": 15,
            "increment": 1,
            "minValueWanted": 1,
            "maxValueWanted": 7,
            "marks": [1, 7, 14],
        }

    },
];
