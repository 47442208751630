import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../util/auth";
import { useAlert, createAlert, updateAlert } from "../util/db";
import SMSSettings from "./SMSSettings";

const useStyles = makeStyles((theme) => ({
  content: {
    paddingBottom: 24,
  },
}));

function EditAlertModal(props) {
  const classes = useStyles();

  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const setSMSConditionsList = props.setSMSConditionsList;

  const hasPhoneNumber = props.hasPhoneNumber;
  const phoneVerified = props.phoneVerified;
  const countActiveAlerts = props.countActiveAlerts;
  const allowedAlerts = props.allowedAlerts;
  const textCredits = props.textCredits;

  const hasPlan = (allowedAlerts>0)

  const canAutoActivate = (allowedAlerts > countActiveAlerts) && hasPhoneNumber;

  const { register, handleSubmit, errors } = useForm();

  // This will fetch item if props.id is defined
  // Otherwise query does nothing and we assume
  // we are creating a new item.
  const { data: alertData, status: alertStatus } = useAlert(props.id);

  // If we are updating an existing item
  // don't show modal until item data is fetched.
  if (props.id && alertStatus !== "success") {
    return null;
  }

  function getConditionsFormat(conditionsList) {
    // need to return a field name, a flavour and then the control value or array
    const conditions = []
    conditionsList.forEach((c) => {
      let condition = {"condition": c.title, "flavour": c.control.flavour};
      if (c.control.flavour === "picker") {
        condition.value = c.control.selected;
      } else {
        if ("valueWanted" in c.control) {
          condition.value = [c.control.valueWanted]
        } else {
          condition.value = [c.control.minValueWanted, c.control.maxValueWanted]
        }
      }
      if ("periodSelected" in c) {
        condition.period = c.periodSelected
      }
      conditions.push(condition)
    })
    return conditions
  }

  function prepareAlertData(data, canAutoActivate) {
    if ('selectedLocation' in props) {
      data['latitude'] = props.selectedLocation.latitude;
      data['longitude'] = props.selectedLocation.longitude;
    }
    data['weatherConditions'] = getConditionsFormat(props.weatherConditionsList)
    data['timeConditions'] = getConditionsFormat(props.timeConditionsList)
    data['smsConditions'] = getConditionsFormat(props.smsConditionsList)
    if (canAutoActivate) {
      data['active'] =true;
    }

    return data;
  }

  const onSubmit = (data) => {
    setPending(true);

    data = prepareAlertData(data, canAutoActivate)

    const query = props.id
      ? updateAlert(props.id, data)
      : createAlert({ owner: auth.user.uid, ...data });

    query
      .then(() => {
        // Let parent know we're done so they can hide modal
        props.onDone();
      })
      .catch((error) => {
        // Hide pending indicator
        setPending(false);
        // Show error alert message
        setFormAlert({
          type: "error",
          message: error.message,
        });
      });
  };

  const handleClose = () => {
    props.setCreatingItem(false);
  }

  let saveLabel = "Save Alert"

  if (hasPhoneNumber && canAutoActivate) {
    saveLabel = "Save and Activate Alert"
  }

  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle>
        {props.id && <>Update</>}
        {!props.id && <>Save</>}
        {` `}Alert
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography variant="h6" paragraph={true}>
              SMS settings
            </Typography>
            <Grid item={true} xs={12}>
              <SMSSettings setSMSConditionsList={setSMSConditionsList} smsConditionsList = {props.smsConditionsList}/>
            </Grid>
            <Grid item={true} xs={12}>
            </Grid>

            <Typography variant="body1" paragraph={true} style={{marginTop:"20px"}}>
        {props.id && <>Update the name for this alert if you want to, and then save. This is what will be sent in the SMS message to you.</>}  
        {!props.id && <>Create a name for this alert that you will recognise. This is what will be sent in the SMS message to you.</>}
        </Typography>
        <Typography variant="body1" paragraph={true}>
        Choose something meaningful like 'Helvellyn, 2 days, warm and clear skies'
        </Typography>
        {formAlert && (
          <Box mb={4}>
            <Alert severity={formAlert.type}>{formAlert.message}</Alert>
          </Box>
        )}

            <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              <TextField
                variant="outlined"
                type="text"
                label="Name"
                name="name"
                defaultValue={alertData && alertData.name}
                error={errors.name ? true : false}
                helperText={errors.name && errors.name.message}
                fullWidth={true}
                autoFocus={true}
                inputRef={register({
                  required: "Please enter a name",
                })}
              />
            </Grid>
            <Grid item={true} xs={12}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={pending}
              >
                {!pending && <span>{saveLabel}</span>}

                {pending && <CircularProgress size={28} />}
              </Button>
              </Grid>

              {!hasPlan &&
              <Grid item={true} xs={12}>
                <Alert variant="outlined" severity="warning">
                    You need to subscribe to a plan to activate alerts. Save the alert and activate it by hitting ⭐ after signing up.
                </Alert>
              </Grid>
              }

              {hasPlan && !canAutoActivate && hasPhoneNumber &&
              <Grid item={true} xs={12}>
                <Alert variant="outlined" severity="info">
                    You already have {countActiveAlerts} alerts active. Save the alert and either upgrade your plan, or de-activate an existing alert in order to activate this one by hitting ⭐.
                </Alert>
              </Grid>
              }
              {canAutoActivate && !phoneVerified &&
              <Grid item={true} xs={12}>
                <Alert variant="outlined" severity="warning">
                    Your alert will be activated, but you need to verify your phone before we can send any more SMS. Please check your SMS inbox, or contact us if you can't find the verification message.
                </Alert>
              </Grid>
              }
              {hasPlan && (textCredits===0) &&
              <Grid item={true} xs={12}>
                <Alert variant="outlined" severity="info">
                    You have no more SMS credits in the account. The alert will be activated but you won't receive any messages until you top up your SMS credit balance.
                </Alert>
              </Grid>
              }
              {hasPlan &&  !hasPhoneNumber &&
              <Grid item={true} xs={12}>
                <Alert variant="outlined" severity="warning">
                    We don't yet have your phone number. Save the alert then add your contact number in Settings before hitting ⭐ to activate the alert.
                </Alert>
              </Grid>
              }

          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default EditAlertModal;
