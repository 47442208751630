import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import { 
    faPersonHiking, 
    faCampground, 
    faSailboat, 
    faPersonBiking, 
    faUmbrellaBeach, 
    faPersonSwimming, 
    faStar,
    faCloudMoon,
    faCirclePlus
    } from "@fortawesome/free-solid-svg-icons"
import ActivityBoxes from "./ActivityBoxes";

function ActivitySelector({activity, setActivity, includeNone}) {

    const activities = [
        {
            "name": "Hiking",
            "icon": faPersonHiking
        },
        {
            "name": "Camping",
            "icon": faCampground
        },
        {
            "name": "Sailing",
            "icon": faSailboat
        },
        {
            "name": "Cycling",
            "icon": faPersonBiking
        },
        {
            "name": "Beach Day",
            "icon": faUmbrellaBeach
        },
        {
            "name": "Wild Swimming",
            "icon": faPersonSwimming
        },
        {
            "name": "Star Gazing",
            "icon": faStar
        },
        {
            "name": "Night Walk",
            "icon": faCloudMoon
        },

    ]

    if (includeNone) {
        activities.push(
            {
                "name": "Custom",
                "icon": faCirclePlus
            }
        )
    }

    return (
        <Grid item xs={12}>
            <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding = {0}
            >
            <Grid container>
                {activities.map((a) => {
                    return (<ActivityBoxes activityName={a.name} activityIcon={a.icon} activity={activity} setActivity={setActivity} key={a.name} singleWidth={includeNone}/>)
                })}
            </Grid>
            </Box>
        </Grid>
      );
    }

export default ActivitySelector;



