import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import OneConditionRow from "./OneConditionRow";
import ConditionRowTableHeader from "./ConditionRowTableHeader";

function ReportActivitySettings({conditions, tempPref, speedPref, distancePref}) {

  
    
    return (
        <Grid item xs={12}>
            <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding = {1}
            sx={{ 
                marginTop: "12px"
            }}
            
            >
            <Grid container>
                <ConditionRowTableHeader/>
                {conditions.map((condition, index) => {
                    return (<OneConditionRow index={index} condition={condition} tempPref={tempPref} speedPref={speedPref} distancePref={distancePref} key={condition.condition}/>)
                })}
            </Grid>
            </Box>
        </Grid>
      );
    }

export default ReportActivitySettings;



