import React from "react";
import Meta from "./../components/Meta";
import CtaSection from "./../components/CtaSection";
import CV from "../components/CV";

function AboutPage(props) {
  return (
    <>
      <Meta title="About" description="Learn about our company and team" />
      <CV
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="About me"
        subtitle=""
      />
      <CtaSection
        bgColor="primary"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Want to see it in action?"
        subtitle=""
        buttonText="Create an alert"
        buttonColor="secondary"
        buttonPath="/alerts"
      />
    </>
  );
}

export default AboutPage;
